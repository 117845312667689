import { AlertTypeV2 } from "../../graphql/operations";

export const AlertTypeTitle: {
  [AlertTypeV2.Geofence]: string;
  [AlertTypeV2.Cargo]: string;
  [AlertTypeV2.TirePressureMonitorSystem]: string;
  [AlertTypeV2.Dwell]: string;
  [AlertTypeV2.Custom]: string;
  [AlertTypeV2.SensorHealth]: string;
  [AlertTypeV2.MovingWithoutPrimaryOrSecondaryPower]: string;
  [AlertTypeV2.Speeding]: string;
  [AlertTypeV2.WheelEnd]: string;
  [AlertTypeV2.TirePressureMonitorSystemCatastrophic]: string;
  [AlertTypeV2.LightCircuit]: string;
  [AlertTypeV2.Metrics]: string;
  [AlertTypeV2.DoorOpenOutsideOfGeofence]: string;
  [AlertTypeV2.DoorOpenAndMoving]: string;
  [AlertTypeV2.AtisNotFunctioning]: string;
  [AlertTypeV2.Regulator]: string;
  [AlertTypeV2.AirTank]: string;
  [AlertTypeV2.Reefer]: string;
  [AlertTypeV2.Atis]: string;
  [AlertTypeV2.AbsFault]: string;
  [AlertTypeV2.Temperature]: string;
  [AlertTypeV2.CargoFloorSpace]: string;
  [AlertTypeV2.RollStability]: string;
} = {
  [AlertTypeV2.Geofence]: "Geofence",
  [AlertTypeV2.Cargo]: "Cargo",
  [AlertTypeV2.TirePressureMonitorSystem]: "TPMS",
  [AlertTypeV2.Dwell]: "Dwell",
  [AlertTypeV2.Custom]: "Custom",
  [AlertTypeV2.SensorHealth]: "Sensor Health",
  [AlertTypeV2.MovingWithoutPrimaryOrSecondaryPower]:
    "Moving without Primary or Secondary Power",
  [AlertTypeV2.Speeding]: "Speeding",
  [AlertTypeV2.WheelEnd]: "Wheel End",
  [AlertTypeV2.TirePressureMonitorSystemCatastrophic]: "TPMS Catastrophic",
  [AlertTypeV2.LightCircuit]: "Light Circuit",
  [AlertTypeV2.Metrics]: "Metrics",
  [AlertTypeV2.DoorOpenOutsideOfGeofence]: "Door open outside of Geofence",
  [AlertTypeV2.DoorOpenAndMoving]: "Door Open & Moving",
  [AlertTypeV2.AtisNotFunctioning]: "ATIS Not Functioning",
  [AlertTypeV2.Regulator]: "Regulator",
  [AlertTypeV2.AirTank]: "Air Tank",
  [AlertTypeV2.Reefer]: "Reefer",
  [AlertTypeV2.Atis]: "ATIS",
  [AlertTypeV2.AbsFault]: "ABS Fault",
  [AlertTypeV2.Temperature]: "Temperature",
  [AlertTypeV2.CargoFloorSpace]: "Cargo Floor Space",
  [AlertTypeV2.RollStability]: "Roll Stability",
};

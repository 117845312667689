import { FC, useMemo, useState } from "react";
import { FieldValues } from "react-hook-form";
import {
  Box,
  Input,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { isEmpty } from "lodash";
import { ReactComponent as AirtankSensorDark } from "../../../../../assets/svgs/airTankSensorDark.svg";
import { ReactComponent as AirtankSensorLight } from "../../../../../assets/svgs/airtankSensorLight.svg";
import { ReactComponent as RegulatorSensorDark } from "../../../../../assets/svgs/regulatorSensorDark.svg";
import { ReactComponent as RegulatorSensorLight } from "../../../../../assets/svgs/regulatorSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  UpdateOrganizationSensorProfileInput,
  SensorProfileConfigInput,
  SensorProfileResult,
  ProfileConfigProperty,
  SensorThresholdShort,
} from "../../../../../graphql/operations";
import Drawer from "../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../shared/components/Drawer/DrawerHeader";
import { Label } from "../../../../../shared/components/FormControlElement/styledElements";
import SensorSlider from "../../../../../shared/components/SensorSlider/SensorSlider";
import {
  sliderMarks,
  extractReversedRules,
  prepareReversedPayload,
  airTankPressureDefaultValues,
  airTankMinSpeed,
  airTankMaxSpeed,
  regulatorDefaultValues,
} from "../../../../../shared/components/SensorSlider/sensorSliderUtils";
import WithAsterisk from "../../../../../shared/components/WithAsterisk";
import {
  MinValuesBySensorType,
  MaxValuesBySensorType,
} from "../../../../../shared/helpers/battery";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import { useGetPressureUnitPreference } from "../../../../../shared/hooks/useGetPressureUnitPreference";
import {
  getConvertedPressureValues,
  getPressureUnitLabel,
  prepareConvertedMbarPressuresPayload,
} from "../../../../../utils/convertPressure";
import { convertMilesToKm } from "../../../../../utils/convertUnits";
import { useSensorProfilesApi } from "../../../hooks/useSensorProfilesApi";
import { AirSupplyDrawers, DrawerType } from "../profileUtils";
import DeleteProfileDialog from "./shared/components/DeleteProfileDialog";
import { ProfilesDrawerMainForm } from "./shared/components/ProfilesDrawerMainForm/ProfilesDrawerMainForm";
import { useProfileDrawerMainForm } from "./shared/hooks/useProfileDrawerMainForm";

export type AirSupplyDrawerProps = {
  sensorType: ProfileConfigProperty;
  sensorProfileData: SensorProfileResult | undefined;
  type: DrawerType;
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  onProfileMutation: () => void;
  onRequestClose: () => void;
};

const AirSupplyDrawer: FC<AirSupplyDrawerProps> = ({
  sensorType,
  type,
  sensorProfileData,
  onClose,
  isOpen,
  onProfileMutation,
  onRequestClose,
}) => {
  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const pressureUnit = useGetPressureUnitPreference();
  // Initial slider values
  const [minAirTankConverted, maxAirBagConverted] = getConvertedPressureValues(
    [MinValuesBySensorType.tankPressure, MaxValuesBySensorType.tankPressure],
    pressureUnit
  );

  const sPmin = MinValuesBySensorType.supplyPressure;
  const sPmax = MaxValuesBySensorType.supplyPressure;
  const supplyPressure =
    sensorProfileData?.configuration?.psiAirSupply?.supplyPressure;

  const initialMovingValues =
    extractReversedRules(supplyPressure?.moving) || regulatorDefaultValues;
  const initialParkedValues =
    extractReversedRules(supplyPressure?.parked) || regulatorDefaultValues;

  const tankPressure =
    sensorProfileData?.configuration?.psiAirSupply?.tankPressure;

  const initialMinSpeed = tankPressure?.minSpeed ?? 0;
  const initialAirTank =
    extractReversedRules(tankPressure as SensorThresholdShort) ||
    airTankPressureDefaultValues;
  const initialAirTankConverted = getConvertedPressureValues(
    initialAirTank,
    pressureUnit
  );

  // State
  const [moving, setMoving] = useState(
    getConvertedPressureValues(initialMovingValues, pressureUnit)
  );
  const [parked, setParked] = useState(
    getConvertedPressureValues(initialParkedValues, pressureUnit)
  );
  const [airTank, setAirTank] = useState(initialAirTankConverted);
  const [minSpeed, setMinSpeed] = useState<number>(initialMinSpeed);
  const [minSpeedError, setMinSpeedError] = useState<string>("");
  const [deleteAirTankPopupState, setDeleteAirTankPopupState] = useState(false);

  // Fetch & prepare organization options
  const availableOrgs = useAvailableOrgs();

  const isEdit = type === AirSupplyDrawers.Edit;
  const isSliderOrMinSpeedDirty =
    airTank.toString() !== initialAirTank.toString() ||
    minSpeed !== initialMinSpeed ||
    moving.toString() !== initialMovingValues.toString() ||
    parked.toString() !== initialParkedValues.toString() ||
    minSpeed !== initialMinSpeed;

  const currentOrgName = useMemo(() => {
    // Find & show the current organization of the sensor in the dropdown
    if (sensorProfileData?.orgId && isEdit) {
      const foundOrg = availableOrgs.find(
        (org) => org._id.toString() === sensorProfileData.orgId
      );

      return foundOrg?._id;
    }

    return "";
  }, [availableOrgs, isEdit, sensorProfileData]);

  const { form: mainAirTankForm } = useProfileDrawerMainForm({
    name: sensorProfileData?.name ?? "",
    orgName: currentOrgName,
    default: sensorProfileData?.default ?? false,
  });

  const handleAirTankProfileCreateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "AirTank Sensor Profile Created Successfully!",
        severity: "success",
      },
    });
  };

  const handleAirTankProfileCreateError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Creation Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleAirTankProfileUpdateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "AirTank Sensor Profile Updated Successfully!",
        severity: "success",
      },
    });
  };

  const handleAirTankProfileUpdateError = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Update Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleAirTankProfileDeleteSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "AirTank Sensor Profile Deleted Successfully!",
        severity: "success",
      },
    });
  };

  const handleAirTankProfileDeleteError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Deletion Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const {
    updateSensorProfile,
    isLoadingUpdateSensorProfile,
    deleteSensorProfile,
    isSuccessDeleteSensorProfile,
    isLoadingDeleteSensorProfile,
    createSensorProfile,
    isLoadingCreateSensorProfile,
  } = useSensorProfilesApi({
    createSensorProfileOnSuccess: handleAirTankProfileCreateSuccess,
    createSensorProfileOnError: handleAirTankProfileCreateError,
    updateSensorProfileOnSuccess: handleAirTankProfileUpdateSuccess,
    updateSensorProfileOnError: handleAirTankProfileUpdateError,
    deleteSensorProfileOnSuccess: handleAirTankProfileDeleteSuccess,
    deleteSensorProfileOnError: handleAirTankProfileDeleteError,
  });

  const handleDeleteProfile = () => {
    if (isLoadingDeleteSensorProfile || isSuccessDeleteSensorProfile) {
      return;
    }

    deleteSensorProfile({ _id: sensorProfileData?._id ?? "" });
  };

  const handleSubmitClick = async () => {
    const isMainFormValid = await mainAirTankForm.trigger();
    const isMinSpeedValid = await onMinSpeedChange({
      target: { value: minSpeed.toString() },
    });

    if (!isMainFormValid || !isMinSpeedValid) return;

    const formValues: FieldValues = mainAirTankForm.getValues();
    const movingConfig = prepareReversedPayload(
      undefined,
      moving,
      sPmin,
      sPmax
    );
    const parkedConfig = prepareReversedPayload(
      undefined,
      parked,
      sPmin,
      sPmax
    );
    const configuration: SensorProfileConfigInput = {
      psiAirSupply: {
        tankPressure: {
          minSpeed: convertMilesToKm(minSpeed),
          match: prepareReversedPayload(
            undefined,
            prepareConvertedMbarPressuresPayload(airTank, pressureUnit),
            MinValuesBySensorType.tankPressure,
            MaxValuesBySensorType.tankPressure
          ),
        },
        supplyPressure: {
          moving: {
            match: movingConfig,
          },
          parked: {
            match: parkedConfig,
          },
        },
      },
    };

    if (type === AirSupplyDrawers.Create) {
      createSensorProfile({
        name: formValues.name,
        default: formValues.default ?? false,
        orgId: formValues?.orgName ?? "",
        configuration,
      });
    } else if (isEdit) {
      const updateSensorProfilePayload: Record<string, any> = {
        _id: sensorProfileData?._id,
        type: sensorProfileData?.type,
        orgId: sensorProfileData?.orgId, // send current orgId to BE by default
      };

      for (let fieldUpdated in mainAirTankForm.formState.dirtyFields) {
        // Get correct id from dropdown object to send to backend
        if (fieldUpdated === "orgName")
          updateSensorProfilePayload.orgId = formValues[fieldUpdated];
        else
          updateSensorProfilePayload[fieldUpdated] = formValues[fieldUpdated];
      }

      if (isSliderOrMinSpeedDirty) {
        updateSensorProfilePayload.configuration = configuration;
      }

      updateSensorProfile(
        updateSensorProfilePayload as UpdateOrganizationSensorProfileInput
      );
    }
  };

  const toggleDeleteAirTankProfilePopup = () => {
    setDeleteAirTankPopupState(!deleteAirTankPopupState);
  };

  const onMinSpeedChange = (e: { target: { value: string } }) => {
    const num = Number(e.target.value);
    if (Number.isNaN(num)) return true;

    setMinSpeed(num);

    if (num > airTankMaxSpeed || num < airTankMinSpeed) {
      setMinSpeedError(
        `Number must be between ${airTankMinSpeed} and ${airTankMaxSpeed}.`
      );
      return false;
    }

    setMinSpeedError("");
    return true;
  };

  const isLightTheme = theme?.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    marginTop: "0.75rem",
  };

  const text = isEdit ? "Edit Profile" : "Create Profile";

  const isLoading =
    isLoadingCreateSensorProfile ||
    isLoadingUpdateSensorProfile ||
    isLoadingDeleteSensorProfile;

  return (
    <Drawer
      testId="airtank-drawer"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <DrawerHeader text={text} onClose={() => onClose(false)} />

      <DrawerContent>
        <Box className="h-full flex flex-col justify-between">
          <Box className="mb-10">
            <ProfilesDrawerMainForm
              form={mainAirTankForm}
              disabled={isLoading}
              isEdit={isEdit}
            />

            <Box className="px-6" data-testid="airtank-profiles">
              <Box className="mb-16">
                <Box className="pb-6" data-testid="input-min-speed">
                  <FormControl sx={{ width: "100%" }}>
                    <WithAsterisk>
                      <Label htmlFor="Min Speed">Min Speed</Label>
                    </WithAsterisk>
                    <Input
                      value={minSpeed}
                      onChange={onMinSpeedChange}
                      data-testid="airtank-profiles-min-speed-input"
                      disabled={isLoading}
                      sx={{ width: "100%", mb: "1px" }}
                    />
                    {!!minSpeedError && (
                      <FormHelperText
                        data-testid="airtank-profiles-min-speed-error-text"
                        error={true}
                      >
                        {minSpeedError}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>

                <Typography sx={{ fontWeight: "bold" }}>
                  Air Tank Settings
                </Typography>
                {isLightTheme ? (
                  <AirtankSensorDark style={svgIconSettings} />
                ) : (
                  <AirtankSensorLight style={svgIconSettings} />
                )}
              </Box>
              <SensorSlider
                values={airTank}
                min={minAirTankConverted}
                max={maxAirBagConverted}
                marks={sliderMarks(
                  minAirTankConverted,
                  maxAirBagConverted,
                  getPressureUnitLabel(pressureUnit)
                )}
                disabled={isLoading}
                reversed
                onChange={setAirTank}
              />
            </Box>

            <Box className="mt-12 px-6" data-testid="profiles-regulator-moving">
              <Box className="mb-16">
                <Typography sx={{ fontWeight: "bold" }}>
                  Regulator Settings Moving
                </Typography>
                {isLightTheme ? (
                  <RegulatorSensorDark style={svgIconSettings} />
                ) : (
                  <RegulatorSensorLight style={svgIconSettings} />
                )}
              </Box>
              <SensorSlider
                values={moving}
                min={minAirTankConverted}
                max={maxAirBagConverted}
                marks={sliderMarks(
                  minAirTankConverted,
                  maxAirBagConverted,
                  getPressureUnitLabel(pressureUnit)
                )}
                disabled={isLoading}
                reversed
                onChange={setMoving}
              />
            </Box>

            <Box className="mt-12 px-6" data-testid="profiles-regulator-parked">
              <Box className="mb-16">
                <Typography sx={{ fontWeight: "bold" }}>
                  Regulator Settings Parked
                </Typography>
                {isLightTheme ? (
                  <RegulatorSensorDark
                    style={svgIconSettings}
                    data-testid="air-supply-profile-drawer-regulator-icon-dark"
                  />
                ) : (
                  <RegulatorSensorLight
                    style={svgIconSettings}
                    data-testid="air-supply-profile-drawer-regulator-icon-light"
                  />
                )}
              </Box>
              <SensorSlider
                values={parked}
                min={minAirTankConverted}
                max={maxAirBagConverted}
                marks={sliderMarks(
                  minAirTankConverted,
                  maxAirBagConverted,
                  getPressureUnitLabel(pressureUnit)
                )}
                disabled={isLoading}
                reversed
                onChange={setParked}
              />
            </Box>
          </Box>

          <DrawerActions
            deleteBtnTestId="btn-delete-airtank-profile"
            showDeleteBtn={isEdit}
            disabled={isLoading}
            onCancel={() => onClose(false)}
            onDelete={toggleDeleteAirTankProfilePopup}
            deleteBtnText="Remove"
          />
        </Box>

        {isEdit && deleteAirTankPopupState && (
          <DeleteProfileDialog
            isOpen={deleteAirTankPopupState}
            isLoading={isLoadingDeleteSensorProfile}
            onClick={handleDeleteProfile}
            onClose={toggleDeleteAirTankProfilePopup}
            testId="air-supply"
          />
        )}
      </DrawerContent>

      <DrawerFooter
        text={
          isLoadingCreateSensorProfile || isLoadingUpdateSensorProfile
            ? "Saving..."
            : "Save"
        }
        disabled={
          isLoading ||
          (isEmpty(mainAirTankForm.formState.dirtyFields) &&
            !isSliderOrMinSpeedDirty)
        }
        testId="btn-airtank-profile-form-submit"
        submit={handleSubmitClick}
      />
    </Drawer>
  );
};

export default AirSupplyDrawer;

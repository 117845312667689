import { isNil, isUndefined } from "lodash";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  Asset,
  AxleSensor,
  HistoricalEventHistory,
  Maybe,
  PsiWheelEndMeasureData,
  SensorStatus,
  SensorThresholdShort,
  TemperatureInternalThresholds,
  TpmsPressureThresholds,
  SensorStatusUppercase,
  HistoricalPsiWheelEndMeasureData,
  DualImbalanceSensorValue,
  PressureUnit,
  TemperatureUnit,
} from "../../../../../graphql/operations";
import { getConvertedPressureValue } from "../../../../../utils/convertPressure";
import { getConvertedTemperatureValue } from "../../../../../utils/convertTemperature";
import {
  checkIsValidDate,
  convertIntervalToDuration,
  DATE_FORMAT,
  DAY_MONTH_FORMAT,
  formatDate,
  getFormattedDuration,
} from "../../../../../utils/date";
import { AssetTiresSensorState } from "../AssetShortTooltip/components/AssetShortTooltipSensors/AssetShortTooltipSensors.interface";
import type { AssetWheelEndsSensorState } from "./components/cards/psiWheelEnd/AxlesSummaryCards";
import type { PsiWheelEndSummaryTableData } from "./components/cards/psiWheelEnd/TiresSummaryTable";
import type { TireSummaryTableData } from "./components/cards/tpms/TiresSummaryTable";
import type {
  PsiWheelEndAxleTemperatureValues,
  PsiWheelEndChartData,
} from "./components/charts/components/psiWheelEnd/PsiWheelEndChartBody";
import type { RegulatorChartData } from "./components/charts/components/regulator/RegulatorChartBody";
import { MAP_PSI_WHEEL_END_AXLE, MAP_TPMS_AXLE } from "./constants";
import {
  PsiWheelEndSummaryData,
  PsiWheelEnd,
  TPMS_Tire,
  TireSummaryData,
  PsiWheelEndAxle,
  DualImbalanceAxleTireData,
  IMappedDualImbalanceChartData,
  AxleSide,
  IReferenceAreaValueType,
  RangeReferenceAreaValueType,
  TPMS_Axle,
  ImbalanceVariantsByAxle,
  MAP_AXLE_TO_NUMBER,
} from "./interfaces";
import { AxleDualImbalanceData, DualImbalanceStatuses } from "./types";

// --------- Card Constants -------
export const iconSettings = {
  iconWidth: "20px",
  iconHeight: "20px",
};

export const getTPMSAxlesDualImbalanceData = (
  axleSensor: AxleSensor
): AxleDualImbalanceData => {
  const axleResult: AxleDualImbalanceData = {};

  // Dual imbalance on outer(left side of axle) tires
  if (
    axleSensor?.primary_roadside_pressure &&
    axleSensor?.inner_roadside_pressure
  ) {
    axleResult.left = {
      gap: Math.abs(
        axleSensor.primary_roadside_pressure -
          axleSensor.inner_roadside_pressure
      ),
      status:
        axleSensor.status_roadside_imbalance ?? SensorStatusUppercase.Unknown,
    };
  }
  // Dual imbalance on inner(right side of axle) tires
  if (
    axleSensor.primary_curbside_pressure &&
    axleSensor.inner_curbside_pressure
  ) {
    axleResult.right = {
      gap: Math.abs(
        axleSensor.primary_curbside_pressure -
          axleSensor.inner_curbside_pressure
      ),
      status:
        axleSensor.status_curbside_imbalance ?? SensorStatusUppercase.Unknown,
    };
  }

  return axleResult;
};

export const prepareDualImbalanceData = (
  asset: Asset
): DualImbalanceStatuses => {
  const dualImbalanceStatuses: DualImbalanceStatuses = {};

  const [sensor1, sensor2, sensor3] =
    asset?.sensors?.tpmsBeta?.data?.sensors ?? [];

  if (sensor1) {
    dualImbalanceStatuses.axle1 = getTPMSAxlesDualImbalanceData(sensor1);
  }
  if (sensor2) {
    dualImbalanceStatuses.axle2 = getTPMSAxlesDualImbalanceData(sensor2);
  }
  if (sensor3) {
    dualImbalanceStatuses.axle3 = getTPMSAxlesDualImbalanceData(sensor3);
  }

  return dualImbalanceStatuses;
};

export const parseTPMSSummary = (asset: Asset): DualImbalanceAxleTireData[] => {
  const sensorsSummary: DualImbalanceAxleTireData[] = [];

  if (asset?.sensors?.tpmsBeta) {
    const { tpmsBeta } = asset.sensors;
    const dualImbalanceStatusData = prepareDualImbalanceData(asset);
    const { receivedDate, data } = tpmsBeta;

    // filter axles data
    Object.values((data?.sensors ?? []) as AxleSensor[])
      .filter(Boolean)
      .forEach((sensor: AxleSensor, index: number) => {
        // add the inner tires if they exist
        const innerLeftTire = sensor.inner_roadside_status
          ? mapInnerLeftTire(
              sensor,
              index + 1,
              receivedDate,
              dualImbalanceStatusData
            )
          : undefined;
        const innerRightTire = sensor.inner_curbside_status
          ? mapInnerRightTire(
              sensor,
              index + 1,
              receivedDate,
              dualImbalanceStatusData
            )
          : undefined;

        // Note: Tires should be in order: OuterLeft, InnerLeft, InnerRight, OuterRight
        const tireObjects: DualImbalanceAxleTireData = {
          left: {
            outerLeft: mapOuterLeftTire(
              sensor,
              index + 1,
              receivedDate,
              dualImbalanceStatusData
            ),
            innerLeft: innerLeftTire,
          },
          right: {
            outerRight: mapOuterRightTire(
              sensor,
              index + 1,
              receivedDate,
              dualImbalanceStatusData
            ),
            innerRight: innerRightTire,
          },
        };

        sensorsSummary.push(tireObjects);
      });
  }

  return sensorsSummary;
};

const mapOuterLeftTire = (
  sensor: AxleSensor,
  axle: number,
  receivedDate: Date,
  dualImbalanceData: DualImbalanceStatuses
): TireSummaryData => ({
  axle: MAP_TPMS_AXLE[axle],
  tire: TPMS_Tire.OuterLeft,
  gap:
    (
      dualImbalanceData?.[
        `axle${axle}` as unknown as keyof DualImbalanceStatuses
      ]! as AxleDualImbalanceData
    )?.left?.gap ?? null,
  dualImbalanceStatus:
    (
      dualImbalanceData?.[
        `axle${axle}` as unknown as keyof DualImbalanceStatuses
      ]! as AxleDualImbalanceData
    )?.left?.status ?? null,
  status: getOverallSensorStatus([
    sensor?.status_primary_roadside_pressure ?? SensorStatusUppercase.Unknown,
    sensor?.status_primary_roadside_temperature ??
      SensorStatusUppercase.Unknown,
  ]),
  pressure: sensor?.primary_roadside_pressure ?? null,
  temperature: !isNil(sensor?.primary_roadside_temperature)
    ? sensor.primary_roadside_temperature
    : null,
  lastReported: receivedDate,
});

const mapOuterRightTire = (
  sensor: AxleSensor,
  axle: number,
  receivedDate: Date,
  dualImbalanceData: DualImbalanceStatuses
): TireSummaryData => ({
  axle: MAP_TPMS_AXLE[axle],
  tire: TPMS_Tire.OuterRight,
  gap:
    (
      dualImbalanceData?.[
        `axle${axle}` as unknown as keyof DualImbalanceStatuses
      ]! as AxleDualImbalanceData
    )?.right?.gap ?? null,
  dualImbalanceStatus:
    (
      dualImbalanceData?.[
        `axle${axle}` as unknown as keyof DualImbalanceStatuses
      ]! as AxleDualImbalanceData
    )?.right?.status ?? null,
  status: getOverallSensorStatus([
    sensor.status_primary_curbside_pressure ?? SensorStatusUppercase.Unknown,
    sensor.status_primary_curbside_temperature ?? SensorStatusUppercase.Unknown,
  ]),
  pressure: sensor?.primary_curbside_pressure ?? null,
  temperature: !isNil(sensor?.primary_curbside_temperature)
    ? sensor.primary_curbside_temperature
    : null,
  lastReported: receivedDate,
});

const mapInnerLeftTire = (
  sensor: AxleSensor,
  axle: number,
  receivedDate: Date,
  dualImbalanceData: DualImbalanceStatuses
): TireSummaryData => ({
  axle: MAP_TPMS_AXLE[axle],
  tire: TPMS_Tire.InnerLeft,
  gap:
    (
      dualImbalanceData?.[
        `axle${axle}` as unknown as keyof DualImbalanceStatuses
      ]! as AxleDualImbalanceData
    )?.left?.gap ?? null,
  dualImbalanceStatus:
    (
      dualImbalanceData?.[
        `axle${axle}` as unknown as keyof DualImbalanceStatuses
      ]! as AxleDualImbalanceData
    )?.left?.status ?? null,
  status: getOverallSensorStatus([
    sensor?.status_inner_roadside_pressure ?? SensorStatusUppercase.Unknown,
    sensor?.status_inner_roadside_temperature ?? SensorStatusUppercase.Unknown,
  ]),
  pressure: sensor?.inner_roadside_pressure ?? null,
  temperature: !isNil(sensor?.inner_roadside_temperature)
    ? sensor.inner_roadside_temperature
    : null,
  lastReported: receivedDate,
});

const mapInnerRightTire = (
  sensor: AxleSensor,
  axle: number,
  receivedDate: Date,
  dualImbalanceData: DualImbalanceStatuses
): TireSummaryData => ({
  axle: MAP_TPMS_AXLE[axle],
  tire: TPMS_Tire.InnerRight,
  gap:
    (
      dualImbalanceData?.[
        `axle${axle}` as unknown as keyof DualImbalanceStatuses
      ]! as AxleDualImbalanceData
    )?.right?.gap ?? null,
  dualImbalanceStatus:
    (
      dualImbalanceData?.[
        `axle${axle}` as unknown as keyof DualImbalanceStatuses
      ]! as AxleDualImbalanceData
    )?.right?.status ?? null,
  status: getOverallSensorStatus([
    sensor?.status_inner_curbside_pressure ?? SensorStatusUppercase.Unknown,
    sensor?.status_inner_curbside_temperature ?? SensorStatusUppercase.Unknown,
  ]),
  pressure: sensor?.inner_curbside_pressure ?? null,
  temperature: !isNil(sensor?.inner_curbside_temperature)
    ? sensor.inner_curbside_temperature
    : null,
  lastReported: receivedDate,
});

export const parseTpmsAxlesSensors = (
  asset: Asset
): AssetTiresSensorState[] => {
  if (asset?.sensors?.tpmsBeta) {
    const { tpmsBeta } = asset.sensors;
    const sensors = (tpmsBeta?.data?.sensors ?? []) as AxleSensor[];

    return sensors.filter(Boolean).map(mapTpmsAxleSensorState);
  }

  return [];
};

export const getOverallSensorStatus = (
  statuses: SensorStatusUppercase[]
): SensorStatusUppercase => {
  if (statuses.includes(SensorStatusUppercase.Unknown))
    return SensorStatusUppercase.Unknown;
  if (statuses.includes(SensorStatusUppercase.Critical))
    return SensorStatusUppercase.Critical;
  if (statuses.includes(SensorStatusUppercase.Alert))
    return SensorStatusUppercase.Alert;
  if (statuses.includes(SensorStatusUppercase.Warning))
    return SensorStatusUppercase.Warning;
  if (statuses.includes(SensorStatusUppercase.Healthy))
    return SensorStatusUppercase.Healthy;

  return SensorStatusUppercase.Unknown;
};

export const mapTpmsAxleSensorState = (
  sensor: AxleSensor
): AssetTiresSensorState => {
  const {
    status_primary_roadside_pressure,
    status_primary_roadside_temperature,
    status_primary_curbside_pressure,
    status_primary_curbside_temperature,
    status_inner_roadside_pressure,
    status_inner_roadside_temperature,
    status_inner_curbside_pressure,
    status_inner_curbside_temperature,
  } = sensor;
  // First, map the required sensors (for the outer tires)
  let sensorData: AssetTiresSensorState = {
    outerLeft: getOverallSensorStatus([
      status_primary_roadside_pressure ?? SensorStatusUppercase.Unknown,
      status_primary_roadside_temperature ?? SensorStatusUppercase.Unknown,
    ]),
    outerRight: getOverallSensorStatus([
      status_primary_curbside_pressure ?? SensorStatusUppercase.Unknown,
      status_primary_curbside_temperature ?? SensorStatusUppercase.Unknown,
    ]),
  };

  // And then, map the optional sensors (for the inner tires. if they exist)
  if (
    !isUndefined(status_inner_roadside_pressure) &&
    !isUndefined(status_inner_roadside_temperature) &&
    !isUndefined(status_inner_curbside_pressure) &&
    !isUndefined(status_inner_curbside_temperature)
  ) {
    sensorData.innerLeft = getOverallSensorStatus([
      status_inner_roadside_pressure ?? SensorStatusUppercase.Unknown,
      status_inner_roadside_temperature ?? SensorStatusUppercase.Unknown,
    ]);
    sensorData.innerRight = getOverallSensorStatus([
      status_inner_curbside_pressure ?? SensorStatusUppercase.Unknown,
      status_inner_curbside_temperature ?? SensorStatusUppercase.Unknown,
    ]);
  }

  return sensorData;
};

export const mapTPMSSummaryToTableData = (
  sensorsSummary: DualImbalanceAxleTireData[]
): TireSummaryTableData[] => {
  const tableSummaryData: TireSummaryTableData[] = [];
  /**
   * Loop both sides of the axle to map push all tires to array for table
   */
  sensorsSummary.forEach((axleData) => {
    Object.values(axleData.left).forEach((tireData: TireSummaryData) => {
      tableSummaryData.push({
        name: `${tireData?.axle} - ${tireData?.tire}`,
        status: tireData?.status,
        gap: tireData?.gap ?? ("" as any),
        dualImbalanceStatus:
          tireData?.dualImbalanceStatus ?? ("" as SensorStatusUppercase),
        pressure: tireData?.pressure,
        temperature: tireData?.temperature,
        lastReported: tireData?.lastReported,
      });
    });

    Object.values(axleData.right).forEach((tireData: TireSummaryData) => {
      tableSummaryData.push({
        name: `${tireData?.axle} - ${tireData?.tire}`,
        status: tireData?.status,
        gap: tireData?.gap ?? ("" as any),
        dualImbalanceStatus:
          tireData?.dualImbalanceStatus ?? ("" as SensorStatusUppercase),
        pressure: tireData?.pressure,
        temperature: tireData?.temperature,
        lastReported: tireData?.lastReported,
      });
    });
  });

  return tableSummaryData;
};

export const determineTpmsLineStokeColor = (isAxleSelected: boolean) => {
  if (isAxleSelected) {
    return ColorsPalette.PrimaryBlue;
  }

  return ColorsPalette.RoyalBlue;
};

export const REGULAR_STROKE = "";
export const DASH_STROKE = "5 5";

export const determineTpmsLineStrokeDash = (
  isAxleSelected: boolean,
  isTireSelected: boolean
) => {
  if (isAxleSelected) {
    if (isTireSelected) {
      return REGULAR_STROKE;
    }

    return DASH_STROKE;
  }

  return REGULAR_STROKE;
};

export const determineDualImbalanceStrokeDash = (
  currentRecord: ImbalanceVariantsByAxle,
  selectedRecord: ImbalanceVariantsByAxle
) => {
  if (currentRecord === selectedRecord) {
    return DASH_STROKE;
  }

  return REGULAR_STROKE;
};

export const mapRegulatorSensorDataToChartData = (
  data: HistoricalEventHistory[],
  pressureUnit: PressureUnit
): RegulatorChartData[] => {
  return data.map((item) => ({
    // Values for the chart axis (x, y)
    date: item.date ? formatDate(item.date, DAY_MONTH_FORMAT) : null,
    median: getConvertedPressureValue(
      item.regulator?.median ?? 0,
      pressureUnit
    ),

    // Values for the tooltip
    tooltipDate: item.date ? formatDate(item.date, DATE_FORMAT) : null,
    min: getConvertedPressureValue(item.regulator?.min ?? 0, pressureUnit),
    max: getConvertedPressureValue(item?.regulator?.max ?? 0, pressureUnit),
  }));
};

export const getElapsedTimeSinceLabel = (date: Date): string => {
  if (!checkIsValidDate(date)) {
    return "unknown";
  }

  const duration = convertIntervalToDuration({
    start: date,
    end: new Date(),
  });

  const durationTypes = [
    "years",
    "months",
    "days",
    "hours",
    "minutes",
    "seconds",
  ];

  // We want to show the only first duration that is not 0 (e.g. 1 year, 2 months, 3 days, etc.)
  for (const durationType of durationTypes) {
    if (duration[durationType as keyof Duration] ?? 0) {
      return getFormattedDuration(duration, {
        format: [durationType],
      });
    }
  }

  return "unknown";
};

export const parsePsiWheelEndSummary = (
  asset: Asset
): PsiWheelEndSummaryData[] => {
  const sensorsSummary: PsiWheelEndSummaryData[] = [];

  if (asset?.sensors?.psiWheelEnd) {
    const { psiWheelEnd } = asset.sensors;
    const { receivedDate, data } = psiWheelEnd;

    Object.values((data?.psiWheelEndMeasure ?? []) as PsiWheelEndMeasureData[])
      .filter(Boolean)
      .forEach((sensor: PsiWheelEndMeasureData, index: number) => {
        sensorsSummary.push({
          axle: MAP_PSI_WHEEL_END_AXLE[index + 1],
          wheelEnd: PsiWheelEnd.Left,
          status: sensor.left_state ?? SensorStatus.Unknown,
          temperature: sensor?.left_temperature ?? null,
          lastReported: receivedDate,
        });

        sensorsSummary.push({
          axle: MAP_PSI_WHEEL_END_AXLE[index + 1],
          wheelEnd: PsiWheelEnd.Right,
          status: sensor.right_state ?? SensorStatus.Unknown,
          temperature: sensor?.right_temperature ?? null,
          lastReported: receivedDate,
        });
      });
  }

  return sensorsSummary;
};

export const mapPsiWheelEndSummaryToTableData = (
  sensorsSummary: PsiWheelEndSummaryData[]
): PsiWheelEndSummaryTableData[] => {
  return sensorsSummary.map((sensorSummary: PsiWheelEndSummaryData) => {
    const name = `${sensorSummary.axle} - ${sensorSummary.wheelEnd}`;

    return {
      name: name,
      status: sensorSummary.status,
      temperature: sensorSummary.temperature,
      lastReported: sensorSummary.lastReported,
    };
  });
};

export const parsePsiWheelEndAxlesSensors = (
  asset: Asset
): AssetWheelEndsSensorState[] => {
  if (asset?.sensors?.psiWheelEnd?.data?.psiWheelEndMeasure) {
    const { psiWheelEnd } = asset.sensors;
    const sensors = psiWheelEnd?.data?.psiWheelEndMeasure ?? [];

    return (sensors as PsiWheelEndMeasureData[])
      .filter(Boolean)
      .map(mapPsiWheelEndAxleSensorState);
  }

  return [];
};

const mapPsiWheelEndAxleSensorState = (
  sensor: PsiWheelEndMeasureData
): AssetWheelEndsSensorState => {
  return {
    leftWheelEnd: sensor.left_state ?? SensorStatus.Unknown,
    rightWheelEnd: sensor.right_state ?? SensorStatus.Unknown,
  };
};

export const mapPsiWheelEndSensorDataToChartData = (
  data: HistoricalEventHistory[],
  tempUnitPreference: TemperatureUnit
): PsiWheelEndChartData[] => {
  return data.map((eventHistoryData) => {
    const sensors =
      (eventHistoryData?.psiWheelEnd
        ?.psiWheelEndMeasure as HistoricalPsiWheelEndMeasureData[]) ?? [];

    const chartDayData: PsiWheelEndChartData = {
      date: eventHistoryData?.date
        ? formatDate(eventHistoryData.date, DAY_MONTH_FORMAT)
        : null,
      tooltipDate: eventHistoryData?.date
        ? formatDate(eventHistoryData.date, DATE_FORMAT)
        : null,
    };

    const MAP_AXLES: Record<number, PsiWheelEndAxle> = {
      0: PsiWheelEndAxle.One,
      1: PsiWheelEndAxle.Two,
      2: PsiWheelEndAxle.Three,
    };

    sensors.forEach(
      (sensor: HistoricalPsiWheelEndMeasureData, axleIndex: number) => {
        const leftWheelEndTemperature = buildPsiWheelEndTableDataKey(
          MAP_AXLES[axleIndex],
          PsiWheelEnd.Left
        );

        chartDayData[leftWheelEndTemperature] = getConvertedTemperatureValue(
          parsePsiWheelEndTemperature(sensor?.leftTemperature) as number,
          tempUnitPreference
        );

        const rightWheelEndTemperature = buildPsiWheelEndTableDataKey(
          MAP_AXLES[axleIndex],
          PsiWheelEnd.Right
        );

        chartDayData[rightWheelEndTemperature] = getConvertedTemperatureValue(
          parsePsiWheelEndTemperature(sensor?.rightTemperature) as number,
          tempUnitPreference
        );
      }
    );

    return chartDayData;
  });
};

const buildPsiWheelEndTableDataKey = (
  axle: PsiWheelEndAxle,
  wheelEnd: PsiWheelEnd
): PsiWheelEndAxleTemperatureValues => {
  return `${axle}-${wheelEnd}-Temperature` as unknown as PsiWheelEndAxleTemperatureValues;
};

const parsePsiWheelEndTemperature = (
  value: string | null | undefined
): number | null => {
  if (!value) {
    return null;
  }

  const parsed = parseInt(value);

  return isNaN(parsed) ? null : parsed;
};

export const determinePsiWheelEndLineStokeColor = (
  inputWheelEnd: PsiWheelEndAxleTemperatureValues,
  selectedAxle: PsiWheelEndAxle | undefined,
  selectedWheelEnd: PsiWheelEnd | undefined
): string => {
  if (isPsiWheelEndSelected(inputWheelEnd, selectedAxle, selectedWheelEnd)) {
    return ColorsPalette.PrimaryBlue;
  }

  return ColorsPalette.RoyalBlue;
};

export const determinePsiWheelEndLineStrokeDash = (
  inputWheelEnd: PsiWheelEndAxleTemperatureValues,
  selectedAxle: PsiWheelEndAxle | undefined,
  selectedWheelEnd: PsiWheelEnd | undefined
) => {
  if (!selectedAxle || !selectedWheelEnd) {
    return REGULAR_STROKE;
  }

  if (isPsiWheelEndSelected(inputWheelEnd, selectedAxle, selectedWheelEnd)) {
    return REGULAR_STROKE;
  }

  return DASH_STROKE;
};

export const isPsiWheelEndSelected = (
  inputWheelEnd: PsiWheelEndAxleTemperatureValues,
  selectedAxle: PsiWheelEndAxle | undefined,
  selectedWheelEnd: PsiWheelEnd | undefined
): boolean => {
  if (!selectedAxle || !selectedWheelEnd) {
    return false;
  }

  // It comes in the form of "${PsiWheelEndAxle}-${PsiWheelEnd}-Temperature", so we need to split it into sections
  const [currentWheelEndAxle, currentWheelEnd] = inputWheelEnd.split("-");

  if (
    currentWheelEndAxle === selectedAxle &&
    currentWheelEnd === selectedWheelEnd
  ) {
    return true;
  }

  return false;
};

/**
 * Map data for every day by axle,
 * meaning for every day we map the left & right sides of the current axle & add them to array
 */
export const mapDualImbalanceChartData = (
  aggregatedDualImbalanceData: Pick<
    HistoricalEventHistory,
    "dualImbalance" | "date"
  >[] = [],
  validAxles: AxleSensor[]
): IMappedDualImbalanceChartData[] => {
  // Result is prepared by day & axle

  return aggregatedDualImbalanceData.map((di) => {
    // convienent mapping by axles due to OS mapped having all tires listed as objects with the axle index at the end
    // example: primaryRoadside1: ...
    const trendObj = {};

    Object.values(validAxles).forEach((_, index) => {
      const currentAxle = index + 1;
      const preparedDate = {
        date: di.date ? formatDate(di.date, DAY_MONTH_FORMAT) : null,
        tooltipDate: di?.date ? formatDate(di.date, DATE_FORMAT) : null,
      };
      const leftSideKey = `${AxleSide.LEFT}${currentAxle}`;
      const rightSideKey = `${AxleSide.RIGHT}${currentAxle}`;

      // leftSide of axle imbalanceData
      // leftSide of axle imbalanceData
      const leftSideImbalanceData = {
        [leftSideKey]: di?.dualImbalance?.averageRoadsideDiff,
        [`${leftSideKey}-min`]: di?.dualImbalance?.minimumRoadsideDiff,
        [`${leftSideKey}-max`]: di?.dualImbalance?.maximumRoadsideDiff,
      };

      // right side of axle imbalanceData
      const rightSidesImbalanceData = {
        [rightSideKey]: di?.dualImbalance?.averageCurbsideDiff,
        [`${rightSideKey}-min`]: di?.dualImbalance?.minimumCurbsideDiff,
        [`${rightSideKey}-max`]: di?.dualImbalance?.maximumCurbsideDiff,
      };

      // Add data to the record only if there's a median gap for left or right side
      Object.assign(trendObj, {
        ...(leftSideImbalanceData[leftSideKey] && leftSideImbalanceData),
        ...(rightSidesImbalanceData[rightSideKey] && rightSidesImbalanceData),
        ...preparedDate,
      });
    });

    return trendObj as IMappedDualImbalanceChartData;
  });
};

export const hasRelevantDualImbalanceData = (
  data: IMappedDualImbalanceChartData[]
): boolean => {
  if (data.length < 1) return false;

  return data.some(
    (diRecord) =>
      (diRecord.left1 && diRecord.left1 !== 0) ||
      (diRecord.left2 && diRecord.left2 !== 0) ||
      (diRecord.left3 && diRecord.left3 !== 0) ||
      (diRecord.right1 && diRecord.right1 !== 0) ||
      (diRecord.right2 && diRecord.right2 !== 0) ||
      (diRecord.right3 && diRecord.right3 !== 0)
  );
};

export const mapOSDualImbalanceChartData = (
  aggregatedDualImbalanceData: DualImbalanceSensorValue[],
  pressureUnit: PressureUnit
): IMappedDualImbalanceChartData[] => {
  return aggregatedDualImbalanceData?.map((di) => {
    const chartData: IMappedDualImbalanceChartData = {
      date: di.reading_day ? formatDate(di.reading_day, DAY_MONTH_FORMAT) : "",
      tooltipDate: di?.reading_day
        ? formatDate(di.reading_day, DATE_FORMAT)
        : "",
    };
    if (di?.axle_0_roadside_diff) {
      chartData.left1 =
        getConvertedPressureValue(
          di.axle_0_roadside_diff.avg ?? 0,
          pressureUnit
        ) ?? 0;
      chartData["left1-min"] = getConvertedPressureValue(
        di.axle_0_roadside_diff.min ?? 0,
        pressureUnit
      );
      chartData["left1-max"] = getConvertedPressureValue(
        di.axle_0_roadside_diff.max ?? 0,
        pressureUnit
      );
    }

    if (di?.axle_0_curbside_diff) {
      chartData.right1 = getConvertedPressureValue(
        di.axle_0_curbside_diff.avg ?? 0,
        pressureUnit
      );
      chartData["right1-min"] = getConvertedPressureValue(
        di.axle_0_curbside_diff.min ?? 0,
        pressureUnit
      );
      chartData["right1-max"] = getConvertedPressureValue(
        di.axle_0_curbside_diff.max ?? 0,
        pressureUnit
      );
    }

    if (di?.axle_1_roadside_diff) {
      chartData.left2 = getConvertedPressureValue(
        di.axle_1_roadside_diff.avg ?? 0,
        pressureUnit
      );
      chartData["left2-min"] = getConvertedPressureValue(
        di.axle_1_roadside_diff.min ?? 0,
        pressureUnit
      );
      chartData["left2-max"] = getConvertedPressureValue(
        di.axle_1_roadside_diff.max ?? 0,
        pressureUnit
      );
    }

    if (di?.axle_1_curbside_diff) {
      chartData.right2 = getConvertedPressureValue(
        di.axle_1_curbside_diff.avg ?? 0,
        pressureUnit
      );
      chartData["right2-min"] = getConvertedPressureValue(
        di.axle_1_curbside_diff.min ?? 0,
        pressureUnit
      );
      chartData["right2-max"] = getConvertedPressureValue(
        di.axle_1_curbside_diff.max ?? 0,
        pressureUnit
      );
    }

    if (di?.axle_2_roadside_diff) {
      chartData.left3 = getConvertedPressureValue(
        di.axle_2_roadside_diff.avg ?? 0,
        pressureUnit
      );
      chartData["left3-min"] = getConvertedPressureValue(
        di.axle_2_roadside_diff.min ?? 0,
        pressureUnit
      );
      chartData["left3-max"] = getConvertedPressureValue(
        di.axle_2_roadside_diff.max ?? 0,
        pressureUnit
      );
    }

    if (di?.axle_2_curbside_diff) {
      chartData.right3 = getConvertedPressureValue(
        di.axle_2_curbside_diff.avg ?? 0,
        pressureUnit
      );
      chartData["right3-min"] = getConvertedPressureValue(
        di.axle_2_curbside_diff.min ?? 0,
        pressureUnit
      );
      chartData["right3-max"] = getConvertedPressureValue(
        di.axle_2_curbside_diff.max ?? 0,
        pressureUnit
      );
    }
    return chartData;
  });
};

/**
 * Converts profile thresholds sensor ranges to area values for recharts chart
 * Works with profiles that have a `or` range in the rule
 */
export const rangesSensorConfigToReferenceAreaValues = (
  thresholds?:
    | Maybe<TpmsPressureThresholds>
    | Maybe<TemperatureInternalThresholds>,
  pressureUnit?: PressureUnit
): RangeReferenceAreaValueType[] | [] => {
  if (!thresholds) return [];

  // get rule from threshold
  const mappedThresholds = Object.keys(thresholds).map((key) => {
    const rule = (thresholds as any)[key];
    const min1Value = rule.underFrom ?? rule.coldSideFrom;
    const max1Value = rule.underTo ?? rule.coldSideTo;
    const min2Value = rule.overFrom ?? rule.hotSideFrom;
    const max2Value = rule.overTo ?? rule.hotSideTo;

    const min1valueConverted = pressureUnit
      ? getConvertedPressureValue(min1Value, pressureUnit)
      : min1Value;
    const max1ValueConverted = pressureUnit
      ? getConvertedPressureValue(max1Value, pressureUnit)
      : max1Value;
    const min2ValueConverted = pressureUnit
      ? getConvertedPressureValue(min2Value, pressureUnit)
      : min2Value;
    const max2ValueConverted = pressureUnit
      ? getConvertedPressureValue(max2Value, pressureUnit)
      : max2Value;

    return {
      state: key.charAt(0).toUpperCase() + key.slice(1), // capitalize the first letter
      min1: min1valueConverted,
      max1: max1ValueConverted,
      min2: min2ValueConverted,
      max2: max2ValueConverted,
    };
  });

  return rangesSensorAdjust(mappedThresholds);
};
const rangesSensorAdjust = (data: RangeReferenceAreaValueType[] | []) => {
  // Iterate through each object in the data array
  for (let i = 0; i < data.length; i++) {
    // Adjust min1 and min2 values, keeping the minimum value unchanged
    if (data[i].min1 > 0) data[i].min1 -= 1;
    if (data[i].min2 > 0) data[i].min2 -= 1;

    // Adjust max1 and max2 values, ensuring max1 is not 1 less than the min2 of the next range
    if (i < data.length - 1) {
      if (data[i].max1 + 1 === data[i + 1].min2) {
        data[i].max1 -= 1;
      }
      if (data[i].max2 + 1 === data[i + 1].min1) {
        data[i].max2 -= 1;
      }
    }
  }
  return data;
};
const sensorAdjust = (
  data: { state: string; min: number; max: number }[]
): { state: string; min: number; max: number }[] => {
  // Iterate through each object in the data array
  for (let i = 0; i < data.length; i++) {
    // Adjust min value, keeping the minimum value unchanged
    if (data[i].min > 0) data[i].min -= 1;

    // Adjust max value, ensuring max is not 1 less than the min of the next range
    if (i < data.length - 1) {
      if (data[i].max + 1 === data[i + 1].min) {
        data[i].max -= 1;
      }
    }
  }
  return data;
};
// Compute chart reference areas from sensor configuration
export const sensorConfigToReferenceAreaValues = (
  thresholds?: Maybe<SensorThresholdShort>
): IReferenceAreaValueType[] | [] => {
  if (!thresholds) return [];

  // get rule from threshold
  const mappedThresholds = Object.keys(thresholds).map((key) => {
    const rule = (thresholds as any)[key];
    const fromKey = key + "From";
    const toKey = key + "To";

    return {
      state: key.charAt(0).toUpperCase() + key.slice(1), // capitalize the first letter
      min: rule[fromKey],
      max: rule[toKey],
    };
  });

  return mappedThresholds;
};

/**
 *
 * @param currentAxle the axle jsx element that the fn is on
 * @param currentTire The current tire element that the fn is bound to
 * @param selected
 * @returns
 */
export const isCurrentTireSelected = (
  currentAxle: TPMS_Axle,
  currentTire: TPMS_Tire,
  selectedTire: TPMS_Tire,
  selectedAxle: TPMS_Axle
) => Boolean(currentAxle === selectedAxle && currentTire === selectedTire);

export const isCurrentDualImbalanceSelected = (
  currentAxle: TPMS_Axle,
  currentDualImbalanceSide: AxleSide,
  selectedDualImbalanceRecord: ImbalanceVariantsByAxle
) => {
  const currentRecord = `${currentDualImbalanceSide}${MAP_AXLE_TO_NUMBER[currentAxle]}`;

  return Boolean(selectedDualImbalanceRecord === currentRecord);
};

import { FC } from "react";
import { GridRowParams, useGridApiRef } from "@mui/x-data-grid-premium";
import {
  SensorProfileConfigType,
  ProfileConfigProperty,
  TableViewType,
} from "../../../../graphql/operations";
import AirBagProfileTable from "./Tables/AirBagProfileTable";
import AirSupplyProfileTable from "./Tables/AirSupplyProfileTable";
import AtisProfileTable from "./Tables/AtisProfileTable";
import BatteriesProfileTable from "./Tables/BatteriesProfileTable";
import BrakesProfileTable from "./Tables/BrakesProfileTable";
import CameraProfileTable from "./Tables/CargoCameraProfileTable";
import LiftgateProfileTable from "./Tables/LiftgateProfileTable";
import LiteSentryGammaProfileTable from "./Tables/LiteSentryGammaProfileTable";
import PsiWheelEndProfileTable from "./Tables/PsiWheelEndProfileTable";
import RollStabilityProfileTable from "./Tables/RollStabilityProfileTable";
import TemperatureProfileTable from "./Tables/TemperatureProfileTable";
import TirePressureProfileTable from "./Tables/TirePressureProfileTable";
import { ProfilesData } from "./profileUtils";

export interface TableGatewayProps {
  type: ProfileConfigProperty | SensorProfileConfigType;
  orgId: string | undefined;
  profilesData: ProfilesData;
  onRowClick: (data: GridRowParams) => void;
}

const ProfilesTableGateway: FC<TableGatewayProps> = ({
  type,
  profilesData,
  onRowClick,
}) => {
  const gridApiRef = useGridApiRef();

  switch (type) {
    case SensorProfileConfigType.Voltage:
      return (
        <BatteriesProfileTable
          tableType={TableViewType.VoltageProfiles}
          apiRef={gridApiRef}
          onRowClick={onRowClick}
          profilesData={profilesData}
        />
      );
    case SensorProfileConfigType.TpmsBeta:
      return (
        <TirePressureProfileTable
          tableType={TableViewType.TpmsBetaProfiles}
          apiRef={gridApiRef}
          onRowClick={onRowClick}
          profilesData={profilesData}
        />
      );
    case ProfileConfigProperty.AirSupply:
      return (
        <AirSupplyProfileTable
          tableType={TableViewType.SupplyPressureProfiles}
          apiRef={gridApiRef}
          onRowClick={onRowClick}
          profilesData={profilesData}
        />
      );
    case SensorProfileConfigType.Airbag:
      return (
        <AirBagProfileTable
          tableType={TableViewType.AirBagProfiles}
          apiRef={gridApiRef}
          onRowClick={onRowClick}
          profilesData={profilesData}
        />
      );
    case SensorProfileConfigType.Temperature:
      return (
        <TemperatureProfileTable
          tableType={TableViewType.TemperatureProfiles}
          apiRef={gridApiRef}
          onRowClick={onRowClick}
          profilesData={profilesData}
        />
      );
    case SensorProfileConfigType.CargoCamera:
      return (
        <CameraProfileTable
          tableType={TableViewType.CargoCameraProfiles}
          apiRef={gridApiRef}
          onRowClick={onRowClick}
          profilesData={profilesData}
        />
      );
    case SensorProfileConfigType.PsiWheelEnd:
      return (
        <PsiWheelEndProfileTable
          tableType={TableViewType.PsiWheelEndProfiles}
          apiRef={gridApiRef}
          onRowClick={onRowClick}
          profilesData={profilesData}
        />
      );
    case SensorProfileConfigType.AtisAlpha:
      return (
        <AtisProfileTable
          tableType={TableViewType.AtisAlphaProfiles}
          apiRef={gridApiRef}
          onRowClick={onRowClick}
          profilesData={profilesData}
        />
      );
    case SensorProfileConfigType.LiteSentryGamma:
      return (
        <LiteSentryGammaProfileTable
          tableType={TableViewType.LiteSentryGammaProfiles}
          apiRef={gridApiRef}
          onRowClick={onRowClick}
          profilesData={profilesData}
        />
      );
    case SensorProfileConfigType.Liftgate:
      return (
        <LiftgateProfileTable
          tableType={TableViewType.LiftgateProfiles}
          apiRef={gridApiRef}
          onRowClick={onRowClick}
          profilesData={profilesData}
        />
      );
    case SensorProfileConfigType.Brakes:
      return (
        <BrakesProfileTable
          tableType={TableViewType.BrakesProfiles}
          apiRef={gridApiRef}
          onRowClick={onRowClick}
          profilesData={profilesData}
        />
      );
    case SensorProfileConfigType.RollStability:
      return (
        <RollStabilityProfileTable
          tableType={TableViewType.RollStabilityProfiles}
          apiRef={gridApiRef}
          onRowClick={onRowClick}
          profilesData={profilesData}
        />
      );
    default:
      return null;
  }
};

export default ProfilesTableGateway;

import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FieldValues } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  ImageList,
  ImageListItem,
  ThemeProvider,
  CircularProgress,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { PAGE_SNACKBAR } from "../../../constants";
import { useAppContext } from "../../../context/AppContext";
import { useDashboardContext } from "../../../context/DashboardContext";
import { breakpoints } from "../../../design-system";
import {
  Dashboard,
  DashboardLibraryWidget,
  DashboardType,
  DashboardVisibility,
  DashboardWidgets,
  useDeleteDashboardMutation,
  useGetDashboardQuery,
  useGetDashboardWidgetsLibraryQuery,
  useUpdateDashboardMutation,
} from "../../../graphql/operations";
import Page from "../../../shared/components/Page";
import { isAdmin } from "../../../shared/components/WithPermissions";
import { useCurrentOrg } from "../../../shared/hooks/useCurrentOrg";
import { useSelectedOrg } from "../../../shared/hooks/useSelectedOrg";
import { useUserData } from "../../../shared/hooks/useUserData";
import useWindowSize from "../../../shared/hooks/useWindowSize";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../utils";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { AddDashboardWidgetDrawer } from "../DashboardWidgetDrawer/AddDashboardWidgetDrawer";
import CreateEditDashboardDialog, {
  DashboardDialogType,
} from "../DashboardsTable/components/CreateEditDashboardDialog";
import { DashboardWidgetMapper } from "./components/DashboardWidgetMapper";
import Header from "./components/Header";
import NoWidgetsToDisplay from "./components/NoWidgetsToDisplay";
import SaveDiscardChangesPopup from "./components/SaveDiscardChangesPopup";
import EmptyWidget from "./components/widgets/EmptyWidget";
import {
  DEFAULT_DASHBOARD_END_DATE_DATESTRING,
  DEFAULT_DASHBOARD_START_DATE_DATESTRING,
} from "./shared";
import { useDashboardViewTheme } from "./useDashboardViewTheme";

export type UpdateDashboardProps = {
  dashboardId: number;
  name: string;
  widgets: Partial<DashboardWidgets>[];
  visibility?: DashboardVisibility;
};

// Local consts
const GRID_GAP = 16;
const PADDING = 12;

const DashboardView: React.FC = () => {
  // React and context
  const navigate = useNavigate();
  const { id: dashboardId } = useParams();
  const { dispatch } = useAppContext();
  const {
    setDashboardId,
    setDashboardWidgets,
    dashboardWidgets,
    setDashboardUserId,
    initialDashboardWidgets,
    setInitialDashboardWidgets,
  } = useDashboardContext();

  // Custom hooks and app data
  const userData = useUserData();
  const theme = useDashboardViewTheme();
  const queryClient = useQueryClient();
  const customerOrganization = useCurrentOrg();
  const selectedOrganization = useSelectedOrg();
  const { width } = useWindowSize();

  const page = useRef();

  // Local state
  const [dashboard, setDashboard] = useState<Dashboard>();
  const [dashboardWidgetKeys, setDashboardWidgetKeys] = useState<string[]>();
  const [columnNumber, setColumnNumber] = useState<number>(0);
  const [gridWidth, setGridWidth] = useState<number>(width ?? 0);
  const [editDashboardPopupOpened, setEditDashboardPopupOpened] =
    useState<boolean>(false);
  const [showAddWidgetDrawer, setShowAddWidgetDrawer] =
    useState<boolean>(false);
  const [newlyAddedWidgets, setNewlyAddedWidgets] = useState<
    Partial<DashboardLibraryWidget>[]
  >([]);

  const orgIdInput = useMemo(
    () => selectedOrganization.value,
    [selectedOrganization]
  );
  const isAnyAdmin = useMemo(
    () => isAdmin(userData?.groups ?? []),
    [userData?.groups]
  );
  // Queries
  const {
    data: dashboardWidgetsList,
    isFetching: isGetDashboardWidgetsLoading,
  } = useGetDashboardWidgetsLibraryQuery(
    {},
    {
      enabled: Boolean(dashboard?.type === DashboardType.Custom || isAnyAdmin),
    }
  );
  const { isLoading: isDashboardLoading, data } = useGetDashboardQuery(
    {
      input: {
        id: Number(dashboardId),
        orgId: orgIdInput,
        fromDate: DEFAULT_DASHBOARD_START_DATE_DATESTRING,
        toDate: DEFAULT_DASHBOARD_END_DATE_DATESTRING,
      },
    },
    {
      /*
        Dashboard ID needs to be handled delicately
        because it's an Int and 0 is valid but Boolean(0) would be false
      */
      enabled: Boolean(dashboardId ?? false) && Boolean(orgIdInput),
    }
  );

  // These depend on the queries so must come after
  const dashboardWidgetsKeyToIdMapping = useMemo(() => {
    const mapping: { [key: string]: number } = {};

    dashboardWidgetsList?.getDashboardWidgetsLibrary?.widgets?.forEach(
      (widget) => {
        if (widget?.key) {
          mapping[widget.key] = widget?.id;
        }
      }
    );

    return mapping;
  }, [dashboardWidgetsList]);

  const widgetsAddedFromDrawer = useMemo(() => {
    const widgetsToAdd =
      newlyAddedWidgets.map((widget) => ({ widgetId: widget.id })) ?? [];
    const currentDashboardWidgets =
      dashboardWidgets.map((widget) => ({
        widgetId: dashboardWidgetsKeyToIdMapping[widget.key],
      })) ?? [];

    const uniqueWidgetsToAdd = [
      ...currentDashboardWidgets,
      ...widgetsToAdd,
    ].filter(
      (widget, index, self) =>
        index === self.findIndex((w) => w.widgetId === widget.widgetId)
    );
    return uniqueWidgetsToAdd;
  }, [dashboardWidgets, dashboardWidgetsKeyToIdMapping, newlyAddedWidgets]);

  // Effects
  useEffect(() => {
    if (width) {
      let itemWidth;
      let numberOfColumns = 1;
      if (width > breakpoints.md) {
        numberOfColumns = 2;
      }

      itemWidth = window.innerWidth / numberOfColumns - (GRID_GAP + PADDING);

      const listItemFullWidth = itemWidth;
      const columnFit = Math.trunc(width / (listItemFullWidth + GRID_GAP));
      setColumnNumber(numberOfColumns);
      setGridWidth(columnFit * listItemFullWidth + GRID_GAP);
    }
  }, [width, setColumnNumber, setGridWidth]);

  useEffect(() => {
    if (data?.getDashboard) {
      const widgets = data.getDashboard?.widgets ?? [];
      setDashboardUserId(data.getDashboard.dashboard.userId ?? null);
      setDashboard(data.getDashboard.dashboard);
      setDashboardWidgetKeys(widgets.map((widget) => widget.key));
      setDashboardWidgets(widgets);
    }
  }, [
    data,
    setDashboard,
    setDashboardWidgetKeys,
    setDashboardWidgets,
    setDashboardUserId,
  ]);

  useEffect(() => {
    if (dashboardId) {
      setDashboardId(Number(dashboardId));
    }
  }, [dashboardId, setDashboardId]);

  // Mutations
  const {
    mutate: updateDashboardMutation,
    isLoading: updateDashboardMutationProcessing,
  } = useUpdateDashboardMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getDashboard"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["getDashboardsForTable"],
      });
      await queryClient.removeQueries({ queryKey: ["getDashboardsForTable"] });
      await queryClient.invalidateQueries({
        queryKey: ["getFavoriteDashboardsNames"],
      });

      // reset the state that holds the newly added widgets after dashboard update
      setNewlyAddedWidgets([]);

      setEditDashboardPopupOpened(false);

      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Dashboard Updated Successfully!",

          severity: "success",
        },
      });
    },
  });

  const { mutate: deleteDashboardMutation } = useDeleteDashboardMutation({
    onSuccess: async () => {
      setEditDashboardPopupOpened(false);
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Dashboard Deleted Successfully!",
          severity: "success",
        },
      });

      queryClient.removeQueries({ queryKey: ["getDashboardsForTable"] });
      queryClient.removeQueries({ queryKey: ["getFavoriteDashboardsNames"] });

      navigate(NavigationRoutes.Dashboard);
    },
    onError: (error) => {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Dashboard deleting failed!",
          text: mapServerErrorCodeToHumanReadableMessage(
            (error as Error).message
          ),
          severity: "error",
        },
      });
    },
  });

  // Callbacks
  const showWidgetsDrawer = useCallback(() => {
    setShowAddWidgetDrawer(true);
  }, [setShowAddWidgetDrawer]);

  const updateDashboard = useCallback(
    ({ dashboardId, name, visibility, widgets }: UpdateDashboardProps) => {
      const input = {
        dashboardId,
        name,
        widgets,
        ...(visibility && { visibility }),
      };

      updateDashboardMutation({ input });
    },
    [updateDashboardMutation]
  );

  const handleEditDashboard = useCallback(
    (formData: FieldValues) => {
      // Because these are Ints we can't do if(!dashboard?.id)
      const dashboardId = dashboard?.id ?? false;
      const name = formData.name ?? false;
      const widgets = widgetsAddedFromDrawer;
      const visibility = formData.public
        ? DashboardVisibility.Public
        : DashboardVisibility.Private;

      if (dashboardId && name) {
        updateDashboard({
          dashboardId,
          name,
          visibility,
          widgets,
        });
      }
    },
    [updateDashboard, dashboard?.id, widgetsAddedFromDrawer]
  );

  const handleSaveDashboardChanges = useCallback(() => {
    // Because these are Ints we can't do if(!dashboard?.id)
    const dashboardId = dashboard?.id ?? false;
    const name = dashboard?.name ?? false;
    const widgets = widgetsAddedFromDrawer;
    const visibility = dashboard?.visibility ?? DashboardVisibility.Private;

    if (dashboardId && name) {
      updateDashboard({
        dashboardId,
        name,
        widgets,
        visibility,
      });
      setInitialDashboardWidgets([]);
    }
  }, [
    updateDashboard,
    dashboard,
    widgetsAddedFromDrawer,
    setInitialDashboardWidgets,
  ]);

  const handleDiscardChanges = useCallback(() => {
    setNewlyAddedWidgets([]);
    if (initialDashboardWidgets.length) {
      setDashboardWidgets(initialDashboardWidgets);
    }
    setInitialDashboardWidgets([]);
  }, [
    setNewlyAddedWidgets,
    setDashboardWidgets,
    initialDashboardWidgets,
    setInitialDashboardWidgets,
  ]);

  const handleDeleteDashboard = useCallback(() => {
    deleteDashboardMutation({
      input: {
        id: dashboard?.id as number,
        orgId: userData?.customerOrg._id ?? "",
      },
    });
  }, [deleteDashboardMutation, dashboard?.id, userData?.customerOrg?._id]);

  const addNewWidgetToDashboard = useCallback(
    (newWidget: Partial<DashboardLibraryWidget>) => {
      // Step 3: Use the setter function to update the state immutably
      setNewlyAddedWidgets((prevWidgets) => [...prevWidgets, newWidget]);
    },
    [setNewlyAddedWidgets]
  );

  // TODO: Add error message when Dashboard not found
  const handleAddWidget = useCallback(
    (widgetToAdd: DashboardLibraryWidget): void => {
      // Check if the widget is already in the array
      if (newlyAddedWidgets.some((widget) => widget.key === widgetToAdd.key)) {
        return;
      }
      addNewWidgetToDashboard(widgetToAdd);
    },
    [newlyAddedWidgets, addNewWidgetToDashboard]
  );

  const isDashboardFavoriteForTheUserOrganization = useMemo(() => {
    return (customerOrganization?.favorite_dashboards ?? []).includes(
      parseInt(dashboardId as string)
    );
  }, [customerOrganization?.favorite_dashboards, dashboardId]);

  return (
    <ThemeProvider theme={theme}>
      <AddDashboardWidgetDrawer
        open={showAddWidgetDrawer}
        setOpen={setShowAddWidgetDrawer}
        onAddWidget={handleAddWidget}
        currentDashboardWidgetKeys={dashboardWidgetKeys ?? []}
        dashboardWidgetsList={
          (dashboardWidgetsList?.getDashboardWidgetsLibrary
            ?.widgets as DashboardLibraryWidget[]) ?? []
        }
      />
      <Page
        ref={page}
        className={"bg-concrete h-auto"}
        styles={{
          backgroundColor: "var(--dashboard-view-background)",
          height:
            isDashboardLoading || dashboardWidgets.length === 0
              ? "100%"
              : "auto",
        }}
        data-testid="page-dashboard"
      >
        <CreateEditDashboardDialog
          dashboard={dashboard}
          isLoading={isGetDashboardWidgetsLoading}
          open={editDashboardPopupOpened}
          processing={updateDashboardMutationProcessing}
          type={DashboardDialogType.Edit}
          onClose={() => setEditDashboardPopupOpened(false)}
          onDelete={handleDeleteDashboard}
          onSubmit={handleEditDashboard}
          isDashboardFavoriteForTheUserOrganization={
            isDashboardFavoriteForTheUserOrganization
          }
        />
        <Header
          dashboard={dashboard}
          setEditDashboardPopupOpened={setEditDashboardPopupOpened}
          showWidgetsDrawer={showWidgetsDrawer}
          isLoading={isDashboardLoading}
        />
        <SaveDiscardChangesPopup
          showPopup={Boolean(
            initialDashboardWidgets.length || newlyAddedWidgets.length // if we have initialDashboardWidgets it means we deleted a widget
          )}
          onSave={handleSaveDashboardChanges}
          onDiscard={handleDiscardChanges}
          processing={updateDashboardMutationProcessing}
        />
        {isDashboardLoading ? (
          <Box
            className="flex h-full w-full items-center justify-center"
            data-testid="asset-summary-loader"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {orgIdInput &&
            (dashboardWidgets.length || newlyAddedWidgets.length) ? (
              <div
                style={{
                  padding: "16px 16px 16px 16px",
                  width: `${gridWidth}px`,
                  margin: `0 auto`,
                }}
                data-testid="dashboard-widgets"
              >
                <ImageList variant="masonry" cols={columnNumber} gap={GRID_GAP}>
                  {dashboardWidgets.map((dashboardWidget, index) => {
                    return (
                      <ImageListItem key={index} sx={{ border: "none" }}>
                        <DashboardWidgetMapper widget={dashboardWidget} />
                      </ImageListItem>
                    );
                  })}
                  {newlyAddedWidgets.map((dashboardWidget, index) => {
                    if (
                      dashboardWidgets.some(
                        (widget) => widget.key === dashboardWidget.key
                      )
                    ) {
                      // do not show empty widget if it already exists and its loaded on the page
                      return <></>;
                    }
                    return (
                      <ImageListItem key={index} sx={{ border: "none" }}>
                        <EmptyWidget widget={dashboardWidget} />
                      </ImageListItem>
                    );
                  })}
                </ImageList>
              </div>
            ) : (
              <NoWidgetsToDisplay />
            )}
          </>
        )}
      </Page>
    </ThemeProvider>
  );
};

DashboardView.displayName = "DashboardView";
export default memo(DashboardView);

import * as yup from "yup";
import { shouldNoteHaveTrimSpaces } from "../../../../../../../../utils/yupUtils";

yup.addMethod(yup.mixed, "shouldNoteHaveTrimSpaces", shouldNoteHaveTrimSpaces);

export const rollStabilityDrawerFormSchema = yup.object().shape({
  sudden: yup
    .string()
    .required("Field is required!")
    .shouldNoteHaveTrimSpaces()
    .nullable(),
  continuous: yup
    .string()
    .required("Field is required!")
    .shouldNoteHaveTrimSpaces()
    .nullable(),
});

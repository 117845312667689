import { FC } from "react";
import { GridRowId } from "@mui/x-data-grid-premium";
import { OrgData } from "../../../../graphql/operations";
import ABSFaultCodesConfigurationSetDrawer from "./Drawers/ABSFaultCode/Drawer";
import CreateEditAssetConfigDrawer from "./Drawers/Asset/CreateEditAssetConfigDrawer";
import CreateOrEditDetentionRuleConfigDrawer from "./Drawers/DetentionRules/CreateOrEditDetentionRuleConfigDrawer";
import CreateEditGeofenceTypeConfigDrawer from "./Drawers/Geofence/CreateEditGeofenceTypeConfigDrawer";
import ProductsDrawer from "./Drawers/Product/ProductsDrawer";
import { DrawerType } from "./configurationsUtils";

export interface DrawerGatewayProps {
  isOpen: boolean;
  selectedConfigId?: GridRowId;
  type: DrawerType;
  selectedOrg?: OrgData;
  onClose: () => void;
  onRequestClose: () => void;
}

const drawer: Record<DrawerType, React.FC<any>> = {
  createAsset: CreateEditAssetConfigDrawer,
  editAsset: CreateEditAssetConfigDrawer,
  createGeofenceType: CreateEditGeofenceTypeConfigDrawer,
  editGeofenceType: CreateEditGeofenceTypeConfigDrawer,
  createProduct: ProductsDrawer,
  editProduct: ProductsDrawer,
  createFaultCode: ABSFaultCodesConfigurationSetDrawer,
  editFaultCode: ABSFaultCodesConfigurationSetDrawer,
  createDetentionRule: CreateOrEditDetentionRuleConfigDrawer,
  editDetentionRule: CreateOrEditDetentionRuleConfigDrawer,
};

const DrawerGateway: FC<DrawerGatewayProps> = (props) => {
  const {
    isOpen,
    selectedConfigId,
    selectedOrg,
    type,
    onClose,
    onRequestClose,
    ...rest
  } = props;

  const Component = drawer[type];
  return (
    <Component
      isOpen={isOpen}
      type={type}
      selectedOrgId={selectedOrg?._id}
      selectedConfigId={selectedConfigId}
      onRequestClose={onRequestClose}
      onClose={onClose}
      {...rest}
    />
  );
};

export default DrawerGateway;

import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { AutocompleteElement, TextFieldElement } from "react-hook-form-mui";
import { Grid } from "@mui/material";
import { useAssetParameters } from "../../hooks/useAssetParameters";
import { AlertFormValues } from "../../interfaces";
import { cargoFloorSpaceOptions } from "../../utils";
import { AlertParametersContainer } from "../AlertParametersContainer";
import { AssetsParameters } from "./AssetsParameters";

interface AlertCargoFloorSpaceParametersProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}

export const AlertCargoFloorSpaceParameters: FC<
  AlertCargoFloorSpaceParametersProps
> = ({ form }) => {
  const {
    assetTypeOptions,
    setAssetNameSearch,
    watchAssetsValue,
    watchAssetTypeValue,
    isFetching,
    hideInputValue,
    setSelectedAsset,
    assetsOptionsList,
    setAssetsOptionsList,
  } = useAssetParameters({ form });

  const [watchedOperatorValue] = form.watch([
    "parameters.cargoFloorSpace.operator",
  ]);
  const isBetweenOperator = watchedOperatorValue === ">= && <=";
  const isGteOrLteOperator =
    watchedOperatorValue === ">" || watchedOperatorValue === "<";

  return (
    <AlertParametersContainer>
      <Grid item xs={12}>
        <Grid container spacing={6}>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            xl={4}
            data-testid="operator-control"
          >
            <AutocompleteElement
              label="Operator"
              options={cargoFloorSpaceOptions}
              name="parameters.cargoFloorSpace.operator"
              control={form.control}
              matchId={true}
            />
          </Grid>

          {!isBetweenOperator && watchedOperatorValue && (
            <Grid item xs={12} md={6} lg={6} xl={4} data-testid="value-control">
              <TextFieldElement
                label="Value"
                name="parameters.cargoFloorSpace.value"
                type={"number"}
                control={form.control}
                fullWidth
              />
            </Grid>
          )}

          {!isGteOrLteOperator && watchedOperatorValue && (
            <>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                xl={4}
                data-testid="from-control"
              >
                <TextFieldElement
                  label="From"
                  name="parameters.cargoFloorSpace.from"
                  type={"number"}
                  control={form.control}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6} xl={4} data-testid="to-control">
                <TextFieldElement
                  label="To"
                  name="parameters.cargoFloorSpace.to"
                  type={"number"}
                  control={form.control}
                  fullWidth
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <AssetsParameters
        form={form}
        setAssetNameSearch={setAssetNameSearch}
        setSelectedAsset={setSelectedAsset}
        assetsOptionsList={assetsOptionsList}
        setAssetsOptionsList={setAssetsOptionsList}
        assetTypeOptions={assetTypeOptions}
        watchAssetsValue={watchAssetsValue}
        watchAssetTypeValue={watchAssetTypeValue}
        isFetching={isFetching}
        hideInputValue={hideInputValue}
        gridSizeXl={4}
        assetsSelectTestId="cargo-floor-space-assets-select"
        assetTypeSelectTestId="cargo-floor-space-asset-type-select"
      />
    </AlertParametersContainer>
  );
};

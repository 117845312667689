import { getGridSingleSelectOperators } from "@mui/x-data-grid-premium";
import { EventHistoryEventNameUi } from "../../../../../constants/map";
import {
  PressureUnit,
  SearchEventHistoryResultData,
  TemperatureUnit,
} from "../../../../../graphql/operations";
import { SingleSelectTableFilterValue } from "../../../../../shared/components/Table";
import { getConvertedPressureValue } from "../../../../../utils/convertPressure";
import { getConvertedTemperatureValue } from "../../../../../utils/convertTemperature";

export const getIsSingleSelectFilterOperator = () => {
  return getGridSingleSelectOperators().filter(
    (f) => f.value === SingleSelectTableFilterValue.Is
  );
};

export const getGpsLockFilterOperatorOptions = () => {
  return [
    {
      label: "Locked",
      value: "true",
    },
    {
      label: "Unlocked",
      value: "false",
    },
  ];
};

export const getEventNameFilterOperatorOptions = () =>
  Object.values(EventHistoryEventNameUi)
    .filter(
      (eventUiName) => eventUiName !== EventHistoryEventNameUi.UnknownEvent
    )
    .map((eventUiName) => ({
      label: eventUiName,
      value: eventUiName,
    }));

export const convertedTemperatureColumnValueGetter = ({
  row,
  fieldName,
  temperatureUnit,
}: {
  row: SearchEventHistoryResultData;
  fieldName: keyof SearchEventHistoryResultData;
  temperatureUnit: TemperatureUnit;
}) => {
  const temp = row[fieldName];
  if (!temp) return null;
  return getConvertedTemperatureValue(temp, temperatureUnit);
};

export const convertedPressureColumnValueGetter = ({
  row,
  fieldName,
  pressureUnit,
}: {
  row: SearchEventHistoryResultData;
  fieldName: keyof SearchEventHistoryResultData;
  pressureUnit: PressureUnit;
}) => {
  const pressure = row[fieldName];
  if (!pressure) return null;
  return getConvertedPressureValue(pressure, pressureUnit);
};

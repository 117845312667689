import {
  GridValueFormatterParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-premium";
import {
  PressureUnit,
  SearchEventHistoryResultData,
  TableColumnFormat,
  TemperatureUnit,
} from "../../../../../graphql/operations";
import {
  TableColumnProps,
  TableGridColDef,
  getTableColumn,
} from "../../../../../shared/components/Table";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";
import { getConvertedPressureValue } from "../../../../../utils/convertPressure";
import {
  getConvertedTemperatureValue,
  getTemperatureUnitLabel,
} from "../../../../../utils/convertTemperature";
import { convertKmToMiles } from "../../../../../utils/convertUnits";
import {
  convertedPressureColumnValueGetter,
  convertedTemperatureColumnValueGetter,
  getEventNameFilterOperatorOptions,
  getGpsLockFilterOperatorOptions,
  getIsSingleSelectFilterOperator,
} from "./helpers";

export const columnVisibilityModel = {
  city: false,
  state: false,
  zip: false,
  eventDate: true,
  eventName: true,
  sequenceNumber: true,
  heading: true,
  speed: true,
  odometer: true,
  dwellTime: true,
  gpsLock: true,
  rssi: true,
  tripState: true,
  locationName: true,
  locationType: true,
  locationArrivalTime: true,
  address: true,
  lat: true,
  lon: true,
  internalTemp: true,
  internalTempStatus: true,
  backupBatteryVoltage: true,
  primaryPower: true,
  secondaryPower: true,
  remainingCapacity: true,
  solarCurrent: true,
  cargoStatus: true,
  floorUsagePercentage: true,
  floorUsageStatus: true,
  bleDoorStatus: true,
  numberOfFaults: true,
  atisAlpha: true,
  tpmsBeta: true,
  airbag: true,
  liteSentryGamma: true,
  absBeta: true,
  // PSI Air Supply fields
  psiAirSupply: true,
  psiAirSupply_regulatorPressure: true,
  psiAirSupply_regulatorStatus: true,
  psiAirSupply_tankPressure: true,
  psiAirSupply_tankStatus: true,
  // Cargo fields
  cargo_floor_space_percentage: true,
  cargo_cube_space_percentage: true,
  // Brake Wear fields
  brake_wear_status: true,
  brake_health_status: true,
  axle1_wheel1_brake_health_percentage: true,
  axle1_wheel2_brake_health_percentage: true,
  axle2_wheel1_brake_health_percentage: true,
  axle2_wheel2_brake_health_percentage: true,
  axle1_wheel1_brake_health_temperature_celsius: true,
  axle1_wheel2_brake_health_temperature_celsius: true,
  axle2_wheel1_brake_health_temperature_celsius: true,
  axle2_wheel2_brake_health_temperature_celsius: true,
  axle1_wheel1_brake_health_service_pressure_millibar: true,
  axle1_wheel2_brake_health_service_pressure_millibar: true,
  axle2_wheel1_brake_health_service_pressure_millibar: true,
  axle2_wheel2_brake_health_service_pressure_millibar: true,
  // TTPair fields
  ttpair_status: true,
  ttpair_tractor_number: true,
  // Roll Stability fields
  roll_stability_status: true,
  // Wheelend fields
  axle1_wheelend_left_status: true,
  axle1_wheelend_left_temperature: true,
  axle1_wheelend_right_status: true,
  axle1_wheelend_right_temperature: true,
  axle2_wheelend_left_status: true,
  axle2_wheelend_left_temperature: true,
  axle2_wheelend_right_status: true,
  axle2_wheelend_right_temperature: true,
  axle3_wheelend_left_status: true,
  axle3_wheelend_left_temperature: true,
  axle3_wheelend_right_status: true,
  axle3_wheelend_right_temperature: true,
  // TPMS Dual Imbalance
  axle1_dual_imbalance_roadside_status: true,
  axle1_dual_imbalance_curbside_status: true,
  axle2_dual_imbalance_roadside_status: true,
  axle2_dual_imbalance_curbside_status: true,
  axle3_dual_imbalance_roadside_status: true,
  axle3_dual_imbalance_curbside_status: true,
  // TPMS Tires Axle 1
  // Primary Roadside
  axle1_primary_roadside_status: true,
  axle1_primary_roadside_pressure_status: true,
  axle1_primary_roadside_temperature_f: true,
  axle1_primary_roadside_temperature_status: true,
  // Primary Curbside
  axle1_primary_curbside_status: true,
  axle1_primary_curbside_pressure_status: true,
  axle1_primary_curbside_temperature_f: true,
  axle1_primary_curbside_temperature_status: true,
  // Inner Roadside
  axle1_inner_roadside_status: true,
  axle1_inner_roadside_pressure_status: true,
  axle1_inner_roadside_temperature_f: true,
  axle1_inner_roadside_temperature_status: true,
  // Inner Curbside
  axle1_inner_curbside_status: true,
  axle1_inner_curbside_pressure_status: true,
  axle1_inner_curbside_temperature_f: true,
  axle1_inner_curbside_temperature_status: true,
  // TPMS Tires Axle 2
  axle2_primary_roadside_status: true,
  axle2_primary_roadside_pressure_status: true,
  axle2_primary_roadside_temperature_f: true,
  axle2_primary_roadside_temperature_status: true,
  // Primary Curbside
  axle2_primary_curbside_status: true,
  axle2_primary_curbside_pressure_status: true,
  axle2_primary_curbside_temperature_f: true,
  axle2_primary_curbside_temperature_status: true,
  // Inner Roadside
  axle2_inner_roadside_status: true,
  axle2_inner_roadside_pressure_status: true,
  axle2_inner_roadside_temperature_f: true,
  axle2_inner_roadside_temperature_status: true,
  // Inner Curbside
  axle2_inner_curbside_status: true,
  axle2_inner_curbside_pressure_status: true,
  axle2_inner_curbside_temperature_f: true,
  axle2_inner_curbside_temperature_status: true,
  // TPMS Tires Axle 3
  axle3_primary_roadside_status: true,
  axle3_primary_roadside_pressure_status: true,
  axle3_primary_roadside_temperature_f: true,
  axle3_primary_roadside_temperature_status: true,
  // Primary Curbside
  axle3_primary_curbside_status: true,
  axle3_primary_curbside_pressure_status: true,
  axle3_primary_curbside_temperature_f: true,
  axle3_primary_curbside_temperature_status: true,
  // Inner Roadside
  axle3_inner_roadside_status: true,
  axle3_inner_roadside_pressure_status: true,
  axle3_inner_roadside_temperature_f: true,
  axle3_inner_roadside_temperature_status: true,
  // Inner Curbside
  axle3_inner_curbside_status: true,
  axle3_inner_curbside_pressure_status: true,
  axle3_inner_curbside_temperature_f: true,
  axle3_inner_curbside_temperature_status: true,
};

export const EVENT_HISTORY_DEFAULT_SORT_COLUMN = "eventDate";
export const EVENT_HISTORY_PAGE_SIZE = 100;

export const getColumns = ({
  timezone,
  pressureUnit,
  temperatureUnit,
}: {
  timezone: string;
  pressureUnit: PressureUnit;
  temperatureUnit: TemperatureUnit;
}): TableGridColDef<SearchEventHistoryResultData>[] => {
  const columns: TableColumnProps<SearchEventHistoryResultData>[] = [
    {
      field: "eventName",
      headerName: "Event Name",
      type: "singleSelect",
      options: {
        flex: 1,
        minWidth: 200,
        sortable: false,
        valueOptions: getEventNameFilterOperatorOptions(),
        filterOperators: getIsSingleSelectFilterOperator(),
      },
    },
    {
      field: "eventDate",
      headerName: "Event Date",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      options: {
        flex: 1,
        minWidth: 300,
        ...columnTimezoneDateTimeFormatParams(timezone),
      },
    },
    {
      field: "address",
      headerName: "Address",
      options: { flex: 1, minWidth: 300, sortable: false },
    },

    {
      field: "primaryPower",
      headerName: "Primary Voltage",
      type: "number",
      options: {
        flex: 1,
        minWidth: 200,
        valueFormatter: (params: GridValueFormatterParams) =>
          params.value?.toFixed(2),
      },
    },
    {
      field: "secondaryPower",
      headerName: "Secondary Voltage",
      type: "number",
      options: {
        flex: 1,
        minWidth: 200,
        valueFormatter: (params: GridValueFormatterParams) =>
          params.value?.toFixed(2),
      },
    },
    {
      field: "backupBatteryVoltage",
      headerName: "Battery",
      type: "number",
      options: {
        flex: 1,
        minWidth: 150,
        valueFormatter: (params: GridValueFormatterParams) =>
          params.value?.toFixed(2),
      },
    },
    {
      field: "remainingCapacity",
      headerName: "Remaining Capacity",
      type: "number",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "solarCurrent",
      headerName: "Solar Current",
      type: "number",
      options: {
        flex: 1,
        minWidth: 200,
        valueFormatter: (params: GridValueFormatterParams) =>
          params.value?.toFixed(2),
      },
    },
    {
      field: "gpsLock",
      headerName: "Gps Lock",
      type: "singleSelect",
      options: {
        flex: 1,
        minWidth: 200,
        valueGetter: ({
          row,
        }: GridValueGetterParams<SearchEventHistoryResultData>) => {
          return row.gpsLock ? "Yes" : "No";
        },
        sortable: false,
        valueOptions: getGpsLockFilterOperatorOptions(),
        filterOperators: getIsSingleSelectFilterOperator(),
      },
    },
    {
      field: "rssi",
      headerName: "Rssi",
      type: "number",
      options: { flex: 1, minWidth: 100 },
    },
    {
      field: "sequenceNumber",
      headerName: "Sequence Number",
      type: "number",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "tripState",
      headerName: "Trip State",
      options: {
        flex: 1,
        minWidth: 200,
        cellClassName: "capitalize",
        valueGetter: ({
          row,
        }: GridValueGetterParams<SearchEventHistoryResultData>) => {
          return row.tripState === "parked" ? "Parked" : "Moving";
        },
        sortable: false,
      },
    },
    {
      field: "speed",
      headerName: "Speed (mph)",
      type: "number",
      options: {
        flex: 1,
        minWidth: 100,
        valueGetter: ({ row }) => {
          return convertKmToMiles(row.speed || 0);
        },
      },
    },
    {
      field: "heading",
      headerName: "Heading",
      type: "number",
      options: { flex: 1, minWidth: 100 },
    },
    {
      field: "odometer",
      headerName: "Odometer",
      type: "number",
      options: { flex: 1, minWidth: 100 },
    },
    // Temporary removed from the table
    // {
    //   field: "tractorConnected",
    //   headerName: "Trailer Connected",
    //   options: {
    //     flex: 1,
    //     minWidth: 200,
    //     sortable: false,
    //   },
    // },
    {
      field: "engineHours",
      headerName: "Engine Hours",
      type: "number",
      options: { flex: 1, minWidth: 200 },
    },
    {
      field: "internalTemp",
      headerName: "Internal Temperature",
      type: "number",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "internalTempStatus",
      headerName: "Internal Temperature Status",
      options: {
        flex: 1,
        minWidth: 200,
        sortable: false,
      },
    },
    {
      field: "lat",
      headerName: "Latitude",
      type: "number",
      options: { flex: 1, minWidth: 100 },
    },
    {
      field: "lon",
      headerName: "Longitude",
      type: "number",
      options: { flex: 1, minWidth: 150 },
    },
    {
      field: "locationName",
      headerName: "Location Name",
      options: {
        flex: 1,
        minWidth: 200,
        sortable: false,
      },
    },
    {
      field: "locationType",
      headerName: "Location Type",
      options: {
        flex: 1,
        minWidth: 150,
        sortable: false,
      },
    },
    { field: "city", headerName: "City", options: { flex: 1, minWidth: 150 } },
    {
      field: "state",
      headerName: "State",
      options: { flex: 1, minWidth: 100 },
    },
    {
      field: "zip",
      headerName: "Zip",
      type: "number",
      options: { flex: 1, minWidth: 100 },
    },
    {
      field: "locationArrivalTime",
      headerName: "Arrival Time",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      options: {
        flex: 1,
        minWidth: 300,
        ...columnTimezoneDateTimeFormatParams(timezone),
      },
    },
    {
      field: "dwellTime",
      headerName: "Dwell Time",
      type: "number",
      options: { flex: 1, minWidth: 200 },
    },
    {
      field: "atisAlphaSelector",
      headerName: "ATIS Status",
      options: {
        flex: 1,
        minWidth: 100,
        sortable: false,
      },
    },
    {
      field: "axle1_wheelend_left_status",
      headerName: "Wheel End Status Left - Axle 1",
      options: {
        flex: 1,
        minWidth: 100,
        sortable: false,
      },
    },
    {
      field: "axle1_wheelend_left_temperature",
      headerName: "Wheel End Temperature Left - Axle 1",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "axle1_wheelend_right_status",
      headerName: "Wheel End Status Right - Axle 1",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "axle1_wheelend_right_temperature",
      headerName: "Wheel End Temperature Right - Axle 1",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "axle2_wheelend_left_status",
      headerName: "Wheel End Status Left - Axle 2",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "axle2_wheelend_left_temperature",
      headerName: "Wheel End Temperature Left - Axle 2",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "axle2_wheelend_right_status",
      headerName: "Wheel End Status Right - Axle 2",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "axle2_wheelend_right_temperature",
      headerName: "Wheel End Temperature Right - Axle 2",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "axle3_wheelend_left_status",
      headerName: "Wheel End Status Left - Axle 3",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "axle3_wheelend_left_temperature",
      headerName: "Wheel End Temperature Left - Axle 3",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "axle3_wheelend_right_status",
      headerName: "Wheel End Status Right - Axle 3",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "axle3_wheelend_right_temperature",
      headerName: "Wheel End Temperature Right - Axle 3",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "dualImbalanceTpms.axle1.left.gap",
      headerName: "Dual Imbalance Left - Axle 1",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "axle1_dual_imbalance_roadside_status",
      headerName: "Dual Imbalance Roadside Status - Axle 1",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "dualImbalanceTpms.axle1.right.gap",
      headerName: "Dual Imbalance Right - Axle 1",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "axle1_dual_imbalance_curbside_status",
      headerName: "Dual Imbalance Curbside Status - Axle 1",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "dual_imbalance_tpms_axle2_left_gap",
      headerName: "Dual Imbalance Left - Axle 2",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "axle2_dual_imbalance_roadside_status",
      headerName: "Dual Imbalance Roadside Status - Axle 2",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "dual_imbalance_tpms_axle2_right_gap",
      headerName: "Dual Imbalance Right - Axle 2",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "axle2_dual_imbalance_curbside_status",
      headerName: "Dual Imbalance Curbside Status - Axle 2",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "dual_imbalance_tpms_axle3_left_gap",
      headerName: "Dual Imbalance Left - Axle 3",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "axle3_dual_imbalance_roadside_status",
      headerName: "Dual Imbalance Roadside Status - Axle 3",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "dual_imbalance_tpms_axle3_right_gap",
      headerName: "Dual Imbalance Right - Axle 3",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "axle3_dual_imbalance_curbside_status",
      headerName: "Dual Imbalance Curbside Status - Axle 3",
      options: {
        flex: 1,
        minWidth: 100,
      },
    },
    {
      field: "chassis_cargoStatus",
      headerName: "Cargo Status",
      options: {
        flex: 1,
        minWidth: 220,
      },
    },
    {
      field: "camera_floorUsagePercentage",
      headerName: "Floor Space",
      options: {
        flex: 1,
        minWidth: 140,
      },
    },
    {
      field: "bleDoorStatus",
      headerName: "Door Status",
      options: {
        flex: 1,
        minWidth: 140,
      },
    },
    {
      field: "cubeSpace",
      headerName: "Cube Space",
      options: {
        flex: 1,
        minWidth: 140,
      },
    },
    {
      field: "left_airbag_pressure",
      headerName: "Left Air Bag Pressure",
      type: "number",
      options: {
        flex: 1,

        minWidth: 140,
      },
    },
    {
      field: "left_airbag_status",
      headerName: "Left Air Bag Status",
      type: "number",
      options: {
        flex: 1,

        minWidth: 140,
      },
    },
    {
      field: "right_airbag_pressure",
      headerName: "Right Air Bag Pressure",
      type: "number",
      options: {
        flex: 1,

        minWidth: 140,
      },
    },
    {
      field: "right_airbag_status",
      headerName: "Right Air Bag Status",
      type: "number",
      options: {
        flex: 1,

        minWidth: 140,
      },
    },
    // AIRTANK & REGULATOR COLUMNS
    {
      field: "psiAirSupply_regulatorPressure",
      headerName: "Regulator Pressure",
      type: "number",
      options: {
        flex: 1,
        minWidth: 140,
      },
    },
    {
      field: "psiAirSupply_regulatorStatus",
      headerName: "Regulator Status",
      options: {
        flex: 1,
        minWidth: 140,
      },
    },
    {
      field: "psiAirSupply_tankPressure",
      headerName: "Air Tank Pressure",
      type: "number",
      options: {
        flex: 1,
        minWidth: 140,
      },
    },
    {
      field: "psiAirSupply_tankStatus",
      headerName: "Air Tank Status",
      options: {
        flex: 1,
        minWidth: 140,
      },
    },
    // Cargo fields
    {
      field: "cargo_floor_space_percentage",
      headerName: "Cargo Floor Space Percentage",
      options: {
        flex: 1,
        minWidth: 140,
      },
    },
    {
      field: "cargo_cube_space_percentage",
      headerName: "Cargo Cube Space Percentage",
      options: {
        flex: 1,
        minWidth: 140,
      },
    },
    // Brake Wear fields
    {
      field: "brake_wear_status",
      headerName: "Brake Wear Status",
      options: {
        flex: 1,
        minWidth: 140,
      },
    },
    {
      field: "brake_health_status",
      headerName: "Brake Health Status",
      options: {
        flex: 1,
        minWidth: 140,
      },
    },
    {
      field: "axle1_wheel1_brake_health_percentage",
      headerName: "Axle 1 Wheel 1 Brake Wear Percentage",
      type: "number",
      options: {
        flex: 1,
        minWidth: 140,
      },
    },
    {
      field: "axle1_wheel2_brake_health_percentage",
      headerName: "Axle 1 Wheel 2 Brake Wear Percentage",
      type: "number",
      options: {
        flex: 1,
        minWidth: 140,
      },
    },
    {
      field: "axle2_wheel1_brake_health_percentage",
      headerName: "Axle 2 Wheel 1 Brake Wear Percentage",
      type: "number",
      options: {
        flex: 1,
        minWidth: 140,
      },
    },
    {
      field: "axle2_wheel2_brake_health_percentage",
      headerName: "Axle 2 Wheel 2 Brake Wear Percentage",
      type: "number",
      options: {
        flex: 1,
        minWidth: 140,
      },
    },
    {
      field: "axle1_wheel1_brake_health_temperature_celsius",
      headerName: `Axle 1 Wheel 1 Brake Health Temperature ${getTemperatureUnitLabel(
        temperatureUnit,
        true
      )}`,
      type: "number",
      options: {
        flex: 1,
        minWidth: 140,
        valueGetter: ({ row }) => {
          return convertedTemperatureColumnValueGetter({
            row,
            fieldName: "axle1_wheel1_brake_health_temperature_celsius",
            temperatureUnit,
          });
        },
      },
    },
    {
      field: "axle1_wheel2_brake_health_temperature_celsius",
      headerName: `Axle 1 Wheel 2 Brake Health Temperature ${getTemperatureUnitLabel(
        temperatureUnit,
        true
      )}`,
      type: "number",
      options: {
        flex: 1,
        minWidth: 140,
        valueGetter: ({ row }) => {
          return convertedTemperatureColumnValueGetter({
            row,
            fieldName: "axle1_wheel2_brake_health_temperature_celsius",
            temperatureUnit,
          });
        },
      },
    },
    {
      field: "axle2_wheel1_brake_health_temperature_celsius",
      headerName: `Axle 2 Wheel 1 Brake Health Temperature ${getTemperatureUnitLabel(
        temperatureUnit,
        true
      )}`,
      type: "number",
      options: {
        flex: 1,
        minWidth: 140,
        valueGetter: ({ row }) => {
          return convertedTemperatureColumnValueGetter({
            row,
            fieldName: "axle2_wheel1_brake_health_temperature_celsius",
            temperatureUnit,
          });
        },
      },
    },
    {
      field: "axle2_wheel2_brake_health_temperature_celsius",
      headerName: `Axle 2 Wheel 2 Brake Health Temperature ${getTemperatureUnitLabel(
        temperatureUnit,
        true
      )}`,
      type: "number",
      options: {
        flex: 1,
        minWidth: 140,
        valueGetter: ({ row }) => {
          return convertedTemperatureColumnValueGetter({
            row,
            fieldName: "axle2_wheel2_brake_health_temperature_celsius",
            temperatureUnit,
          });
        },
      },
    },
    {
      field: "axle1_wheel1_brake_health_service_pressure_millibar",
      headerName: `Axle 1 Wheel 1 Brake Health Service Pressure ${pressureUnit}`,
      type: "number",
      options: {
        flex: 1,
        minWidth: 140,
        valueGetter: ({ row }) => {
          return convertedPressureColumnValueGetter({
            row,
            fieldName: "axle1_wheel1_brake_health_service_pressure_millibar",
            pressureUnit,
          });
        },
      },
    },
    {
      field: "axle1_wheel2_brake_health_service_pressure_millibar",
      headerName: `Axle 1 Wheel 2 Brake Health Service Pressure ${pressureUnit}`,
      type: "number",
      options: {
        flex: 1,
        minWidth: 140,
        valueGetter: ({ row }) => {
          return convertedPressureColumnValueGetter({
            row,
            fieldName: "axle1_wheel2_brake_health_service_pressure_millibar",
            pressureUnit,
          });
        },
      },
    },
    {
      field: "axle2_wheel1_brake_health_service_pressure_millibar",
      headerName: `Axle 2 Wheel 1 Brake Health Service Pressure ${pressureUnit}`,
      type: "number",
      options: {
        flex: 1,
        minWidth: 140,
        valueGetter: ({ row }) => {
          return convertedPressureColumnValueGetter({
            row,
            fieldName: "axle2_wheel1_brake_health_service_pressure_millibar",
            pressureUnit,
          });
        },
      },
    },
    {
      field: "axle2_wheel2_brake_health_service_pressure_millibar",
      headerName: `Axle 2 Wheel 2 Brake Health Service Pressure ${pressureUnit}`,
      type: "number",
      options: {
        flex: 1,
        minWidth: 140,
        valueGetter: ({ row }) => {
          return convertedPressureColumnValueGetter({
            row,
            fieldName: "axle2_wheel2_brake_health_service_pressure_millibar",
            pressureUnit,
          });
        },
      },
    },
    // TTPair fields
    {
      field: "ttpair_status",
      headerName: "T/T Pair Status",
      options: {
        flex: 1,
        minWidth: 140,
      },
    },
    {
      field: "ttpair_tractor_number",
      headerName: "T/T Pair Tractor Number",
      options: {
        flex: 1,
        minWidth: 140,
      },
    },
    // Roll Stability fields
    {
      field: "roll_stability_status",
      headerName: "Roll Stability Status",
      options: {
        flex: 1,
        minWidth: 140,
      },
    },
    // TPMS states sensor Axle 1
    // Primary Roadside
    {
      field: "axle1_primary_roadside_status",
      headerName: "Primary Roadside Tire Status - Axle 1",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle1_primary_roadside_pressure_status",
      headerName: "Primary Roadside Tire Pressure Status - Axle 1",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle1_primary_roadside_temperature_f",
      headerName: "Primary Roadside Tire Temperature (F) - Axle 1",
      type: "number",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle1_primary_roadside_temperature_status",
      headerName: "Primary Roadside Tire Temperature Status - Axle 1",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    // Primary Curbside
    {
      field: "axle1_primary_curbside_status",
      headerName: "Primary Curbside Tire Status - Axle 1",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle1_primary_curbside_pressure_status",
      headerName: "Primary Curbside Tire Pressure Status - Axle 1",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle1_primary_curbside_temperature_f",
      headerName: "Primary Curbside Tire Temperature (F) - Axle 1",
      type: "number",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle1_primary_curbside_temperature_status",
      headerName: "Primary Curbside Tire Temperature Status - Axle 1",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    // Inner Roadside
    {
      field: "axle1_inner_roadside_status",
      headerName: "Inner Roadside Tire Status - Axle 1",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle1_inner_roadside_pressure_status",
      headerName: "Inner Roadside Tire Pressure Status - Axle 1",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle1_inner_roadside_temperature_f",
      headerName: "Inner Roadside Tire Temperature (F) - Axle 1",
      type: "number",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle1_inner_roadside_temperature_status",
      headerName: "Inner Roadside Tire Temperature Status - Axle 1",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    // Inner Curbside
    {
      field: "axle1_inner_curbside_status",
      headerName: "Inner Curbside Tire Status - Axle 1",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle1_inner_curbside_pressure_status",
      headerName: "Inner Curbside Tire Pressure Status - Axle 1",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle1_inner_curbside_temperature_f",
      headerName: "Inner Curbside Tire Temperature (F) - Axle 1",
      type: "number",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle1_inner_curbside_temperature_status",
      headerName: "Inner Curbside Tire Temperature Status - Axle 1",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    // TPMS states sensor Axle 2
    // Primary Roadside
    {
      field: "axle2_primary_roadside_status",
      headerName: "Primary Roadside Tire Status - Axle 2",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle2_primary_roadside_pressure_status",
      headerName: "Primary Roadside Tire Pressure Status - Axle 2",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle2_primary_roadside_temperature_f",
      headerName: "Primary Roadside Tire Temperature (F) - Axle 2",
      type: "number",
      options: {
        flex: 1,

        minWidth: 200,
      },
    },
    {
      field: "axle2_primary_roadside_temperature_status",
      headerName: "Primary Roadside Tire Temperature Status - Axle 2",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    // Primary Curbside
    {
      field: "axle2_primary_curbside_status",
      headerName: "Primary Curbside Tire Status - Axle 2",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle2_primary_curbside_pressure_status",
      headerName: "Primary Curbside Tire Pressure Status - Axle 2",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle2_primary_curbside_temperature_f",
      headerName: "Primary Curbside Tire Temperature (F) - Axle 2",
      type: "number",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle2_primary_curbside_temperature_status",
      headerName: "Primary Curbside Tire Temperature Status - Axle 2",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    // Inner Roadside
    {
      field: "axle2_inner_roadside_status",
      headerName: "Inner Roadside Tire Status - Axle 2",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle2_inner_roadside_pressure_status",
      headerName: "Inner Roadside Tire Pressure Status - Axle 2",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle2_inner_roadside_temperature_f",
      headerName: "Inner Roadside Tire Temperature (F) - Axle 2",
      type: "number",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle2_inner_roadside_temperature_status",
      headerName: "Inner Roadside Tire Temperature Status - Axle 2",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    // Inner Curbside
    {
      field: "axle2_inner_curbside_status",
      headerName: "Inner Curbside Tire Status - Axle 2",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle2_inner_curbside_pressure_status",
      headerName: "Inner Curbside Tire Pressure Status - Axle 2",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle2_inner_curbside_temperature_f",
      headerName: "Inner Curbside Tire Temperature (F) - Axle 2",
      type: "number",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle2_inner_curbside_temperature_status",
      headerName: "Inner Curbside Tire Temperature Status - Axle 2",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    // TPMS states sensor Axle 3
    // Primary Roadside
    {
      field: "axle3_primary_roadside_status",
      headerName: "Primary Roadside Tire Status - Axle 3",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle3_primary_roadside_pressure_status",
      headerName: "Primary Roadside Tire Pressure Status - Axle 3",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle3_primary_roadside_temperature_f",
      headerName: "Primary Roadside Tire Temperature (F) - Axle 3",
      type: "number",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle3_primary_roadside_temperature_status",
      headerName: "Primary Roadside Tire Temperature Status - Axle 3",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    // Primary Curbside
    {
      field: "axle3_primary_curbside_status",
      headerName: "Primary Curbside Tire Status - Axle 3",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle3_primary_curbside_pressure_status",
      headerName: "Primary Curbside Tire Pressure Status - Axle 3",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle3_primary_curbside_temperature_f",
      headerName: "Primary Curbside Tire Temperature (F) - Axle 3",
      type: "number",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle3_primary_curbside_temperature_status",
      headerName: "Primary Curbside Tire Temperature Status - Axle 3",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    // Inner Roadside
    {
      field: "axle3_inner_roadside_status",
      headerName: "Inner Roadside Tire Status - Axle 3",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle3_inner_roadside_pressure_status",
      headerName: "Inner Roadside Tire Pressure Status - Axle 3",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle3_inner_roadside_temperature_f",
      headerName: "Inner Roadside Tire Temperature (F) - Axle 3",
      type: "number",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle3_inner_roadside_temperature_status",
      headerName: "Inner Roadside Tire Temperature Status - Axle 3",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    // Inner Curbside
    {
      field: "axle3_inner_curbside_status",
      headerName: "Inner Curbside Tire Status - Axle 3",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle3_inner_curbside_pressure_status",
      headerName: "Inner Curbside Tire Pressure Status - Axle 3",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle3_inner_curbside_temperature_f",
      headerName: "Inner Curbside Tire Temperature (F) - Axle 3",
      type: "number",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "axle3_inner_curbside_temperature_status",
      headerName: "Inner Curbside Tire Temperature Status - Axle 3",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    // End of TPMS fields
    {
      field: "liteSentryGammaScenario_6_deviation_value",
      headerName: "Brake Current Deviation - Precheck",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "liteSentryGammaScenario_0_deviation_value",
      headerName: "Brake Current Deviation - Tractor (Blue Powered)",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "liteSentryGammaScenario_5_deviation_value",
      headerName: "Brake Current Deviation - Tractor (Blue Unpowered)",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "liteSentryGammaScenario_9_deviation_value",
      headerName: "Left Turn Signal Current Deviation - Precheck",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "liteSentryGammaScenario_3_deviation_value",
      headerName: "Left Turn Signal Current Deviation - Tractor",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "liteSentryGammaScenario_7_deviation_value",
      headerName: "Right Turn Signal Current Deviation - Precheck",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "liteSentryGammaScenario_1_deviation_value",
      headerName: "Right Turn Signal Current Deviation - Tractor",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "liteSentryGammaScenario_8_deviation_value",
      headerName: "License Current Deviation - Precheck",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "liteSentryGammaScenario_2_deviation_value",
      headerName: "License Current Deviation - Tractor",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "liteSentryGammaScenario_10_deviation_value",
      headerName: "Marker Current Deviation - Precheck",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "liteSentryGammaScenario_4_deviation_value",
      headerName: "Marker Current Deviation - Tractor",
      options: {
        flex: 1,
        minWidth: 200,
      },
    },
    {
      field: "absBeta_numberOfFaults",
      headerName: "ABS Fault Codes",
      type: "number",
      options: {
        flex: 1,
        minWidth: 150,
      },
    },
  ];

  return columns.map(getTableColumn);
};

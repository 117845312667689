import { FC, useMemo, useState } from "react";
import { FieldValues } from "react-hook-form";
import { Grid, Box, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { ReactComponent as PsiWheelEndSensorDark } from "../../../../../assets/svgs/wheelEndTemperatureSensorDark.svg";
import { ReactComponent as PsiWheelEndSensorLight } from "../../../../../assets/svgs/wheelEndTemperatureSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  SensorProfileResult,
  SensorProfileConfigType,
  UpdateOrganizationSensorProfileInput,
} from "../../../../../graphql/operations";
import Drawer from "../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../shared/components/Drawer/DrawerHeader";
import SensorSlider from "../../../../../shared/components/SensorSlider/SensorSlider";
import {
  extractRules,
  prepareRulesPayload,
  sliderMarks,
  wheelEndDefaultValues,
} from "../../../../../shared/components/SensorSlider/sensorSliderUtils";
import {
  MaxValuesBySensorType,
  MinValuesBySensorType,
} from "../../../../../shared/helpers/battery";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import { useGetTemperatureUnitPreference } from "../../../../../shared/hooks/useGetTemperatureUnitPreference";
import {
  getConvertedTemperatureValues,
  getTemperatureUnitLabel,
  prepareConvertedCelsiusTemperaturesPayload,
} from "../../../../../utils/convertTemperature";
import { useSensorProfilesApi } from "../../../hooks/useSensorProfilesApi";
import { DrawerType, PsiWheelEndDrawers } from "../profileUtils";
import DeleteProfileDialog from "./shared/components/DeleteProfileDialog";
import { ProfilesDrawerMainForm } from "./shared/components/ProfilesDrawerMainForm/ProfilesDrawerMainForm";
import { useProfileDrawerMainForm } from "./shared/hooks/useProfileDrawerMainForm";

export type PsiWheelEndDrawerProps = {
  sensorType: SensorProfileConfigType;
  sensorProfileData: SensorProfileResult | undefined;
  type: DrawerType;
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  onProfileMutation: () => void;
  onRequestClose: () => void;
};

const PsiWheelEndDrawer: FC<PsiWheelEndDrawerProps> = ({
  sensorType,
  sensorProfileData,
  type,
  isOpen,
  onClose,
  onProfileMutation,
  onRequestClose,
}) => {
  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    marginTop: "0.75rem",
  };

  const tempUnitPreference = useGetTemperatureUnitPreference();

  const [minWheelEndTemperatureConverted, maxWheelEndTemperatureConverted] =
    getConvertedTemperatureValues(
      [MinValuesBySensorType.psiWheelEnd, MaxValuesBySensorType.psiWheelEnd],
      tempUnitPreference
    );

  const wheelEndTresholds =
    sensorProfileData?.configuration?.psiWheelEnd?.temperature;

  const initialWheelEndValues = getConvertedTemperatureValues(
    extractRules(wheelEndTresholds) || wheelEndDefaultValues,
    tempUnitPreference
  );

  const [wheelEndValues, setWheelEndValues] = useState(initialWheelEndValues);

  const [deletePsiWheelEndPopupState, setDeletePsiWheelEndPopupState] =
    useState(false);

  const isCreateMode = useMemo(() => {
    return type === PsiWheelEndDrawers.Create;
  }, [type]);

  const isEditMode = useMemo(() => {
    return type === PsiWheelEndDrawers.Edit;
  }, [type]);

  const availableOrgs = useAvailableOrgs();

  const currentOrgName = useMemo(() => {
    // Find & show the current organization of the sensor in the dropdown
    if (sensorProfileData?.orgId && isEditMode) {
      const foundOrg = availableOrgs.find(
        (org) => org._id.toString() === sensorProfileData.orgId
      );

      return foundOrg?._id;
    }

    return "";
  }, [availableOrgs, isEditMode, sensorProfileData]);

  const { form: psiWheelEndMainForm } = useProfileDrawerMainForm({
    name: sensorProfileData?.name ?? "",
    orgName: currentOrgName,
    default: sensorProfileData?.default ?? false,
  });

  const toggleDeletePsiWheelEndProfilePopup = () => {
    setDeletePsiWheelEndPopupState(!deletePsiWheelEndPopupState);
  };

  const handlePsiWheelEndProfileCreateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Wheel End Sensor Profile Created Successfully!",
        severity: "success",
      },
    });
  };

  const handlePsiWheelEndProfileCreateError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Creation Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handlePsiWheelEndProfileUpdateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Wheel End Sensor Profile Updated Successfully!",
        severity: "success",
      },
    });
  };

  const handlePsiWheelEndProfileUpdateError = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Update Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handlePsiWheelEndProfileDeleteSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Wheel End Sensor Profile Deleted Successfully!",
        severity: "success",
      },
    });
  };

  const handlePsiWheelEndProfileDeleteError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Deletion Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const {
    updateSensorProfile,
    isLoadingUpdateSensorProfile,
    deleteSensorProfile,
    isSuccessDeleteSensorProfile,
    isLoadingDeleteSensorProfile,
    createSensorProfile,
    isLoadingCreateSensorProfile,
  } = useSensorProfilesApi({
    createSensorProfileOnSuccess: handlePsiWheelEndProfileCreateSuccess,
    createSensorProfileOnError: handlePsiWheelEndProfileCreateError,
    updateSensorProfileOnSuccess: handlePsiWheelEndProfileUpdateSuccess,
    updateSensorProfileOnError: handlePsiWheelEndProfileUpdateError,
    deleteSensorProfileOnSuccess: handlePsiWheelEndProfileDeleteSuccess,
    deleteSensorProfileOnError: handlePsiWheelEndProfileDeleteError,
  });

  const handleDeleteProfile = () => {
    if (isLoadingDeleteSensorProfile || isSuccessDeleteSensorProfile) {
      return;
    }

    deleteSensorProfile({ _id: sensorProfileData?._id ?? "" });
  };

  const handleSubmitClick = async () => {
    const isMainFormValid = await psiWheelEndMainForm.trigger();

    if (!isMainFormValid) return;

    const allFormData: FieldValues = {
      ...psiWheelEndMainForm.getValues(),
    };

    const { thresholds } = prepareRulesPayload(
      undefined,
      prepareConvertedCelsiusTemperaturesPayload(
        wheelEndValues,
        tempUnitPreference
      ),
      MinValuesBySensorType.psiWheelEnd,
      MaxValuesBySensorType.psiWheelEnd
    );

    const configuration = {
      psiWheelEnd: {
        temperature: {
          match: {
            thresholds,
          },
        },
      },
    };

    if (isCreateMode) {
      const sensorProfileData = {
        name: allFormData.name,
        configuration,
        default: allFormData.default ?? false,
        orgId: allFormData?.orgName ?? "",
      };

      createSensorProfile(sensorProfileData);
    } else if (isEditMode) {
      const updateSensorProfilePayload: Record<string, any> = {
        _id: sensorProfileData?._id,
        type: sensorProfileData?.type,
        orgId: sensorProfileData?.orgId, // send current orgId to BE by default
      };

      for (let fieldUpdated in psiWheelEndMainForm.formState.dirtyFields) {
        // Get correct id from dropdown object to send to backend
        if (fieldUpdated === "orgName")
          updateSensorProfilePayload.orgId = allFormData[fieldUpdated];
        else
          updateSensorProfilePayload[fieldUpdated] = allFormData[fieldUpdated];
      }

      updateSensorProfilePayload.configuration = configuration;

      updateSensorProfile(
        updateSensorProfilePayload as UpdateOrganizationSensorProfileInput
      );
    }
  };

  const isLoading =
    isLoadingCreateSensorProfile ||
    isLoadingUpdateSensorProfile ||
    isLoadingDeleteSensorProfile;

  const isSliderDirty =
    wheelEndValues.toString() !== initialWheelEndValues.toString();

  const text = isEditMode ? "Edit Profile" : "Create Profile";

  return (
    <Drawer
      testId="psi-wheel-end-drawer"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <DrawerHeader text={text} onClose={() => onClose(false)} />

      <DrawerContent>
        <Box className="h-full flex flex-col justify-between">
          <Box className="pb-10">
            <ProfilesDrawerMainForm
              form={psiWheelEndMainForm}
              disabled={isLoading}
              isEdit={isEditMode}
            />
            <div className="flex flex-col pb-10">
              <Grid container className="bg-background px-6">
                <Grid item xs={12}>
                  <Box className="mb-16">
                    <Typography sx={{ fontWeight: "bold" }}>
                      Wheel End Temperature Settings
                    </Typography>
                    {isLightTheme ? (
                      <PsiWheelEndSensorDark style={svgIconSettings} />
                    ) : (
                      <PsiWheelEndSensorLight style={svgIconSettings} />
                    )}
                  </Box>
                  <SensorSlider
                    values={wheelEndValues}
                    min={minWheelEndTemperatureConverted}
                    max={maxWheelEndTemperatureConverted}
                    onChange={setWheelEndValues}
                    marks={sliderMarks(
                      minWheelEndTemperatureConverted,
                      maxWheelEndTemperatureConverted,
                      getTemperatureUnitLabel(tempUnitPreference, true)
                    )}
                  />
                </Grid>
              </Grid>
            </div>
          </Box>
          <DrawerActions
            deleteBtnTestId="btn-delete-psi-wheel-end-profile"
            showDeleteBtn={isEditMode}
            disabled={isLoading}
            onCancel={() => onClose(false)}
            onDelete={toggleDeletePsiWheelEndProfilePopup}
            deleteBtnText="Remove"
          />

          {deletePsiWheelEndPopupState && isEditMode && (
            <DeleteProfileDialog
              isOpen={deletePsiWheelEndPopupState}
              onClose={toggleDeletePsiWheelEndProfilePopup}
              isLoading={isLoadingDeleteSensorProfile}
              onClick={handleDeleteProfile}
              testId="psi-wheel-end"
            />
          )}
        </Box>
      </DrawerContent>

      <DrawerFooter
        text={
          isLoadingCreateSensorProfile || isLoadingUpdateSensorProfile
            ? "Saving..."
            : "Save"
        }
        disabled={
          isLoading ||
          (isEmpty(psiWheelEndMainForm.formState.dirtyFields) && !isSliderDirty)
        }
        testId="btn-psi-wheel-end-profile-form-submit"
        submit={handleSubmitClick}
      />
    </Drawer>
  );
};

export default PsiWheelEndDrawer;

import { FC, memo, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as BatterySensorDark } from "../../../../../assets/svgs/batterySensorDark.svg";
import { ReactComponent as BatterySensorLight } from "../../../../../assets/svgs/batterySensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  SensorProfileResult,
  ProfileConfigProperty,
  SensorProfileConfigType,
  UpdateOrganizationSensorProfileInput,
  useCreateOrganizationSensorProfileMutation,
  useDeleteSensorProfileMutation,
  useUpdateOrganizationSensorProfileMutation,
} from "../../../../../graphql/operations";
import Drawer from "../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../shared/components/Drawer/DrawerHeader";
import SensorSlider from "../../../../../shared/components/SensorSlider/SensorSlider";
import {
  sliderMarks,
  extractReversedRules,
  prepareReversedPayload,
  batteryDefaultValues,
  batteryStep,
} from "../../../../../shared/components/SensorSlider/sensorSliderUtils";
import {
  MinValuesBySensorType,
  MaxValuesBySensorType,
} from "../../../../../shared/helpers/battery";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import {
  sensorTypeToSensorNameMap,
  DrawerType,
  BatteryDrawers,
} from "../profileUtils";
import DeleteProfileDialog from "./shared/components/DeleteProfileDialog";
import { ProfilesDrawerMainForm } from "./shared/components/ProfilesDrawerMainForm/ProfilesDrawerMainForm";
import { useProfileDrawerMainForm } from "./shared/hooks/useProfileDrawerMainForm";

export type ProfilesDrawerProps = {
  sensorType: SensorProfileConfigType | ProfileConfigProperty;
  sensorProfileData: SensorProfileResult | undefined;
  type: DrawerType;
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  onProfileMutation: () => void;
};

const ProfilesDrawer: FC<ProfilesDrawerProps> = ({
  sensorType,
  type,
  sensorProfileData,
  onClose,
  isOpen,
  onProfileMutation,
}: ProfilesDrawerProps) => {
  const batteryRules = sensorProfileData?.configuration?.voltage?.battery;
  const parsedBatteryValues = extractReversedRules(batteryRules);
  const initialBattery = parsedBatteryValues || batteryDefaultValues;

  const [battery, setBattery] = useState(initialBattery);
  const [deleteProfilePopup, setDeleteProfilePopup] = useState(false);
  const {
    dispatch,
    state: { theme },
  } = useAppContext();

  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    marginTop: "0.75rem",
  };
  // Fetch & prepare organization options
  const availableOrgs = useAvailableOrgs();

  const isEdit = type === BatteryDrawers.Edit;

  const currentOrgName = useMemo(() => {
    // Find & show the current organization of the sensor in the dropdown
    if (sensorProfileData?.orgId && isEdit) {
      const foundOrg = availableOrgs.find(
        (org) => org._id.toString() === sensorProfileData.orgId
      );

      return foundOrg?._id;
    }

    return "";
  }, [availableOrgs, isEdit, sensorProfileData]);

  // Main Form is always static & will constist of same fields for every sensor
  const { form: batteryMainForm } = useProfileDrawerMainForm({
    name: sensorProfileData?.name ?? "",
    orgName: currentOrgName,
    default: sensorProfileData?.default ?? false,
  });

  const handleSubmitClick = async () => {
    const isMainFormValid = await batteryMainForm.trigger();
    if (!isMainFormValid) return;

    const mainFormData: any = batteryMainForm.getValues();
    const { orgName, name, default: isDefault } = mainFormData;

    const batteryConfig = {
      voltage: {
        battery: {
          match: prepareReversedPayload(
            undefined,
            battery,
            MinValuesBySensorType.battery,
            MaxValuesBySensorType.battery,
            batteryStep
          ),
        },
      },
    };

    if (type === BatteryDrawers.Create) {
      createOrganizationSensorProfile({
        input: {
          name,
          configuration: batteryConfig,
          default: isDefault ?? false,
          orgId: orgName ?? "",
        },
      });
    } else if (isEdit) {
      const updateSensorProfilePayload: Record<string, any> = {
        _id: sensorProfileData?._id,
        type: sensorProfileData?.type,
        orgId: sensorProfileData?.orgId,
      };
      if (name !== sensorProfileData?.name) {
        updateSensorProfilePayload.name = name;
      }
      if (isDefault !== sensorProfileData?.default) {
        updateSensorProfilePayload.default = isDefault;
      }
      if (orgName !== sensorProfileData?.orgId) {
        updateSensorProfilePayload.orgId = orgName;
      }
      updateSensorProfilePayload.configuration = batteryConfig;

      updateSensorProfile({
        input:
          updateSensorProfilePayload as UpdateOrganizationSensorProfileInput,
      });
    }
  };

  const { mutate: createOrganizationSensorProfile, isLoading } =
    useCreateOrganizationSensorProfileMutation({
      onSuccess: () => {
        onProfileMutation();
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Success!",
            text: `${
              sensorTypeToSensorNameMap[
                sensorType as keyof typeof sensorTypeToSensorNameMap
              ]
            } Sensor Profile Created Successfully!`,
            severity: "success",
          },
        });
      },
      onError: () => {
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Sensor Profile Creation Failed",
            text: "Something Went Wrong.",
            severity: "error",
            onClose: () => {},
          },
        });
      },
    });

  const { mutate: updateSensorProfile, isLoading: isUpdateLoading } =
    useUpdateOrganizationSensorProfileMutation({
      onSuccess: async () => {
        onProfileMutation();
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Success!",
            text: `${
              sensorTypeToSensorNameMap[
                sensorType as keyof typeof sensorTypeToSensorNameMap
              ]
            } Sensor Profile Updated Successfully!`,
            severity: "success",
          },
        });
      },
      onError: () => {
        onProfileMutation();
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Sensor Profile Update Failed",
            text: "Something Went Wrong.",
            severity: "error",
            onClose: () => {},
          },
        });
      },
    });

  const {
    mutate: deleteSensorProfile,
    isLoading: deletionLoading,
    isSuccess: deleteSuccess,
  } = useDeleteSensorProfileMutation({
    onSuccess: async () => {
      onProfileMutation();
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Success!",
          text: `${
            sensorTypeToSensorNameMap[
              sensorType as keyof typeof sensorTypeToSensorNameMap
            ]
          } Sensor Profile Deleted Successfully!`,
          severity: "success",
        },
      });
    },
    onError: () => {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Sensor Profile Deletion Failed",
          text: "Something Went Wrong.",
          severity: "error",
          onClose: () => {},
        },
      });
    },
  });

  const handleDeleteProfile = () => {
    if (deletionLoading || deleteSuccess) {
      return;
    }

    deleteSensorProfile({ input: { _id: sensorProfileData?._id ?? "" } });
  };

  const toggleDeleteProfilePopup = () => {
    setDeleteProfilePopup(!deleteProfilePopup);
  };

  const handleClose = () => onClose(false);

  const batteryMarks = sliderMarks(
    MinValuesBySensorType.battery,
    MaxValuesBySensorType.battery,
    "V"
  );

  const headerText = `${isEdit ? "Edit" : "Create"} Profile`;

  const isLoadingOrUpdating = isLoading || isUpdateLoading || deletionLoading;
  const isInvalid = isLoadingOrUpdating || !batteryMainForm.formState.isValid;
  const isEditAndPristine =
    isEdit &&
    !batteryMainForm.formState.isDirty &&
    battery.toString() === initialBattery.toString();

  return (
    <Drawer
      isOpen={isOpen}
      onRequestClose={handleClose}
      testId="battery-profile-drawer"
    >
      <DrawerHeader text={headerText} onClose={handleClose} />

      <DrawerContent>
        <Box className="h-full flex flex-col justify-between">
          <Box>
            <ProfilesDrawerMainForm
              form={batteryMainForm}
              disabled={isLoading}
              isEdit={isEdit}
            />

            <Box className="px-6" data-testid="profiles-drawer-battery-slider">
              <Box className="mb-16">
                <Typography sx={{ fontWeight: "bold" }}>
                  Battery Settings
                </Typography>
                {isLightTheme ? (
                  <BatterySensorDark style={svgIconSettings} />
                ) : (
                  <BatterySensorLight style={svgIconSettings} />
                )}
              </Box>
              <SensorSlider
                values={battery}
                min={MinValuesBySensorType.battery}
                max={MaxValuesBySensorType.battery}
                marks={batteryMarks}
                disabled={isLoading}
                step={batteryStep}
                reversed
                onChange={setBattery}
              />
            </Box>
          </Box>

          <DrawerActions
            cancelBtnTestId="btn-cancel-battery-profile"
            deleteBtnTestId="btn-delete-battery-profile"
            showDeleteBtn={isEdit}
            disabled={isLoadingOrUpdating}
            onCancel={handleClose}
            onDelete={toggleDeleteProfilePopup}
            deleteBtnText="Remove"
          />

          {isEdit && deleteProfilePopup && (
            <DeleteProfileDialog
              isOpen={deleteProfilePopup}
              isLoading={deletionLoading}
              onClick={handleDeleteProfile}
              onClose={toggleDeleteProfilePopup}
              testId="battery"
            />
          )}
        </Box>
      </DrawerContent>

      <DrawerFooter
        text={isLoading || isUpdateLoading ? "Saving..." : "Save"}
        disabled={isInvalid || isEditAndPristine}
        testId="battery-profiles-drawer-submit-btn"
        submit={handleSubmitClick}
      />
    </Drawer>
  );
};

export default memo(ProfilesDrawer);

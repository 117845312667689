import { memo, FC, useState, useEffect, useMemo, useCallback } from "react";
import { Box, ThemeProvider } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useAppContext } from "../../../../../context/AppContext";
import {
  TableViewType,
  useFindAssetsByGeofenceOsQuery,
  TableDomain,
  FileFormat,
  GetTableDataInput,
  FindAssetsByGeofenceOsInput,
  AssetsResult,
  AssetOs,
  SortOrder,
  AssetTableData,
} from "../../../../../graphql/operations";
import Spinner from "../../../../../shared/components/Spinner";
import {
  ServerSideExport,
  ServerSideExportFormat,
} from "../../../../../shared/components/Table";
import { BackEndProcessingTable } from "../../../../../shared/components/Table/BackEndProcessingTable";
import { mapOpenSearchData } from "../../../../../shared/hooks/openSearchMongoPolyfillHooks/useAssetsTableData";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import { useProductsList } from "../../../../../shared/hooks/useProductsList";
import { useTableDataExporter } from "../../../../../shared/hooks/useTableDataExporter/useTableDataExporter";
import { columnVisibilityModel } from "../../../TableView/components/AssetsTable/columns";
import { useSettingsTheme } from "../../hooks/useSettingsTheme";
import { getColumns } from "./columns";

export type GeofenceAssetListProps = {
  geofenceId: string;
};

export const GeofenceAssetsList: FC<GeofenceAssetListProps> = memo(
  ({ geofenceId }: GeofenceAssetListProps) => {
    const {
      state: { appConfig },
    } = useAppContext();

    const theme = useSettingsTheme();
    const gridApiRef = useGridApiRef();

    const [fileFormat, setFileFormat] = useState<FileFormat>(FileFormat.Excel);
    const [isExporting, setIsExporting] = useState(false);
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const timezone = usePreferredTimezone();
    const { products } = useProductsList();
    const columns = useMemo(() => getColumns(timezone), [timezone]);

    const [queryInput, setQueryInput] = useState<FindAssetsByGeofenceOsInput>({
      geofenceId,
      sorting: [
        {
          field: "asset_id",
          order: SortOrder.Asc,
        },
      ],
      pagination: {
        skip: 0,
        limit: appConfig.table.defaultRowsPerPage,
      },
    });

    useEffect(() => {
      if (geofenceId && geofenceId !== queryInput.geofenceId) {
        setQueryInput((prev) => ({
          ...prev,
          geofenceId,
        }));
      }
    }, [geofenceId, queryInput.geofenceId]);

    const {
      data: queryData,
      isSuccess,
      isLoading,
    } = useFindAssetsByGeofenceOsQuery<AssetsResult>(
      { input: queryInput },
      {
        refetchOnMount: true,
        staleTime: 5000,
        select: (data) => data.findAssetsByGeofenceOS as AssetsResult,
      }
    );

    const updateQueryInput = useCallback(
      (data: Partial<GetTableDataInput>) => {
        setQueryInput((prev) => ({ ...prev, ...data }));
      },
      [setQueryInput]
    );

    const transformAssetOsToTableGridData = (
      data: AssetOs[]
    ): AssetTableData[] => {
      return mapOpenSearchData({
        data,
        products,
      });
    };

    useTableDataExporter<AssetTableData>({
      apiRef: gridApiRef,
      columns,
      queryInput,
      domain: TableDomain.AssetsInGeofence,
      fileFormat,
      isExporting,
      setExporting: setIsExporting,
      totalCount: queryData?.total ?? undefined,
      baseFilters: { geofenceId },
    });

    const handleExport = useCallback((format: ServerSideExportFormat) => {
      if (format === ServerSideExport.EMAIL) {
        setIsSendingEmail(true);
      } else {
        setFileFormat(format);
        setIsExporting(true);
      }
    }, []);

    return (
      <ThemeProvider theme={theme}>
        <Box className="bg-background h-full overflow-auto">
          <Box className="flex h-full w-full pt-2 px-4 pb-6 md:px-6 lg:px-16">
            <BackEndProcessingTable
              apiRef={gridApiRef}
              baseFilters={{ geofenceId }}
              domain={TableDomain.AssetsInGeofence}
              isSendingEmail={isSendingEmail}
              setSendingEmail={setIsSendingEmail}
              tableType={TableViewType.GeofenceAssets}
              tableName={"geofence-assets"}
              columnVisibilityModel={columnVisibilityModel}
              queryInput={queryInput}
              getRowId={(row) => row.id}
              columns={columns}
              data={{
                rows: queryData?.assets
                  ? transformAssetOsToTableGridData(
                      queryData.assets as AssetOs[]
                    )
                  : [],
                pagination: {
                  skip: queryInput.pagination?.skip ?? 0,
                  limit:
                    queryInput.pagination?.limit ??
                    appConfig.table.defaultRowsPerPage,
                  total: queryData?.total ?? 0,
                },
              }}
              updateQueryInput={updateQueryInput}
              onExport={handleExport}
              sorting={queryInput.sorting ?? undefined}
              isLoading={isLoading}
              isSuccess={isSuccess}
              totalCount={queryData?.total ?? undefined}
            />
            <Spinner counter={Number(isSendingEmail)} />
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
);

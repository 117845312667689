import { uniqBy } from "lodash";
import * as yup from "yup";
import {
  ASSET_DOOR_TYPES,
  ASSET__WHEELS_CONFIGS,
  IAssetDoorTypes,
} from "../../../../../constants/assets";
import {
  transformers,
  convertToIsoStringIgnoringTimezoneOffset,
} from "../../../../../utils";

const doorTypeRegEx = new RegExp(
  `(${Object.keys(ASSET_DOOR_TYPES).join("|")})`
);

const getDoorTypeRegEx = (
  doorTypes: IAssetDoorTypes = ASSET_DOOR_TYPES
): RegExp => {
  const doorValues = Object.values(doorTypes)
    .map((v) => v.split(" ")[0]) // Get the door key value
    .filter(Boolean);

  if (doorValues.length === 0) {
    throw new Error("No valid door types found");
  }

  const regexPattern = `(${doorValues.join("|")})`;
  return new RegExp(regexPattern, "i");
};

export const schema = yup.object().shape({
  asset_id: yup
    .string()
    .required("Asset ID is required")
    .transform(transformers.string),
  name: yup.string().transform(transformers.string),
  customer_orgs_id: yup
    .string()
    .required("Company Name is required")
    .transform(transformers.string),
  imei: yup
    .string()
    .nullable()
    .transform(transformers.string)
    // Allow quotes and numbers for IMEI
    .matches(/^['|"]?[0-9]*['|"]?$/, { message: "Device IMEI is not valid" })
    .transform((value) => (value ? value.replace(/['|"]/g, "") : undefined)) // Ignore quotes
    .min(14, "Device IMEI should be min 14 and max 16 numbers length")
    .max(16, "Device IMEI should be min 14 and max 16 numbers length")
    .optional(),
  prd_cde: yup.string().nullable().optional(),
  tags: yup
    .string()
    .nullable()
    .transform((value) => {
      const assetTags = value?.split(",").map((val: string) => {
        return val.replace(/\s+/g, " ").trim();
      });
      return uniqBy(assetTags, (tag: string) => tag.toLowerCase()).join(",");
    })
    .default("")
    .optional(),
  vin: yup.string().nullable().transform(transformers.string).optional(),
  wheel_config: yup
    .number()
    .nullable()
    .oneOf(ASSET__WHEELS_CONFIGS, "# of Tires is not valid")
    .transform(transformers.number)
    .optional(),
  num_of_axles: yup
    .number()
    .typeError("# of Axles should be a number")
    .nullable()
    .min(0, "# of Axles must be greater than or equal to 0")
    .max(Number.MAX_SAFE_INTEGER)
    .optional(),
  length: yup
    .number()
    .integer("Length must be an integer")
    .nullable(true)
    .transform((_, val) => (val ? Number(val) : null))
    .optional()
    .typeError("Length should be a number")
    .min(0, "Length must be greater than or equal to 0")
    .max(Number.MAX_SAFE_INTEGER),
  category: yup.string().nullable().required("Asset Type is required"),
  door_type: yup
    .string()
    .nullable()
    .matches(doorTypeRegEx, {
      message: `Door Type is not valid. It should be one of ( ${Object.keys(
        ASSET_DOOR_TYPES
      ).join(", ")} )`,
    })
    .optional(),
  subAssetType: yup.string().nullable().transform(transformers.string),
  externalHeight: yup
    .number()
    .integer("External Height must be an integer")
    .nullable(true)
    .transform((_, val) => (val ? Number(val) : null))
    .optional()
    .typeError("External Height should be a number")
    .min(0, "External Height must be greater than or equal to 0")
    .max(Number.MAX_SAFE_INTEGER),

  internalHeight: yup
    .number()
    .integer("Internal Height must be an integer")
    .nullable(true)
    .transform((_, val) => (val ? Number(val) : null))
    .optional()
    .typeError("Internal Height should be a number")
    .min(0, "Internal Height must be greater than or equal to 0")
    .max(Number.MAX_SAFE_INTEGER),

  internalLength: yup
    .number()
    .integer("Internal Length must be an integer")
    .nullable(true)
    .transform((_, val) => (val ? Number(val) : null))
    .optional()
    .typeError("Internal Length should be a number")
    .min(0, "Internal Length must be greater than or equal to 0")
    .max(Number.MAX_SAFE_INTEGER),
  internalWidth: yup
    .number()
    .integer("Internal Width must be an integer")
    .nullable(true)
    .transform((_, val) => (val ? Number(val) : null))
    .optional()
    .typeError("Internal Width should be a number")
    .min(0, "Internal Width must be greater than or equal to 0")
    .max(Number.MAX_SAFE_INTEGER),

  externalLength: yup
    .number()
    .integer("External Length must be an integer")
    .nullable(true)
    .transform((_, val) => (val ? Number(val) : null))
    .optional()
    .typeError("External Length should be a number")
    .min(0, "External Length must be greater than or equal to 0")
    .max(Number.MAX_SAFE_INTEGER),
  manufacturer: yup
    .string()
    .nullable()
    .transform(transformers.string)
    .optional(),
  assetModel: yup.string().nullable().transform(transformers.string).optional(),

  emptyWeight: yup
    .number()
    .integer("Empty weight must be an integer")
    .nullable(true)
    .transform((_, val) => (val ? Number(val) : null))
    .optional()
    .typeError("Empty weight should be a number")
    .min(0, "Empty weight must be greater than or equal to 0")
    .max(Number.MAX_SAFE_INTEGER),

  odometer: yup
    .number()
    .integer("Odometer value must be an integer")
    .nullable(true)
    .transform((_, val) => (val ? Number(val) : null))
    .optional()
    .typeError("Odometer value should be a number")
    .min(0, "Odometer value must be greater than or equal to 0")
    .max(Number.MAX_SAFE_INTEGER),
  engineHours: yup
    .number()
    .integer("Engine Hours value must be an integer")
    .nullable(true)
    .transform((_, val) => (val ? Number(val) : null))
    .optional()
    .typeError("Engine Hours value should be a number")
    .min(0, "Engine Hours value must be greater than or equal to 0")
    .max(Number.MAX_SAFE_INTEGER),
  // metadata fields
  metadata: yup.object().shape({
    year: yup
      .number()
      .integer("Year must be an integer")
      .nullable(true)
      .optional()
      // this transform is used to prevent error appear when type something in the given field and then delete it
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      ),
    assetInserviceDate: yup
      .mixed()
      .nullable()
      .test("is-valid-date", "The date is invalid", (value) => {
        if (value === null || value === undefined || value === "") {
          return true;
        }
        const parsedDate = new Date(value);
        return !isNaN(parsedDate.getTime());
      })
      .transform((value) => {
        if (value === null || value === undefined || value === "") {
          return null;
        }
        const parsedDate = new Date(value);
        if (!isNaN(parsedDate.getTime())) {
          return convertToIsoStringIgnoringTimezoneOffset(
            parsedDate,
            "yyyy-MM-dd"
          );
        }
        return value;
      }),
    cubeCapacity: yup
      .number()
      .integer("Cube capacity must be an integer")
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null))
      .optional()
      .typeError("Cube capacity should be a number")
      .min(0, "Cube capacity must be greater than or equal to 0")
      .max(Number.MAX_SAFE_INTEGER),
    railCompliant: yup
      .boolean()
      .nullable()
      .transform(transformers.boolean)
      .optional(),
    airRide: yup
      .boolean()
      .nullable()
      .transform(transformers.boolean)
      .optional(),

    // liftgate
    liftgate: yup
      .boolean()
      .nullable()
      .transform(transformers.boolean)
      .optional(),
    liftgateMACAddress: yup
      .string()
      .nullable()
      .transform(transformers.string)
      .optional(),
    liftgateManufacturer: yup
      .string()
      .nullable()
      .transform(transformers.string)
      .optional(),
    liftgateModel: yup
      .string()
      .nullable()
      .transform(transformers.string)
      .optional(),
    liftgateSerial: yup
      .string()
      .nullable()
      .transform(transformers.string)
      .optional(),
    liftgateYear: yup
      .number()
      .integer("Liftgate year must be an integer")
      .nullable(true)
      .optional()
      // this transform is used to prevent error appear when type something in the given field and then delete it
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      ),
    liftgateFWVersion: yup
      .string()
      .nullable()
      .transform(transformers.string)
      .optional(),

    // TCU
    CARBNumber: yup.string().transform(transformers.string),
    reeferModel: yup.string().transform(transformers.string),
    reeferSerialNumber: yup.string().transform(transformers.string),
    reeferManufacturer: yup.string().transform(transformers.string),
    reeferCARBCompliant: yup.boolean(),
    reeferYear: yup
      .number()
      .integer("Reefer year must be an integer")
      .nullable(true)
      .optional()
      // this transform is used to prevent error appear when type something in the given field and then delete it
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      ),

    // Rail Compliance
    flushMountedNose: yup.mixed().transform(Boolean),
    gridExtensionPlate: yup.mixed().transform(Boolean),
    liftPads: yup.mixed().transform(Boolean),

    //ABS
    absCanbus: yup.mixed().transform(Boolean),
    absConfiguration: yup.string().transform(transformers.string),
    absManufacturer: yup.string().transform(transformers.string),
    absModel: yup.string().transform(transformers.string),
    absYear: yup
      .number()
      .integer("ABS year must be an integer")
      .nullable(true)
      .optional()
      // this transform is used to prevent error appear when type something in the given field and then delete it
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      ),

    //ATIS
    atis: yup.mixed().transform(Boolean),
    atisManufacturer: yup.string().transform(transformers.string),
    atisModel: yup.string().transform(transformers.string),

    //Other
    lightWeight: yup.mixed().transform(Boolean),
    ttPairCapable: yup.mixed().transform(Boolean),
    aeroKitType: yup.string().transform(transformers.string),
    batteryCharger: yup.string().transform(transformers.string),
    doorLatchType: yup.string().transform(transformers.string),
    doorLatchManufacturer: yup.string().transform(transformers.string),
    electricalHarnessManufacturer: yup.string().transform(transformers.string),
    electronicLandingGear: yup.mixed().transform(Boolean),
    etrack: yup.mixed().transform(Boolean),
    gladhandManufacturer: yup.string().transform(transformers.string),
    gladhandType: yup.string().transform(transformers.string),
    howIsNoseboxMounted: yup.string().transform(transformers.string),
    innerLinerAvailable: yup.mixed().transform(Boolean),
    nosePostDepth: yup
      .number()
      .integer("Nose Post Depth must be an integer")
      .nullable(true)
      .optional(),
    interiorDropDownCargoSystem: yup.mixed().transform(Boolean),
    kingpinOffset: yup
      .number()
      .integer("Kingpin Offset must be an integer")
      .nullable(true)
      .optional()
      // this transform is used to prevent error appear when type something in the given field and then delete it
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      ),
    landingGearManufacturer: yup.string().transform(transformers.string),
    landingGearModel: yup.string().transform(transformers.string),
    landingGearModelRating: yup.string().transform(transformers.string),
    lightingManufacture: yup.string().transform(transformers.string),
    preferredMidturnLightPartNumber: yup
      .string()
      .transform(transformers.string),
    preferredTailLightPartNumber: yup.string().transform(transformers.string),
    logisticsPosts: yup.mixed().transform(Boolean),
    noseTypeBody: yup.string().transform(transformers.string),
    noseboxManufacturer: yup.string().transform(transformers.string),
    noseboxModel: yup.string().transform(transformers.string),
    permalogic: yup.mixed().transform(Boolean),
    rearAero: yup.mixed().transform(Boolean),
    rearAeroManufacturer: yup.string().transform(transformers.string),
    roadtrainAllowed: yup.mixed().transform(Boolean),
    skirt: yup.mixed().transform(Boolean),
    skirtManufacturer: yup.string().transform(transformers.string),
    suspensionManufacturer: yup.string().transform(transformers.string),
    suspensionModel: yup.string().transform(transformers.string),
    suspensionType: yup.string().transform(transformers.string),
    suspensionYear: yup
      .number()
      .integer("Suspension Year must be an integer")
      .nullable(true)
      .optional()
      // this transform is used to prevent error appear when type something in the given field and then delete it
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      ),
    tireSize: yup.string().transform(transformers.string),
    tireRimType: yup.string().transform(transformers.string),
  }),
});

export const getSchemaV2 = ({ doorTypes }: { doorTypes: IAssetDoorTypes }) => {
  return yup.object().shape({
    asset_id: yup
      .string()
      .required("Asset ID is required")
      .transform(transformers.string),
    name: yup.string().nullable().transform(transformers.string),
    org_name: yup
      .string()
      .required("Organization Name is required")
      .transform(transformers.string),
    imei: yup
      .string()
      .nullable()
      .transform(transformers.string)
      // Allow quotes and numbers for IMEI
      .matches(/^['|"]?[0-9]*['|"]?$/, { message: "Device IMEI is not valid" })
      .transform((value) => (value ? value.replace(/['|"]/g, "") : undefined)) // Ignore quotes
      .min(14, "Device IMEI should be min 14 and max 16 numbers length")
      .max(16, "Device IMEI should be min 14 and max 16 numbers length")
      .optional(),
    tags: yup
      .string()
      .nullable()
      .transform((value) => {
        const assetTags = value?.split(",").map((val: string) => {
          return val.replace(/\s+/g, " ").trim();
        });
        return uniqBy(assetTags, (tag: string) => tag.toLowerCase()).join(",");
      })
      .default("")
      .optional(),
    vin: yup.string().nullable().transform(transformers.string).optional(),
    wheel_config: yup
      .number()
      .nullable()
      .oneOf(ASSET__WHEELS_CONFIGS, "# of Tires is not valid")
      .transform(transformers.number)
      .optional(),
    num_of_axles: yup
      .number()
      .typeError("# of Axles should be a number")
      .nullable()
      .min(0, "# of Axles must be greater than or equal to 0")
      .max(Number.MAX_SAFE_INTEGER)
      .optional(),
    length: yup
      .number()
      .integer("Length must be an integer")
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null))
      .optional()
      .typeError("Length should be a number")
      .min(0, "Length must be greater than or equal to 0")
      .max(Number.MAX_SAFE_INTEGER),
    category: yup.string().nullable().required("Asset Type is required"),
    door_type: yup
      .string()
      .nullable()
      .matches(getDoorTypeRegEx(doorTypes), {
        message: `Door Type is not valid. It should be one of ( ${Object.keys(
          doorTypes
        ).join(", ")} )`,
      })
      .optional(),
    sub_asset_type: yup.string().nullable().transform(transformers.string),
    height: yup
      .number()
      .integer("Height must be an integer")
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null))
      .optional()
      .typeError("Height should be a number")
      .min(0, "Height must be greater than or equal to 0")
      .max(Number.MAX_SAFE_INTEGER),
  });
};

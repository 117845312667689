import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { GeofenceData } from "../../../../../graphql/operations";
import { schema } from "./geofenceFormUtils";

export const useGeofenceForm = (initialValues: Partial<GeofenceData> = {}) => {
  const initialData = {
    configuration: {
      typeId: initialValues?.configuration?.typeId ?? null,
      typeName: initialValues?.configuration?.typeName ?? null,
      subTypeId: initialValues?.configuration?.subTypeId ?? null,
      parentId: initialValues?.configuration?.parentId ?? null,
    },
    geofence: {
      code: initialValues?.geofence?.code ?? null,
      description: initialValues?.geofence?.description ?? null,
      fullAddressFormatted:
        initialValues?.geofence?.fullAddressFormatted ?? null,
      name: initialValues?.geofence?.name ?? null,
      tags: initialValues?.geofence?.tags ?? null,
      geofenceArea: initialValues?.geofence?.geofenceArea ?? null,
    },
    metadata: {
      owner: initialValues?.metadata?.owner ?? null,
    },
    orgId: initialValues?.orgId ?? null,
  } as Partial<GeofenceData>;
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialData,
  });

  const getValues = useCallback(
    () => schema.cast(form.getValues(), { assert: false }),
    [form]
  );
  return { form, getValues, schema };
};

import { FC, memo, useMemo, useState } from "react";
import {
  Box,
  Grid,
  Input,
  Typography,
  FormControl,
  ThemeProvider,
  FormHelperText,
} from "@mui/material";
import { ReactComponent as TemperatureSensorDark } from "../../../../../assets/svgs/temperatureSensorDark.svg";
import { ReactComponent as TemperatureSensorLight } from "../../../../../assets/svgs/temperatureSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  SensorProfileResult,
  SensorProfileConfigType,
  UpdateOrganizationSensorProfileInput,
  SensorConfigInputTempInternal,
} from "../../../../../graphql/operations";
import Drawer from "../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../shared/components/Drawer/DrawerHeader";
import { Label } from "../../../../../shared/components/FormControlElement/styledElements";
import SensorSlider from "../../../../../shared/components/SensorSlider/SensorSlider";
import {
  sliderMarks,
  extractDoubleRules,
  returnEvenValuesFromRange,
  returnDynamicLimit,
  internalTemperatureDefaultValues,
  prepareDoubleRulesPayload,
} from "../../../../../shared/components/SensorSlider/sensorSliderUtils";
import Text from "../../../../../shared/components/Text";
import WithAsterisk from "../../../../../shared/components/WithAsterisk";
import {
  MinValuesBySensorType,
  MaxValuesBySensorType,
} from "../../../../../shared/helpers/battery";
import {
  GET_HEALTHY_POINT_MIN,
  GET_HEALTHY_POINT_MAX,
} from "../../../../../shared/helpers/temperature";
import { useFormTheme } from "../../../../../shared/hooks/theme/useFormTheme";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import { useGetTemperatureUnitPreference } from "../../../../../shared/hooks/useGetTemperatureUnitPreference";
import {
  getConvertedTemperatureValues,
  getTemperatureUnitLabel,
  prepareConvertedCelsiusTemperaturesPayload,
} from "../../../../../utils/convertTemperature";
import { useSensorProfilesApi } from "../../../hooks/useSensorProfilesApi";
import { DrawerType, TemperatureDrawers } from "../profileUtils";
import DeleteProfileDialog from "./shared/components/DeleteProfileDialog";
import { ProfilesDrawerMainForm } from "./shared/components/ProfilesDrawerMainForm/ProfilesDrawerMainForm";
import { useProfileDrawerMainForm } from "./shared/hooks/useProfileDrawerMainForm";

const sliderTitleStyle = {
  color: "var(--battery-settings)",
  fontWeight: 800,
  marginBottom: "4rem",
};

export type TemperatureDrawerProps = {
  sensorType: SensorProfileConfigType;
  sensorProfileData: SensorProfileResult | undefined;
  type: DrawerType;
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  onProfileMutation: () => void;
};

const TemperatureDrawer: FC<TemperatureDrawerProps> = ({
  type,
  sensorProfileData,
  onClose,
  isOpen,
  onProfileMutation,
}) => {
  const {
    dispatch,
    state: { theme },
  } = useAppContext();

  const tempUnitPreference = useGetTemperatureUnitPreference();
  const HEALTHY_POINT_MIN = GET_HEALTHY_POINT_MIN(tempUnitPreference);
  const HEALTHY_POINT_MAX = GET_HEALTHY_POINT_MAX(tempUnitPreference);

  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    margin: "1rem 0rem",
  };
  const formTheme = useFormTheme();
  const orgs = useAvailableOrgs();
  const isEdit = type === TemperatureDrawers.Edit;

  const currentOrgName = useMemo(() => {
    // Find & show the current organization of the sensor in the dropdown
    if (sensorProfileData?.orgId && isEdit) {
      const foundOrg = orgs.find(
        (org) => org._id.toString() === sensorProfileData.orgId
      );

      return foundOrg?._id;
    }
  }, [isEdit, orgs, sensorProfileData?.orgId]);

  const { form: mainForm } = useProfileDrawerMainForm({
    name: sensorProfileData?.name ?? "",
    orgName: currentOrgName,
    default: sensorProfileData?.default ?? false,
  });

  // Sensor values extraction
  const [minAirTankConverted, maxAirBagConverted] =
    getConvertedTemperatureValues(
      [MinValuesBySensorType.internal, MaxValuesBySensorType.internal],
      tempUnitPreference
    );

  const temperatureThresholds =
    sensorProfileData?.configuration?.temperature?.internal;
  const parsedTemperature = extractDoubleRules(
    temperatureThresholds,
    "internal" as SensorProfileConfigType
  );

  const initialTemperature = getConvertedTemperatureValues(
    parsedTemperature || internalTemperatureDefaultValues,
    tempUnitPreference
  );

  const initialUnderTemp = initialTemperature.slice(0, 3);
  const initialHealthyPoint = initialTemperature.slice(3, 4)[0];
  const initialOverTemp = initialTemperature.slice(4, 7);

  // State
  const [overTemperature, setOverTemperature] = useState(initialOverTemp);
  const [underTemperature, setUnderTemperature] = useState(initialUnderTemp);
  const [healthyPoint, setHealthyPoint] = useState(initialHealthyPoint);
  const [healthyPointError, setHealthyPointError] = useState<string>("");
  const [deleteTemperaturePopup, setDeleteTemperaturePopup] = useState(false);

  // Event Handlers
  const handleTemperatureProfileCreateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Temperature Profile Created Successfully!",
        severity: "success",
      },
    });
  };

  const handleTemperatureProfileCreateError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Temperature Profile Creation Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleTemperatureProfileUpdateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Temperature Profile Updated Successfully!",
        severity: "success",
      },
    });
  };

  const handleTemperatureProfileUpdateError = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Temperature Profile Update Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleTemperatureProfileDeleteSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Temperature Profile Deleted Successfully!",
        severity: "success",
      },
    });
  };

  const handleTemperatureProfileDeleteError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Temperature Profile Deletion Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const {
    updateSensorProfile,
    isLoadingUpdateSensorProfile,
    deleteSensorProfile,
    isSuccessDeleteSensorProfile,
    isLoadingDeleteSensorProfile,
    createSensorProfile,
    isLoadingCreateSensorProfile,
  } = useSensorProfilesApi({
    createSensorProfileOnSuccess: handleTemperatureProfileCreateSuccess,
    createSensorProfileOnError: handleTemperatureProfileCreateError,
    updateSensorProfileOnSuccess: handleTemperatureProfileUpdateSuccess,
    updateSensorProfileOnError: handleTemperatureProfileUpdateError,
    deleteSensorProfileOnSuccess: handleTemperatureProfileDeleteSuccess,
    deleteSensorProfileOnError: handleTemperatureProfileDeleteError,
  });

  const handleDeleteTemperature = () => {
    if (isLoadingDeleteSensorProfile || isSuccessDeleteSensorProfile) {
      return;
    }

    deleteSensorProfile({ _id: sensorProfileData?._id ?? "" });
  };

  const toggleDeleteTemperaturePopup = () => {
    setDeleteTemperaturePopup(!deleteTemperaturePopup);
  };

  const handleClose = () => onClose(false);

  const onHealthyPointChange = (e: { target: { value: string } }) => {
    const num = Number(e.target.value);
    if (isNaN(num)) return;

    const temperatureErorMsg = `Number must be between ${HEALTHY_POINT_MIN} and ${HEALTHY_POINT_MAX}.`;
    let evenUnderTemperature = returnEvenValuesFromRange(
      minAirTankConverted,
      num
    );
    let evenOverTemperature = returnEvenValuesFromRange(
      num,
      maxAirBagConverted
    );
    let errorMsg = "";

    if (num < HEALTHY_POINT_MIN) {
      evenUnderTemperature = returnEvenValuesFromRange(
        minAirTankConverted,
        HEALTHY_POINT_MIN
      );
      evenOverTemperature = returnEvenValuesFromRange(
        HEALTHY_POINT_MIN,
        maxAirBagConverted
      );
      errorMsg = temperatureErorMsg;
    } else if (num > HEALTHY_POINT_MAX) {
      evenUnderTemperature = returnEvenValuesFromRange(
        minAirTankConverted,
        HEALTHY_POINT_MAX
      );
      evenOverTemperature = returnEvenValuesFromRange(
        HEALTHY_POINT_MAX,
        maxAirBagConverted
      );
      errorMsg = temperatureErorMsg;
    }

    setUnderTemperature(evenUnderTemperature);
    setOverTemperature(evenOverTemperature);
    setHealthyPoint(num);
    setHealthyPointError(errorMsg);
  };

  const handleSubmitClick = () => {
    const [healthyPointPayload] = prepareConvertedCelsiusTemperaturesPayload(
      [healthyPoint],
      tempUnitPreference
    );

    const configuration = {
      temperature: {
        internal: {
          match: prepareDoubleRulesPayload(
            undefined,
            prepareConvertedCelsiusTemperaturesPayload(
              underTemperature,
              tempUnitPreference
            ),
            prepareConvertedCelsiusTemperaturesPayload(
              overTemperature,
              tempUnitPreference
            ),
            healthyPointPayload,
            MinValuesBySensorType.internal,
            MaxValuesBySensorType.internal,
            "tempInternal"
          ) as SensorConfigInputTempInternal,
        },
      },
    };

    const { orgName, name, default: isDefault } = mainForm.getValues();

    if (!isEdit) {
      return createSensorProfile({
        orgId: orgName,
        default: isDefault ?? false,
        name,
        configuration,
      });
    }

    const updateSensorProfilePayload: Record<string, any> = {
      _id: sensorProfileData?._id,
      type: sensorProfileData?.type,
      orgId: orgName,
      default: isDefault ?? false,
      name,
      configuration,
    };
    updateSensorProfile(
      updateSensorProfilePayload as UpdateOrganizationSensorProfileInput
    );
  };

  const dynamicLimit = returnDynamicLimit(
    healthyPoint,
    HEALTHY_POINT_MIN,
    HEALTHY_POINT_MAX
  );

  const headerText = `${isEdit ? "Edit" : "Create"} Profile`;

  const isLoading =
    isLoadingDeleteSensorProfile ||
    isLoadingCreateSensorProfile ||
    isLoadingUpdateSensorProfile;
  const isInvalid =
    isLoading || !mainForm.formState.isValid || !!healthyPointError;
  const isEditAndPristine =
    isEdit &&
    !mainForm.formState.isDirty &&
    initialTemperature.toString() ===
      [...underTemperature, healthyPoint, ...overTemperature].toString();

  return (
    <Drawer
      isOpen={isOpen}
      onRequestClose={handleClose}
      testId="temperature-profile-drawer"
    >
      <DrawerHeader text={headerText} onClose={handleClose} />

      <DrawerContent>
        <ThemeProvider theme={formTheme}>
          <Box className="h-full flex flex-col justify-between">
            <Box className="mb-8">
              <ProfilesDrawerMainForm
                form={mainForm}
                disabled={isLoading}
                isEdit={isEdit}
              />
              <Grid
                container
                className="drawerSection"
                spacing={6}
                direction="column"
              >
                <Grid item>
                  <Text
                    fontSize={14}
                    fontWeight="bold"
                    classes="!text-base !text-primary"
                  >
                    Temperature Settings
                  </Text>
                  {isLightTheme ? (
                    <TemperatureSensorDark
                      style={svgIconSettings}
                      data-testid="temperature-profile-drawer-thermostat"
                    />
                  ) : (
                    <TemperatureSensorLight
                      style={svgIconSettings}
                      data-testid="temperature-profile-drawer-thermostat"
                    />
                  )}

                  <Box className="pb-4">
                    <FormControl sx={{ width: "100%" }}>
                      <WithAsterisk>
                        <Label htmlFor="Healthy Value">Healthy Value</Label>
                      </WithAsterisk>

                      <Input
                        value={healthyPoint}
                        onChange={onHealthyPointChange}
                        data-testid="temperature-profiles-healthy-point-input"
                        id="temperatureHealthyPointInput"
                        disabled={isLoading}
                        sx={{ width: "100%" }}
                      />
                      {!!healthyPointError && (
                        <FormHelperText
                          data-testid="temperature-profiles-healthy-point-error-text"
                          error={true}
                        >
                          {healthyPointError}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item>
                  <Typography sx={sliderTitleStyle}>
                    Hot Side Settings
                  </Typography>
                  <SensorSlider
                    values={overTemperature}
                    min={dynamicLimit}
                    max={maxAirBagConverted}
                    marks={sliderMarks(
                      dynamicLimit,
                      maxAirBagConverted,
                      getTemperatureUnitLabel(tempUnitPreference, true)
                    )}
                    disabled={isLoading}
                    onChange={setOverTemperature}
                  />
                </Grid>
                <Grid item>
                  <Typography sx={sliderTitleStyle}>
                    Cold Side Settings
                  </Typography>
                  <SensorSlider
                    values={underTemperature}
                    min={minAirTankConverted}
                    max={dynamicLimit}
                    marks={sliderMarks(
                      minAirTankConverted,
                      dynamicLimit,
                      getTemperatureUnitLabel(tempUnitPreference, true)
                    )}
                    disabled={isLoading}
                    reversed
                    onChange={setUnderTemperature}
                  />
                </Grid>
              </Grid>
            </Box>

            <DrawerActions
              cancelBtnTestId="temperature-profile-drawer-cancel"
              deleteBtnTestId="temperature-profile-drawer-delete"
              showDeleteBtn={isEdit}
              disabled={isLoading}
              onCancel={handleClose}
              onDelete={toggleDeleteTemperaturePopup}
              deleteBtnText="Remove"
            />

            {isEdit && deleteTemperaturePopup && (
              <DeleteProfileDialog
                isOpen={deleteTemperaturePopup}
                onClose={toggleDeleteTemperaturePopup}
                isLoading={isLoadingDeleteSensorProfile}
                onClick={handleDeleteTemperature}
                testId="temperature"
              />
            )}
          </Box>
        </ThemeProvider>
      </DrawerContent>

      <DrawerFooter
        text={
          isLoadingCreateSensorProfile || isLoadingUpdateSensorProfile
            ? "Saving..."
            : "Save"
        }
        disabled={isInvalid || isEditAndPristine}
        testId="temperature-profile-drawer-submit"
        submit={handleSubmitClick}
      />
    </Drawer>
  );
};

export default memo(TemperatureDrawer);

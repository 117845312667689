import {
  CheckCircleOutlineRounded,
  ErrorOutlineOutlined,
  NotificationImportantOutlined,
  ReportProblemOutlined,
  SettingsInputComponentOutlined,
} from "@mui/icons-material";
import DoorSlidingIcon from "@mui/icons-material/DoorSliding";
import SensorsIcon from "@mui/icons-material/Sensors";
import TungstenIcon from "@mui/icons-material/Tungsten";
import { Libraries } from "@react-google-maps/api";
import { GoogleMapsTypeIds } from "../enums/map";
import { AssetTripStatus } from "../graphql/operations";
import { getDwellType } from "../shared/helpers/dwell";
import {
  formatDate,
  getEndOfDay,
  getEndOfMonth,
  getEndOfToday,
  getEndOfWeek,
  getEndOfYesterday,
  getStartOfDay,
  getStartOfMonth,
  getStartOfToday,
  getStartOfWeek,
  getStartOfYesterday,
  getSubDays,
  getSubHours,
  getSubMonths,
  subWeeksFromDate,
} from "../utils/date";
import {
  CargoUltrasonicState,
  DwellSensorState,
} from "../views/AssetsView/MapView/Assets/AssetShortTooltip/components/AssetShortTooltipSensors/AssetShortTooltipSensors.interface";
import cargoEvent from "../views/AssetsView/MapView/Assets/Breadcrumbs/Map/markers/Cargo.svg";
import doorEvent from "../views/AssetsView/MapView/Assets/Breadcrumbs/Map/markers/Door.svg";
import healthEvent from "../views/AssetsView/MapView/Assets/Breadcrumbs/Map/markers/Health.svg";
import locationUpdateEvent from "../views/AssetsView/MapView/Assets/Breadcrumbs/Map/markers/Location.svg";
import locationEntryEvent from "../views/AssetsView/MapView/Assets/Breadcrumbs/Map/markers/LocationEntry.svg";
import emptyNotSignalingMoving from "../views/AssetsView/MapView/Shared/animatedMarkerIcons/empty-not-signaling-moving.svg";
import emptySignalingMoving from "../views/AssetsView/MapView/Shared/animatedMarkerIcons/empty-signaling-moving.svg";
import loadedNotSignalingMoving from "../views/AssetsView/MapView/Shared/animatedMarkerIcons/loaded-not-signaling-moving.svg";
import loadedSignalingMoving from "../views/AssetsView/MapView/Shared/animatedMarkerIcons/loaded-signaling-moving.svg";
import unknownNotSignalingMoving from "../views/AssetsView/MapView/Shared/animatedMarkerIcons/unknown-not-signaling-moving.svg";
import unknownSignalingMoving from "../views/AssetsView/MapView/Shared/animatedMarkerIcons/unknown-signaling-moving.svg";
import type {
  AssetFilters,
  Feature,
  FilterPayload,
  FilterPayloadDateRange,
  IGeofenceFilters,
} from "../views/AssetsView/shared/AssetsDataContext/AssetsDataContext";

// All available field operators for filtration
export enum OperatorValue {
  ///////////////////////////////////////////////////////////////////////
  // String Field Operands
  ///////////////////////////////////////////////////////////////////////
  STRING_CONTAINS = "contains",
  STRING_EQUALS = "equals",
  STRING_STARTS_WITH = "startsWith",
  STRING_ENDS_WITH = "endsWith",
  STRING_NOT_EQUAL = "isNotEqual",
  STRING_NOT_CONTAIN = "doesNotContain",

  ///////////////////////////////////////////////////////////////////////
  // Number Field Operands
  ///////////////////////////////////////////////////////////////////////
  NUMBER_EQUALS = "=",
  NUMBER_NOT_EQUALS = "!=",
  NUMBER_GREATER_THAN = ">",
  NUMBER_GREATER_THAN_OR_EQUALS = ">=",
  NUMBER_LESS_THAN = "<",
  NUMBER_LESS_THAN_OR_EQUALS = "<=",
  VALUE_RANGE = "valueRange",
  VALUE_RANGES = "valueRanges",

  ///////////////////////////////////////////////////////////////////////
  // Date Field Operands
  ///////////////////////////////////////////////////////////////////////
  IS_DATE = "is",
  IS_NOT_DATE = "not",
  IS_DATE_BEFORE = "before",
  IS_DATE_ON_OR_BEFORE = "onOrBefore",
  IS_DATE_AFTER = "after",
  IS_DATE_ON_OR_AFTER = "onOrAfter",
  DATE_RANGE = "dateRange",

  ///////////////////////////////////////////////////////////////////////
  // Boolean Field Operands
  ///////////////////////////////////////////////////////////////////////
  IS_TRUE = "isTrue",
  IS_FALSE = "isFalse",

  ///////////////////////////////////////////////////////////////////////
  // All Field Operands
  ///////////////////////////////////////////////////////////////////////
  IS_EMPTY = "isEmpty",
  IS_NOT_EMPTY = "isNotEmpty",
  IS_ANY_OF = "isAnyOf",

  ///////////////////////////////////////////////////////////////////////
  // Array of Objects Field Operands
  ///////////////////////////////////////////////////////////////////////
  ARRAY_OF_OBJECTS_PROPERTY_IS_ANY_OF = "arrayOfObjectsPropertyIsAnyOf",
}

// TODO: Go and check all places where this is used
export enum TRIP_STATUS {
  Parked = "parked",
  Moving = "moving",
  On_trip = "on_trip",
}

export const TRIP_STATUS_LABELS: Record<TRIP_STATUS, string> = {
  [TRIP_STATUS.Moving]: "Moving",
  [TRIP_STATUS.Parked]: "Parked",
  [TRIP_STATUS.On_trip]: "On Trip",
};

export enum STATUS_FILTER_MAPPING {
  Moving = "Moving",
  Parked = "Parked",
  On_trip = "On Trip",

  Low = "Low Dwell",
  Medium = "Medium Dwell",
  High = "High Dwell",
  Mega = "Mega Dwell",
}

export enum DWELL_STATUS_MAPPING {
  Low = "Low",
  Medium = "Medium",
  High = "High",
  Mega = "Mega",
}

export const DWELL_STATUS_DAYS: Record<DWELL_STATUS_MAPPING, number> = {
  [DWELL_STATUS_MAPPING.Low]: 0,
  [DWELL_STATUS_MAPPING.Medium]: 3,
  [DWELL_STATUS_MAPPING.High]: 7,
  [DWELL_STATUS_MAPPING.Mega]: 29,
};

export const DWELL_STATUS_FILTER_VALUE_MAPPING: Record<
  DWELL_STATUS_MAPPING,
  string
> = {
  [DWELL_STATUS_MAPPING.Low]: "low",
  [DWELL_STATUS_MAPPING.Medium]: "medium",
  [DWELL_STATUS_MAPPING.High]: "large",
  [DWELL_STATUS_MAPPING.Mega]: "mega",
};

export type RangeFilterOption = {
  startValue?: number;
  endValue?: number;
};

export const DWELL_STATUS_RANGE_MAPPING: {
  [key in DWELL_STATUS_MAPPING]: RangeFilterOption;
} = {
  [DWELL_STATUS_MAPPING.Low]: { startValue: 0.1, endValue: 3 },
  [DWELL_STATUS_MAPPING.Medium]: { startValue: 3.1, endValue: 7 },
  [DWELL_STATUS_MAPPING.High]: { startValue: 7.1, endValue: 29 },
  [DWELL_STATUS_MAPPING.Mega]: {
    startValue: 29.1,
  },
};

export enum DWELL_STATUS {
  Low = "Low",
  Medium = "Medium",
  High = "High",
  Mega = "Mega",
}

export enum FILTER_TYPE {
  status = "status",
  signal = "signal",
  imei = "imei",
  regions = "state",
}

export enum SMART_SEARCH_MATCH_AGAINST {
  location = "location",
  asset = "asset",
  device = "device",
  user = "user",
  geofence = "geofences",
  organization = "organization",
  page = "page",
}
export enum AssetGeometryType {
  POINT = "Point",
  POLYGON = "Polygon",
}

export const SHOW_MORE = "show more";
export const SHOW_LESS = "show less";

export const OTHER_DETAILS_CONSTANT = [
  {
    id: "door",
    Icon: DoorSlidingIcon,
    title: "DOOR",
    classes: "error",
    color: "#CA1212",
    StatusIcon: ErrorOutlineOutlined,
    statusText: "Open",
  },
  {
    id: "lamp-name",
    Icon: TungstenIcon,
    title: "LAMP NAME",
    classes: "alert",
    color: "#E78D3A",
    StatusIcon: ReportProblemOutlined,
    statusText: "Alert",
  },
  {
    id: "sensor-name",
    Icon: SettingsInputComponentOutlined,
    title: "SENSOR NAME",
    classes: "warning",
    color: "#E5BE33",
    StatusIcon: NotificationImportantOutlined,
    statusText: "Warning",
  },
  {
    id: "sensors",
    Icon: SensorsIcon,
    title: "8 Sensors",
    classes: "healthy",
    color: "#339933",
    StatusIcon: CheckCircleOutlineRounded,
    statusText: "Healthy",
  },
];
const SIGNALING_COLOR = "80, 134, 199";
const NOT_SIGNALING_COLOR = "0, 0, 0";
const NOT_SIGNALING_LOADED = "30, 30, 30";

//-----------------------
// Filter constants
//-----------------------
const LOW_DWELL_FILTER_RANGE = [
  [">=", ["get", "dwellingDays"], 0],
  ["<=", ["get", "dwellingDays"], 3],
];
const MEDIUM_DWELL_FILTER_RANGE = [
  [">=", ["get", "dwellingDays"], 4],
  ["<=", ["get", "dwellingDays"], 7],
];
const LARGE_DWELL_FILTER_RANGE = [
  [">=", ["get", "dwellingDays"], 8],
  ["<=", ["get", "dwellingDays"], 29],
];
const MEGA_DWELL_FILTER_RANGE = [">=", ["get", "dwellingDays"], 30];
const NOT_SIGNALING_FILTER = ["==", ["get", "signal"], false];
const SIGNALING_FILTER = ["==", ["get", "signal"], true];
const MOVING_FILTER = ["==", ["get", "status"], TRIP_STATUS.Moving];
const NOT_MOVING_FILTER = ["!=", ["get", "status"], TRIP_STATUS.Moving];
const LOAD_LOADED_FILTER = ["==", ["get", "load"], CargoUltrasonicState.Loaded];
const LOAD_EMPTY_FILTER = [
  "==",
  ["get", "load"],
  CargoUltrasonicState.Unloaded,
];
const LOAD_UNKNOWN_FILTER = [
  "==",
  ["get", "load"],
  CargoUltrasonicState.Unknown,
];

export enum FILTER_OPERATOR_VALUE {
  IsTrue = "isTrue",
  IsFalse = "isFalse",
  IsNotEmpty = "isNotEmpty",
  IsEmpty = "isEmpty",
}

export const SHIPPING_ICON_STYLE = { width: "14px", height: "14px" };
export const CHECK_ICON_STYLE = { width: "10px", height: "10px" };
export const IMPORTANT_NOTIFICATION_STYLE = { width: "9px", height: "10px" };
export const STATUS_ICON_STYLE = { width: "8px", height: "8px" };
export const LOCATION_ICON_STYLE = { width: "14px", height: "14px" };
export const CENTER_FOCUS_ICON = {
  width: "28px",
  height: "28px",
  color: "var(--primary)",
  backgroundColor: "var(--background)",
  borderRadius: "0.25rem",
};
//------------------------------------
// Mapbox Filter logic Start
//------------------------------------
export const ASSET_MOVING_UNKNOWN_SIGNALING = [
  "all",
  SIGNALING_FILTER,
  LOAD_UNKNOWN_FILTER,
  MOVING_FILTER,
];
export const ASSET_MOVING_UNKNOWN_NOT_SIGNALING = [
  "all",
  NOT_SIGNALING_FILTER,
  LOAD_UNKNOWN_FILTER,
  MOVING_FILTER,
];
export const ASSET_SIGNALING_UNKNOWN_DWELL_MEGA = [
  "all",
  NOT_MOVING_FILTER,
  LOAD_UNKNOWN_FILTER,
  SIGNALING_FILTER,
  MEGA_DWELL_FILTER_RANGE,
];
export const ASSET_NOT_SIGNALING_UNKNOWN_DWELL_MEGA = [
  "all",
  NOT_MOVING_FILTER,
  LOAD_UNKNOWN_FILTER,
  NOT_SIGNALING_FILTER,
  MEGA_DWELL_FILTER_RANGE,
];
export const ASSET_MOVING_LOADED_SIGNALING = [
  "all",
  SIGNALING_FILTER,
  LOAD_LOADED_FILTER,
  MOVING_FILTER,
];
export const ASSET_MOVING_EMPTY_SIGNALING = [
  "all",
  SIGNALING_FILTER,
  LOAD_EMPTY_FILTER,
  MOVING_FILTER,
];
export const ASSET_MOVING_LOADED_NOT_SIGNALING = [
  "all",
  NOT_SIGNALING_FILTER,
  LOAD_LOADED_FILTER,
  MOVING_FILTER,
];
export const ASSET_MOVING_EMPTY_NOT_SIGNALING = [
  "all",
  NOT_SIGNALING_FILTER,
  LOAD_EMPTY_FILTER,
  MOVING_FILTER,
];
export const ASSET_SIGNALING_LOADED_DWELL_MEGA = [
  "all",
  NOT_MOVING_FILTER,
  LOAD_LOADED_FILTER,
  SIGNALING_FILTER,
  MEGA_DWELL_FILTER_RANGE,
];
export const ASSET_SIGNALING_EMPTY_DWELL_MEGA = [
  "all",
  NOT_MOVING_FILTER,
  LOAD_EMPTY_FILTER,
  SIGNALING_FILTER,
  MEGA_DWELL_FILTER_RANGE,
];
export const ASSET_NOT_SIGNALING_LOADED_DWELL_MEGA = [
  "all",
  NOT_MOVING_FILTER,
  LOAD_LOADED_FILTER,
  NOT_SIGNALING_FILTER,
  MEGA_DWELL_FILTER_RANGE,
];
export const ASSET_NOT_SIGNALING_EMPTY_DWELL_MEGA = [
  "all",
  NOT_MOVING_FILTER,
  LOAD_EMPTY_FILTER,
  NOT_SIGNALING_FILTER,
  MEGA_DWELL_FILTER_RANGE,
];
export const ASSET_NOT_SIGNALING_UNKNOWN_DWELL_LARGE =
  ASSET_NOT_SIGNALING_UNKNOWN_DWELL_MEGA.slice(0, 4).concat(
    LARGE_DWELL_FILTER_RANGE
  );
export const ASSET_NOT_SIGNALING_UNKNOWN_DWELL_MEDIUM =
  ASSET_NOT_SIGNALING_UNKNOWN_DWELL_MEGA.slice(0, 4).concat(
    MEDIUM_DWELL_FILTER_RANGE
  );
export const ASSET_NOT_SIGNALING_UNKNOWN_DWELL_LOW =
  ASSET_NOT_SIGNALING_UNKNOWN_DWELL_MEGA.slice(0, 4).concat(
    LOW_DWELL_FILTER_RANGE
  );
export const ASSET_SIGNALING_UNKNOWN_DWELL_LARGE =
  ASSET_SIGNALING_UNKNOWN_DWELL_MEGA.slice(0, 4).concat(
    LARGE_DWELL_FILTER_RANGE
  );
export const ASSET_SIGNALING_UNKNOWN_DWELL_MEDIUM =
  ASSET_SIGNALING_UNKNOWN_DWELL_MEGA.slice(0, 4).concat(
    MEDIUM_DWELL_FILTER_RANGE
  );
export const ASSET_SIGNALING_UNKNOWN_DWELL_LOW =
  ASSET_SIGNALING_UNKNOWN_DWELL_MEGA.slice(0, 4).concat(LOW_DWELL_FILTER_RANGE);

export const ASSET_NOT_SIGNALING_LOADED_DWELL_LARGE =
  ASSET_NOT_SIGNALING_LOADED_DWELL_MEGA.slice(0, 4).concat(
    LARGE_DWELL_FILTER_RANGE
  );
export const ASSET_NOT_SIGNALING_LOADED_DWELL_MEDIUM =
  ASSET_NOT_SIGNALING_LOADED_DWELL_MEGA.slice(0, 4).concat(
    MEDIUM_DWELL_FILTER_RANGE
  );
export const ASSET_NOT_SIGNALING_LOADED_DWELL_LOW =
  ASSET_NOT_SIGNALING_LOADED_DWELL_MEGA.slice(0, 4).concat(
    LOW_DWELL_FILTER_RANGE
  );
export const ASSET_NOT_SIGNALING_EMPTY_DWELL_LARGE =
  ASSET_NOT_SIGNALING_EMPTY_DWELL_MEGA.slice(0, 4).concat(
    LARGE_DWELL_FILTER_RANGE
  );
export const ASSET_NOT_SIGNALING_EMPTY_DWELL_MEDIUM =
  ASSET_NOT_SIGNALING_EMPTY_DWELL_MEGA.slice(0, 4).concat(
    MEDIUM_DWELL_FILTER_RANGE
  );
export const ASSET_NOT_SIGNALING_EMPTY_DWELL_LOW =
  ASSET_NOT_SIGNALING_EMPTY_DWELL_MEGA.slice(0, 4).concat(
    LOW_DWELL_FILTER_RANGE
  );
export const ASSET_SIGNALING_LOADED_DWELL_LARGE =
  ASSET_SIGNALING_LOADED_DWELL_MEGA.slice(0, 4).concat(
    LARGE_DWELL_FILTER_RANGE
  );
export const ASSET_SIGNALING_LOADED_DWELL_MEDIUM =
  ASSET_SIGNALING_LOADED_DWELL_MEGA.slice(0, 4).concat(
    MEDIUM_DWELL_FILTER_RANGE
  );
export const ASSET_SIGNALING_LOADED_DWELL_LOW =
  ASSET_SIGNALING_LOADED_DWELL_MEGA.slice(0, 4).concat(LOW_DWELL_FILTER_RANGE);
export const ASSET_SIGNALING_EMPTY_DWELL_LARGE =
  ASSET_SIGNALING_EMPTY_DWELL_MEGA.slice(0, 4).concat(LARGE_DWELL_FILTER_RANGE);
export const ASSET_SIGNALING_EMPTY_DWELL_MEDIUM =
  ASSET_SIGNALING_EMPTY_DWELL_MEGA.slice(0, 4).concat(
    MEDIUM_DWELL_FILTER_RANGE
  );
export const ASSET_SIGNALING_EMPTY_DWELL_LOW =
  ASSET_SIGNALING_EMPTY_DWELL_MEGA.slice(0, 4).concat(LOW_DWELL_FILTER_RANGE);
//------------------------------------
// Mapbox Filter logic End
//------------------------------------

export const ASSET_MARKER_COLOR_MAPPING = new Map([
  ["loaded-signaling-moving", SIGNALING_COLOR],
  ["empty-signaling-moving", SIGNALING_COLOR],
  ["unknown-signaling-moving", SIGNALING_COLOR],
  ["loaded-not-signaling-moving", NOT_SIGNALING_COLOR],
  ["unknown-not-signaling-moving", NOT_SIGNALING_COLOR],
  ["empty-not-signaling-moving", NOT_SIGNALING_COLOR],
  ["loaded-signaling-dwell-mega", SIGNALING_COLOR],
  ["loaded-signaling-dwell-large", SIGNALING_COLOR],
  ["loaded-signaling-dwell-medium", SIGNALING_COLOR],
  ["loaded-signaling-dwell-low", SIGNALING_COLOR],
  ["empty-signaling-dwell-mega", SIGNALING_COLOR],
  ["unknown-signaling-dwell-mega", SIGNALING_COLOR],
  ["unknown-signaling-dwell-large", SIGNALING_COLOR],
  ["unknown-signaling-dwell-medium", SIGNALING_COLOR],
  ["unknown-signaling-dwell-low", SIGNALING_COLOR],
  ["empty-signaling-dwell-mega", SIGNALING_COLOR],
  ["empty-signaling-dwell-large", SIGNALING_COLOR],
  ["empty-signaling-dwell-medium", SIGNALING_COLOR],
  ["empty-signaling-dwell-low", SIGNALING_COLOR],
  ["empty-not-signaling", NOT_SIGNALING_COLOR],
  ["loaded-not-signaling-dwell-mega", NOT_SIGNALING_LOADED],
  ["loaded-not-signaling-dwell-large", NOT_SIGNALING_LOADED],
  ["loaded-not-signaling-dwell-medium", NOT_SIGNALING_LOADED],
  ["loaded-not-signaling-dwell-low", NOT_SIGNALING_LOADED],
  ["unknown-not-signaling-dwell-mega", NOT_SIGNALING_LOADED],
  ["unknown-not-signaling-dwell-large", NOT_SIGNALING_LOADED],
  ["unknown-not-signaling-dwell-medium", NOT_SIGNALING_LOADED],
  ["unknown-not-signaling-dwell-low", NOT_SIGNALING_LOADED],
  ["empty-not-signaling-dwell-mega", NOT_SIGNALING_COLOR],
  ["empty-not-signaling-dwell-large", NOT_SIGNALING_COLOR],
  ["empty-not-signaling-dwell-medium", NOT_SIGNALING_COLOR],
  ["empty-not-signaling-dwell-low", NOT_SIGNALING_COLOR],
  ["unknown-signaling-moving", SIGNALING_COLOR],
  ["unknown-not-signaling-moving", NOT_SIGNALING_COLOR],
]);

export const DWELL_TYPE_ARC_LINE_MAP = new Map([
  ["mega", { arcPos: 1, lineDashed: [40, 15] }],
  ["large", { arcPos: -2.2, lineDashed: [40, 15, 40, 15, 40, 70] }],
  ["medium", { arcPos: -2, lineDashed: [50, 10, 50, 120] }],
  ["low", { arcPos: 4, lineDashed: [50, 300, 0] }],
]);
export const DWELL_TYPE_ARC_LINE_MAP_GM = new Map([
  ["mega", { arcPos: 1, lineDashed: [20, 6] }],
  ["large", { arcPos: -2.2, lineDashed: [20, 6, 20, 6, 20, 35] }],
  ["medium", { arcPos: -2.3, lineDashed: [25, 5, 25, 60] }],
  ["low", { arcPos: 4, lineDashed: [25, 150, 0] }],
]);

export const ASSETS_TYPES_SIZE_MAP: Map<string, number> = new Map([
  ["loaded-signaling-moving", 37],
  ["empty-signaling-moving", 37],
  ["loaded-not-signaling-moving", 37],
  ["empty-not-signaling-moving", 37],
  ["empty-signaling-dwell-mega", 37],
  ["empty-signaling-dwell-large", 37],
  ["empty-signaling-dwell-medium", 37],
  ["empty-signaling-dwell-low", 37],
  ["loaded-signaling-dwell-mega", 37],
  ["loaded-signaling-dwell-large", 37],
  ["loaded-signaling-dwell-medium", 37],
  ["loaded-signaling-dwell-low", 37],
  ["loaded-not-signaling-dwell-mega", 37],
  ["loaded-not-signaling-dwell-large", 37],
  ["loaded-not-signaling-dwell-medium", 37],
  ["loaded-not-signaling-dwell-low", 37],
  ["empty-not-signaling-dwell-mega", 37],
  ["empty-not-signaling-dwell-large", 37],
  ["empty-not-signaling-dwell-medium", 37],
  ["empty-not-signaling-dwell-low", 37],
  ["unknown-signaling-moving", 37],
  ["unknown-not-signaling-moving", 37],
  ["unknown-not-signaling-dwell-mega", 37],
  ["unknown-not-signaling-dwell-large", 37],
  ["unknown-not-signaling-dwell-medium", 37],
  ["unknown-not-signaling-dwell-low", 37],
  ["unknown-signaling-dwell-mega", 37],
  ["unknown-signaling-dwell-large", 37],
  ["unknown-signaling-dwell-medium", 37],
  ["unknown-signaling-dwell-low", 37],
]);

export const ASSETS_LIST_WIDTH = 240;
export const FILTERS_LIST_WIDTH = 330;
export const SMALLEST_SCREEN_WIDTH = 462;
export const ASSETS_LIST_MIN_HEIGHT = 320;
export const ASSETS_LIST_HEADER_HEIGHT = 56;
export const ASSETS_LIST_ITEM_HEIGHT = 58;
export const GEOFENCE_LIST_ITEM_HEIGHT = 64;
export const ASSET_DETAIL_PANEL_WIDTH = 240;
export const ASSETS_LIST_PAGINATION_HEIGHT = 88;
export const DWELL_STATUS_RANGE_MAP: {
  [x: string]: { minDays: number; maxDays: number; sign: string };
} = {
  Low: { minDays: 0, maxDays: 3, sign: "(0-3)" },
  Medium: { minDays: 4, maxDays: 7, sign: "(4-7)" },
  High: { minDays: 8, maxDays: 29, sign: "(8-29)" },
  Mega: { minDays: 30, maxDays: 9999, sign: "(30+)" },
};

export const FILTER_BUTTONS_ARR = [
  { title: "Today", daysBefore: 0 },
  { title: "Yesterday", daysBefore: 1 },
  { title: "This Week", daysBefore: "unknown" },
  { title: "Last Week", daysBefore: "lastWeek" },
  { title: "Last 7 Days", daysBefore: 7 },
  { title: "Last 14 Days", daysBefore: 14 },
  { title: "Last 90 Days", daysBefore: 90 },
  { title: "May", daysBefore: "month" },
  { title: "June", daysBefore: "month" },
  { title: "July", daysBefore: "month" },
];

export const MONTHS_JS_NAMING = [
  { name: "January", value: 0 },
  { name: "February", value: 1 },
  { name: "March", value: 2 },
  { name: "April", value: 3 },
  { name: "May", value: 4 },
  { name: "June", value: 5 },
  { name: "July", value: 6 },
  { name: "August", value: 7 },
  { name: "September", value: 8 },
  { name: "October", value: 9 },
  { name: "November", value: 10 },
  { name: "December", value: 11 },
];

export type DateFilterOption = {
  start: () => Date;
  end: () => Date;
  title: string;
};

export const DATE_FILTER_OPTIONS: DateFilterOption[] = [
  {
    start: () => getStartOfToday(),
    end: () => getEndOfToday(),
    title: "Today",
  },
  {
    start: () => getStartOfYesterday(),
    end: () => getEndOfYesterday(),
    title: "Yesterday",
  },
  {
    start: () => getStartOfWeek(new Date()),
    end: () => getEndOfToday(),
    title: "This Week",
  },
  {
    start: () => getStartOfWeek(getSubDays(new Date(), 7)),
    end: () => getEndOfWeek(getSubDays(new Date(), 7)),
    title: "Last Week",
  },
  {
    start: () => getSubDays(new Date(), 7),
    end: () => getEndOfToday(),
    title: "Last 7 Days",
  },
  {
    start: () => getSubDays(new Date(), 14),
    end: () => getEndOfToday(),
    title: "Last 14 Days",
  },
  {
    start: () => getSubDays(new Date(), 90),
    end: () => getEndOfToday(),
    title: "Last 90 Days",
  },
  {
    start: () => getStartOfMonth(getSubMonths(new Date(), 2)),
    end: () => getEndOfMonth(getSubMonths(new Date(), 2)),
    title: formatDate(getSubMonths(new Date(), 2), "MMM"),
  },
  {
    start: () => getStartOfMonth(getSubMonths(new Date(), 1)),
    end: () => getEndOfMonth(getSubMonths(new Date(), 1)),
    title: formatDate(getSubMonths(new Date(), 1), "MMM"),
  },
  {
    start: () => getStartOfMonth(new Date()),
    end: () => getEndOfMonth(new Date()),
    title: formatDate(new Date(), "MMM"),
  },
];

export interface SortOption {
  label: string;
  order: "asc" | "desc";
  key: string;
  id: string;
}

export const SORT_OPTIONS: SortOption[] = [
  {
    label: "Reported (Most Recent)",
    id: "reported-recent",
    order: "desc",
    key: "lst_evnt_t",
  },
  {
    label: "Reported (Oldest)",
    id: "reported-oldest",
    order: "asc",
    key: "lst_evnt_t",
  },
  {
    label: "Name (A to Z)",
    id: "name-a-z",
    order: "asc",
    key: "name",
  },
  {
    label: "Name (Z to A)",
    id: "name-z-a",
    order: "desc",
    key: "name",
  },
  {
    label: "Location (A to Z)",
    id: "location-a-z",
    order: "asc",
    key: "city",
  },
  {
    label: "Location (Z to A)",
    id: "location-z-a",
    order: "desc",
    key: "city",
  },
];

export const ASSET_TYPES = ["Trailer", "Chassis", "Container"];

export const GOOGLE_MAP_STYLES = [
  { label: "Satellite", value: GoogleMapsTypeIds.SATELLITE },
  { label: "Street", value: GoogleMapsTypeIds.ROADMAP },
];

export const MAP_LEGENDS = [
  {
    dwellingDays: 0,
    trip_st: TRIP_STATUS.Parked,
    signal: true,
    cargo_state: CargoUltrasonicState.Unknown,
    svgProps: { "data-testid": "unknown-signaling-parked" },
    label: "Parked Unknown Low Dwell",
  },
  {
    dwellingDays: 4,
    trip_st: TRIP_STATUS.Parked,
    signal: true,
    cargo_state: CargoUltrasonicState.Unknown,
    svgProps: { "data-testid": "unknown-signaling-parked" },
    label: "Parked Unknown  Medium Dwell",
  },
  {
    dwellingDays: 10,
    trip_st: TRIP_STATUS.Parked,
    signal: true,
    cargo_state: CargoUltrasonicState.Unknown,
    svgProps: { "data-testid": "unknown-signaling-parked" },
    label: "Parked Unknown  High Dwell",
  },
  {
    dwellingDays: 31,
    trip_st: TRIP_STATUS.Parked,
    signal: true,
    cargo_state: CargoUltrasonicState.Unknown,
    svgProps: { "data-testid": "unknown-signaling-parked" },
    label: "Parked Unknown  Mega Dwell",
  },
  {
    dwellingDays: 0,
    trip_st: TRIP_STATUS.Moving,
    signal: true,
    cargo_state: CargoUltrasonicState.Loaded,
    svgProps: { "data-testid": "loaded-signaling-moving" },
    label: "Moving Loaded",
  },
  {
    dwellingDays: 0,
    trip_st: TRIP_STATUS.Moving,
    signal: true,
    cargo_state: CargoUltrasonicState.Unloaded,
    svgProps: { "data-testid": "loaded-signaling-moving" },
    label: "Moving Empty",
  },
  {
    dwellingDays: 0,
    trip_st: TRIP_STATUS.Moving,
    signal: true,
    cargo_state: CargoUltrasonicState.Unknown,
    svgProps: { "data-testid": "loaded-signaling-moving" },
    label: "Moving Unknown",
  },
  {
    dwellingDays: 0,
    trip_st: TRIP_STATUS.Parked,
    signal: true,
    cargo_state: CargoUltrasonicState.Unloaded,
    svgProps: { "data-testid": "loaded-signaling-dwell-low" },
    label: "Parked Empty Low Dwell",
  },
  {
    dwellingDays: 4,
    trip_st: TRIP_STATUS.Parked,
    signal: true,
    cargo_state: CargoUltrasonicState.Unloaded,
    svgProps: { "data-testid": "loaded-signaling-dwell-low" },
    label: "Parked Empty Medium Dwell",
  },
  {
    dwellingDays: 10,
    trip_st: TRIP_STATUS.Parked,
    signal: true,
    cargo_state: CargoUltrasonicState.Unloaded,
    svgProps: { "data-testid": "loaded-signaling-dwell-low" },
    label: "Parked Empty High Dwell",
  },
  {
    dwellingDays: 31,
    trip_st: TRIP_STATUS.Parked,
    signal: true,
    cargo_state: CargoUltrasonicState.Unloaded,
    svgProps: { "data-testid": "loaded-signaling-dwell-low" },
    label: "Parked Empty Mega Dwell",
  },
  {
    dwellingDays: 0,
    trip_st: TRIP_STATUS.Parked,
    signal: true,
    cargo_state: CargoUltrasonicState.Loaded,
    svgProps: { "data-testid": "loaded-signaling-dwell-low" },
    label: "Parked Loaded Low Dwell",
  },
  {
    dwellingDays: 4,
    trip_st: TRIP_STATUS.Parked,
    signal: true,
    cargo_state: CargoUltrasonicState.Loaded,
    svgProps: { "data-testid": "loaded-signaling-dwell-low" },
    label: "Parked Loaded Medium Dwell",
  },
  {
    dwellingDays: 10,
    trip_st: TRIP_STATUS.Parked,
    signal: true,
    cargo_state: CargoUltrasonicState.Loaded,
    svgProps: { "data-testid": "loaded-signaling-dwell-low" },
    label: "Parked Loaded High Dwell",
  },
  {
    dwellingDays: 31,
    trip_st: TRIP_STATUS.Parked,
    signal: true,
    cargo_state: CargoUltrasonicState.Loaded,
    svgProps: { "data-testid": "loaded-signaling-dwell-low" },
    label: "Parked Loaded Mega Dwell",
  },
];

export const SORT_OPTIONS_GEOFENCES: SortOption[] = [
  {
    label: "Reported (Most Recent)",
    id: "reported-recent",
    order: "desc",
    key: "createdAt",
  },
  {
    label: "Reported (Oldest)",
    id: "reported-oldest",
    order: "asc",
    key: "createdAt",
  },
  {
    label: "Name (A to Z)",
    id: "name-a-z",
    order: "asc",
    key: "name",
  },
  {
    label: "Name (Z to A)",
    id: "name-z-a",
    order: "desc",
    key: "name",
  },
];

export const MAP_GEO_STYLES = [
  // inactive polygon styles start
  // background
  {
    id: "gl-draw-polygon-fill-inactive",
    type: "fill",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Polygon"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "fill-color": "#3C88CC",
      "fill-outline-color": "#5086C7",
      "fill-opacity": 0.2,
    },
  },
  // border
  {
    id: "gl-draw-polygon-stroke-inactive",
    type: "line",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Polygon"],
      ["!=", "mode", "static"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#5086C7",
      "line-dasharray": [0.2, 2],
      "line-width": 5,
    },
  },
  // inactive polygon styles end

  // active polygon styles start
  // background
  {
    id: "gl-draw-polygon-fill-active",
    type: "fill",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": "#3C88CC",
      "fill-outline-color": "#3C88CC",
      "fill-opacity": 0.2,
    },
  },
  // vertex point
  {
    id: "gl-draw-polygon-and-line-vertex-active",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 10,
      "circle-color": "#508FC7",
    },
  },
  // vertex halo point
  {
    id: "gl-draw-polygon-and-line-vertex-halo-active",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 8,
      "circle-color": "#FFFFFF",
    },
  },
  // midpoint vertex point
  {
    id: "gl-draw-polygon-midpoint-vertex",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": 8,
      "circle-color": "#508FC7",
    },
  },
  // midpoint vertex halo point
  {
    id: "gl-draw-polygon-midpoint-vertex-hallow",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": 6,
      "circle-color": "#FFFFFF",
    },
  },
  // border
  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: [
      "all",
      ["==", "active", "true"],
      ["==", "$type", "Polygon"],
      ["!=", "mode", "static"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#3C88CC",
      "line-dasharray": [0.2, 2],
      "line-width": 5,
    },
  },
  // active polygon styles end
];

export const ODOMETER_CHART_DATE_RANGE_OPTIONS = [
  {
    value: 1,
    label: "Last 30 Days",
    getRange: () => {
      return [
        formatDate(getStartOfDay(getSubDays(new Date(), 30)), "yyyy-MM-dd") +
          "T00:00:00.000Z",
        formatDate(getEndOfToday(), "yyyy-MM-dd") + "T23:59:59.999Z",
      ];
    },
  },
  {
    value: 2,
    label: "Today",
    getRange: () => {
      return [
        formatDate(new Date(), "yyyy-MM-dd") + "T00:00:00.000Z",
        formatDate(new Date(), "yyyy-MM-dd") + "T23:59:59.999Z",
      ];
    },
  },
  {
    value: 3,
    label: "Last 2 Days",
    getRange: () => {
      return [
        formatDate(getStartOfYesterday(), "yyyy-MM-dd") + "T00:00:00.000Z",
        formatDate(getEndOfToday(), "yyyy-MM-dd") + "T23:59:59.999Z",
      ];
    },
  },
  {
    value: 4,
    label: "This Week",
    getRange: () => {
      return [
        formatDate(getStartOfWeek(new Date()), "yyyy-MM-dd") + "T00:00:00.000Z",
        formatDate(getEndOfToday(), "yyyy-MM-dd") + "T23:59:59.999Z",
      ];
    },
  },
  {
    value: 5,
    label: "Last Week",
    getRange: () => {
      return [
        formatDate(
          getStartOfWeek(subWeeksFromDate(new Date(), 1)),
          "yyyy-MM-dd"
        ) + "T00:00:00.000Z",
        formatDate(
          getEndOfWeek(subWeeksFromDate(new Date(), 1)),
          "yyyy-MM-dd"
        ) + "T23:59:59.999Z",
      ];
    },
  },
];

export const BREADCRUMBS_TAB_DATE_RANGE_OPTIONS = [
  {
    value: 1,
    label: "Last 24 Hours",
    getRange: () => {
      return [getSubHours(new Date(), 24), new Date()];
    },
  },
  {
    value: 2,
    label: "Last 7 Days",
    getRange: () => {
      return [getStartOfDay(getSubDays(new Date(), 6)), getEndOfToday()];
    },
  },
  {
    value: 3,
    label: "Last 30 Days",
    getRange: () => {
      return [getStartOfDay(getSubDays(new Date(), 30)), getEndOfToday()];
    },
  },
  {
    value: 4,
    label: "Custom",
  },
];

export const VOLTAGE_CHART_DATE_RANGE_OPTIONS = [
  {
    value: 1,
    label: "Last 30 Days",
    getRange: () => {
      return [getStartOfDay(getSubDays(new Date(), 30)), getEndOfToday()];
    },
  },
  {
    value: 2,
    label: "Today",
    getRange: () => {
      return [getStartOfToday(), getEndOfToday()];
    },
  },
  {
    value: 3,
    label: "Last 2 Days",
    getRange: () => {
      return [getStartOfYesterday(), getEndOfToday()];
    },
  },
  {
    value: 4,
    label: "This Week",
    getRange: () => {
      return [getStartOfWeek(new Date()), getEndOfToday()];
    },
  },
  {
    value: 5,
    label: "Last Week",
    getRange: () => {
      return [
        getStartOfWeek(subWeeksFromDate(new Date(), 1)),
        getEndOfWeek(subWeeksFromDate(new Date(), 1)),
      ];
    },
  },
  {
    value: 6,
    label: "Custom",
  },
];

export enum DateRangeKey {
  Today = "today",
  Last2Days = "last-2-days",
  ThisWeek = "this-week",
  LastWeek = "last-week",
  Last30Days = "last-30-days",
  Custom = "custom",
}

// TODO: Make all the date range options consistent and follow this definition
export const DEFAULT_DATE_RANGE_OPTIONS = [
  {
    value: 1,
    key: DateRangeKey.Today,
    label: "Today",
    getRange: () => {
      return [getStartOfToday(), getEndOfToday()];
    },
  },
  {
    value: 2,
    key: DateRangeKey.Last2Days,
    label: "Last 2 Days",
    getRange: () => {
      return [getStartOfYesterday(), getEndOfToday()];
    },
  },
  {
    value: 3,
    key: DateRangeKey.ThisWeek,
    label: "This Week",
    getRange: () => {
      return [getStartOfWeek(new Date()), getEndOfToday()];
    },
  },
  {
    value: 4,
    key: DateRangeKey.LastWeek,
    label: "Last Week",
    getRange: () => {
      return [
        getStartOfWeek(subWeeksFromDate(new Date(), 1)),
        getEndOfWeek(subWeeksFromDate(new Date(), 1)),
      ];
    },
  },
  {
    value: 5,
    key: DateRangeKey.Last30Days,
    label: "Last 30 Days",
    getRange: () => {
      return [getStartOfDay(getSubDays(new Date(), 29)), getEndOfToday()];
    },
  },
  {
    value: 6,
    key: DateRangeKey.Custom,
    label: "Custom",
  },
];

export const DEFAULT_CHART_DATE_RANGE_OPTION =
  DEFAULT_DATE_RANGE_OPTIONS[4].value;

export const TPMS_CHART_DATE_RANGE_OPTIONS = DEFAULT_DATE_RANGE_OPTIONS;
export const AIRBAG_CHART_DATE_RANGE_OPTIONS = DEFAULT_DATE_RANGE_OPTIONS;
export const AIRTANK_CHART_DATE_RANGE_OPTIONS = DEFAULT_DATE_RANGE_OPTIONS;
export const REGULATOR_CHART_DATE_RANGE_OPTIONS = DEFAULT_DATE_RANGE_OPTIONS;

export const OTHER_SENSORS_GRAPH_OPTIONS = [
  {
    value: 1,
    label: "Today",
    start: formatDate(getStartOfToday(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
    end: formatDate(getStartOfToday(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
  },
  {
    value: 2,
    label: "Last 2 Days",
    start: formatDate(getStartOfYesterday(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
    end: formatDate(getStartOfToday(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
  },
  {
    value: 3,
    label: "This Week",
    start: formatDate(
      getStartOfWeek(new Date()),
      "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
    ),
    end: formatDate(getEndOfWeek(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
  },
  {
    value: 4,
    label: "Last Week",
    start: formatDate(
      getStartOfWeek(subWeeksFromDate(new Date(), 1)),
      "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
    ),
    end: formatDate(
      getEndOfWeek(subWeeksFromDate(new Date(), 1)),
      "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
    ),
  },
  {
    value: 5,
    label: "Last 30 Days",
    start: formatDate(
      getStartOfDay(getSubDays(new Date(), 29)),
      "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
    ),
    end: formatDate(getStartOfToday(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
  },
  {
    value: 6,
    label: "Custom",
  },
];

// This map is not exhaustive so please if you see a missing filter add it here
// and also contains geofence filter names
export const ASSET_FILTERS_DISPLAY_NAME_MAP = new Map<
  keyof AssetFilters | keyof IGeofenceFilters,
  string
>([
  ["airbag", "Air Bag Health"],
  ["assetHealth", "Asset Health"],
  ["assetTags", "Tags"],
  ["assetTypes", "Asset Type"],
  ["assetYear", "Asset Year"],
  ["atisAlpha", "ATIS Health"],
  ["backupBatteryVoltage", "Backup Battery Voltage"],
  ["batteryPrimaryVoltage", "Battery Primary Voltage"],
  ["batterySecondaryVoltage", "Battery Secondary Voltage"],
  ["internalCameraStatus", "Internal Camera State"],
  ["cargoUltrasonicState", "Cargo State"],
  ["doorState", "Door State"],
  ["geofenceCategories", "Geofence Categories"],
  ["geofenceNames", "Geofence Names"],
  ["installStatus", "Installation Status"],
  ["installedDate", "Installation Date"],
  ["installer", "Installer"],
  ["internalCameraFloorUsagePercentage", "Floor Space Usage"],
  ["internalCameraCubeUsagePercentage", "Cube Space Usage"],
  ["lastReportedDateRange", "Last Reported"],
  ["liteSentryGamma", "Light Circuit Health"],
  ["manufacturers", "Manufacturers"],
  ["regions", "Regions"],
  ["numberOfAxles", "№ of Axles"],
  ["numberOfTires", "№ of Tires"],
  ["productNames", "Product Name"],
  ["signals", "GPS Signal"],
  ["solarAmperage", "Solar"],
  ["status", "Trip Status"],
  ["subOrganizations", "Sub-Organizations"],
  ["supplyPressure", "Regulator Health"],
  ["tankPressure", "Air Tank Health"],
  ["temperatureInternal", "Temperature (Internal) Health"],
  ["totalMileage", "Total Mileage"],
  ["tpmsBeta", "TPMS Health"],
  ["wheelEndTemperature", "Wheel End Health"],
  ["weightStatus", "Weight"],
  ["battery", "Battery"],
  ["dualImbalance", "Dual Imbalance"],
  ["liftgate", "Liftgate"],
  ["orgIds", "Organization"],
  ["names", "Name"],
  ["shapes", "Geofence Shape"],
  ["typeIds", "Geofence Type"],
  ["underMin", "Under Min"],
  ["overMax", "Over Max"],
  ["tags", "Geofence Tags"],
  ["hasAssets", "Geofence Assets"],
  ["timezones", "Time Zone"],
  ["createdRange", "Geofence Created"],
  ["updatedRange", "Geofence Updated"],
  ["locationCodes", "Location Code"],
  ["operatingHoursRange", "Currently Open"],
  ["zones", "Zones"],
  ["sharedGroupName", "Shared Group Name"],
  ["selectedShareType", "Shared Type"],
  ["assetDetentionState", "Detention State"],
]);

export const CUSTOM_FILTER_FIELD_MAP = new Map<string, FilterPayload>([
  [
    "subOrganizations",
    {
      columnField: "customer_orgs_id",
      operatorValue: OperatorValue.STRING_EQUALS,
      value: {},
    },
  ],
  [
    "assetTypes",
    {
      columnField: "category",
      operatorValue: OperatorValue.IS_ANY_OF,
      value: [],
    },
  ],
  [
    "assetTags",
    {
      columnField: "tags",
      operatorValue: OperatorValue.STRING_CONTAINS,
      value: [],
    },
  ],
  [
    "productNames",
    {
      columnField: "prd_cde",
      operatorValue: OperatorValue.IS_ANY_OF,
      value: [],
    },
  ],
  [
    "status",
    {
      columnField: "tripStatus.tripState",
      operatorValue: OperatorValue.IS_ANY_OF,
      value: [],
    },
  ],
  [
    "dwell",
    {
      operatorValue: OperatorValue.VALUE_RANGES,
      columnField: "dwelling.dwellingDays",
      valueRanges: [{ startValue: 0, endValue: 0 }],
    },
  ],
  [
    "dwellRange",
    {
      operatorValue: OperatorValue.VALUE_RANGE,
      columnField: "dwelling.dwellingDays",
      valueRange: { startValue: 0, endValue: 0 },
    },
  ],
  ["signals", { columnField: "signal", operatorValue: "" }],
  [
    "assetId",
    {
      columnField: "asset_id",
      operatorValue: OperatorValue.IS_ANY_OF,
      value: [],
    },
  ],
  [
    "imeiId",
    { columnField: "imei", operatorValue: OperatorValue.IS_ANY_OF, value: [] },
  ],
  ["installStatus", { columnField: "imei", operatorValue: "" }],
  [
    "installer",
    {
      columnField: "installer",
      operatorValue: OperatorValue.IS_ANY_OF,
      value: [],
    },
  ],
  [
    "installedDate",
    {
      operatorValue: OperatorValue.DATE_RANGE,
      columnField: "installed_date",
      dateRange: { startDate: null, endDate: null },
    },
  ],
  [
    "batteryPrimaryVoltage",
    {
      operatorValue: OperatorValue.VALUE_RANGE,
      columnField: "sensors.voltage.data.main",
      valueRange: { startValue: null, endValue: null },
    },
  ],
  [
    "batterySecondaryVoltage",
    {
      operatorValue: OperatorValue.VALUE_RANGE,
      columnField: "sensors.voltage.data.auxiliary",
      valueRange: { startValue: null, endValue: null },
    },
  ],
  [
    "backupBatteryVoltage",
    {
      operatorValue: OperatorValue.VALUE_RANGE,
      columnField: "sensors.voltage.data.battery",
      valueRange: { startValue: null, endValue: null },
    },
  ],
  [
    "solarAmperage",
    {
      operatorValue: OperatorValue.VALUE_RANGE,
      columnField: "sensors.voltage.data.solarAmperage",
      valueRange: { startValue: null, endValue: null },
    },
  ],
  [
    "internalCameraFloorUsagePercentage",
    {
      operatorValue: OperatorValue.VALUE_RANGE,
      valueRange: { startValue: null, endValue: null },
      columnField: "sensors.cargoCamera.data.floorUsagePercentage",
    },
  ],
  [
    "internalCameraCubeUsagePercentage",
    {
      operatorValue: OperatorValue.VALUE_RANGE,
      valueRange: { startValue: null, endValue: null },
      columnField: "sensors.cargoCamera.data.cubeUsagePercentage",
    },
  ],
  [
    "internalCameraStatus",
    {
      operatorValue: OperatorValue.IS_ANY_OF,
      value: [],
      columnField: "sensors.cargoCamera.data.floorUsageStatus",
    },
  ],
  [
    "doorState",
    {
      operatorValue: OperatorValue.IS_ANY_OF,
      value: [],
      columnField: "sensors.bleDoorSensor.data.doorState",
    },
  ],

  [
    "cargoUltrasonicState",
    {
      operatorValue: OperatorValue.IS_ANY_OF,
      columnField: "sensors.chassis.data.cargoState",
      value: [],
    },
  ],
  [
    "liteSentryGamma",
    {
      operatorValue: OperatorValue.IS_ANY_OF,
      columnField: "sensors.liteSentryGamma.statusLiteSentryGamma",
      value: [],
    },
  ],
  [
    "atisAlpha",
    {
      operatorValue: OperatorValue.IS_ANY_OF,
      columnField: "sensors.atisAlpha.statusAtisAlpha",
      value: [],
    },
  ],
  [
    "temperatureInternal",
    {
      operatorValue: OperatorValue.IS_ANY_OF,
      columnField: "sensors.temperature.statusTemperature",
      value: [],
    },
  ],
  [
    "wheelEndTemperature",
    {
      operatorValue: OperatorValue.IS_ANY_OF,
      columnField: "sensors.psiWheelEnd.statusPsiWheelEnd",
      value: [],
    },
  ],
  [
    "airbag",
    {
      operatorValue: OperatorValue.IS_ANY_OF,
      columnField: "sensors.airbag.statusAirbag",
      value: [],
    },
  ],
  [
    "tpmsBeta",
    {
      operatorValue: OperatorValue.IS_ANY_OF,
      columnField: "sensors.tpmsBeta.statusTpmsBeta",
      value: [],
    },
  ],
  [
    "supplyPressure", // regulator
    {
      operatorValue: OperatorValue.IS_ANY_OF,
      columnField: "sensors.psiAirSupply.data.statusSupplyPressure",
      value: [],
    },
  ],
  [
    "tankPressure", // Airtank
    {
      operatorValue: OperatorValue.IS_ANY_OF,
      columnField: "sensors.psiAirSupply.data.statusTankPressure",
      value: [],
    },
  ],
  [
    "assetHealth",
    {
      operatorValue: OperatorValue.IS_ANY_OF,
      columnField: "health.condition",
      value: [],
    },
  ],
  [
    "numberOfAxles",
    {
      operatorValue: OperatorValue.IS_ANY_OF,
      columnField: "num_of_axles",
      value: [],
    },
  ],
  [
    "numberOfTires",
    {
      operatorValue: OperatorValue.IS_ANY_OF,
      columnField: "wheel_config",
      value: [],
    },
  ],
  [
    "weightStatus",
    {
      operatorValue: OperatorValue.VALUE_RANGE,
      columnField: "emptyWeight",
      valueRange: { startValue: null, endValue: null },
    },
  ],
  [
    "totalMileage",
    {
      operatorValue: OperatorValue.VALUE_RANGE,
      columnField: "tripStatus.odometer",
      valueRange: { startValue: null, endValue: null },
    },
  ],
  [
    "manufacturers",
    {
      operatorValue: OperatorValue.IS_ANY_OF,
      columnField: "manufacturer",
      value: [],
    },
  ],
  [
    "regions",
    {
      operatorValue: OperatorValue.IS_ANY_OF,
      columnField: "state",
      value: [],
    },
  ],
  [
    "zonesRange",
    {
      operatorValue: OperatorValue.VALUE_RANGE,
      columnField: "fullAddress.postcode",
      valueRangeForZones: { country: "", startValue: null, endValue: null },
    },
  ],
  [
    "zonesMatch",
    {
      operatorValue: OperatorValue.IS_ANY_OF,
      columnField: "fullAddress.postcode",
      value: [],
    },
  ],
  [
    "assetYear",
    {
      operatorValue: OperatorValue.VALUE_RANGE,
      columnField: "metadata.year",
      valueRange: { startValue: null, endValue: null },
    },
  ],
  [
    "lastReportedDateRange",
    {
      operatorValue: OperatorValue.DATE_RANGE,
      columnField: "lst_evnt_t",
      dateRange: { startDate: null, endDate: null },
    },
  ],
  [
    "geofenceNames",
    {
      operatorValue: OperatorValue.ARRAY_OF_OBJECTS_PROPERTY_IS_ANY_OF,
      columnField: "geofence",
      elemMatchField: "name",
      value: [],
    },
  ],
  [
    "geofenceCategories",
    {
      operatorValue: OperatorValue.ARRAY_OF_OBJECTS_PROPERTY_IS_ANY_OF,
      columnField: "geofence",
      elemMatchField: "typeId",
      value: [],
    },
  ],
  [
    "sharedGroupName",
    {
      operatorValue: OperatorValue.IS_ANY_OF,
      columnField: "sharedGroupName",
      value: [],
    },
  ],
]);

export const beaconTypes = [
  "loaded-signaling-moving",
  "empty-signaling-moving",
  "unknown-signaling-moving",
  "loaded-not-signaling-moving",
  "empty-not-signaling-moving",
  "unknown-not-signaling-moving",
];

export const getGeofenceRenderOptions = (
  editable: boolean,
  draggable: boolean
) => ({
  fillColor: "#3C88CC",
  fillOpacity: 0.2,
  strokeColor: "#5086C7",
  strokeOpacity: 1,
  strokeWeight: 5,
  clickable: true,
  editable,
  draggable,
  geodesic: false,
  zIndex: 1,
});

export const getFeatureIconType = (feature: Feature): string => {
  const { signal, status, load, dwellingDays } = feature.properties;
  const isLoaded = load === CargoUltrasonicState.Loaded;
  const isEmpty = load === CargoUltrasonicState.Unloaded; // property value is renamed in tripStatus object from empty -> unloaded
  const isLoadUnknown = [
    CargoUltrasonicState.Unknown,
    CargoUltrasonicState.Na,
  ].includes(load as CargoUltrasonicState);
  const isSignaling = signal;
  const isMoving = status === AssetTripStatus.Moving;
  const isNotMoving = !isMoving;

  // Determine dwell type
  const dwell = dwellingDays ?? 0;
  const dwellType = getDwellType(dwell);
  let megaDwell, largeDwell, mediumDwell, lowDwell;
  switch (dwellType) {
    case DwellSensorState.Mega: {
      megaDwell = true;
      break;
    }
    case DwellSensorState.Large: {
      largeDwell = true;
      break;
    }
    case DwellSensorState.Medium: {
      mediumDwell = true;
      break;
    }
    case DwellSensorState.Low: {
      lowDwell = true;
      break;
    }
    default:
      break;
  }

  if (isLoaded && isSignaling && isMoving) {
    return "loaded-signaling-moving";
  }
  if (isEmpty && isSignaling && isMoving) {
    return "empty-signaling-moving";
  }
  if (isLoadUnknown && isSignaling && isMoving) {
    return "unknown-signaling-moving";
  }
  if (isLoaded && !isSignaling && isMoving) {
    return "loaded-not-signaling-moving";
  }
  if (isMoving && isLoadUnknown && !signal) {
    return "unknown-not-signaling-moving";
  }
  if (isEmpty && !isSignaling && isMoving) {
    return "empty-not-signaling-moving";
  }
  if (isLoaded && isSignaling && isNotMoving && megaDwell) {
    return "loaded-signaling-dwell-mega";
  }
  if (isLoaded && isSignaling && isNotMoving && largeDwell) {
    return "loaded-signaling-dwell-large";
  }
  if (isLoaded && isSignaling && isNotMoving && mediumDwell) {
    return "loaded-signaling-dwell-medium";
  }
  if (isLoaded && isSignaling && isNotMoving && lowDwell) {
    return "loaded-signaling-dwell-low";
  }
  if (isEmpty && isSignaling && isNotMoving && megaDwell) {
    return "empty-signaling-dwell-mega";
  }
  if (isEmpty && isSignaling && isNotMoving && largeDwell) {
    return "empty-signaling-dwell-large";
  }
  if (isEmpty && isSignaling && isNotMoving && mediumDwell) {
    return "empty-signaling-dwell-medium";
  }
  if (isEmpty && isSignaling && isNotMoving && lowDwell) {
    return "empty-signaling-dwell-low";
  }
  if (isLoaded && !isSignaling && isNotMoving && megaDwell) {
    return "loaded-not-signaling-dwell-mega";
  }
  if (isLoaded && !isSignaling && isNotMoving && largeDwell) {
    return "loaded-not-signaling-dwell-large";
  }
  if (isLoaded && !isSignaling && isNotMoving && mediumDwell) {
    return "loaded-not-signaling-dwell-medium";
  }
  if (isLoaded && !isSignaling && isNotMoving && lowDwell) {
    return "loaded-not-signaling-dwell-low";
  }
  if (isEmpty && !isSignaling && isNotMoving && megaDwell) {
    return "empty-not-signaling-dwell-mega";
  }
  if (isEmpty && !isSignaling && isNotMoving && largeDwell) {
    return "empty-not-signaling-dwell-large";
  }
  if (isEmpty && !isSignaling && isNotMoving && mediumDwell) {
    return "empty-not-signaling-dwell-medium";
  }
  if (isEmpty && !isSignaling && isNotMoving && lowDwell) {
    return "empty-not-signaling-dwell-low";
  }
  if (isLoadUnknown && isSignaling && isNotMoving && megaDwell) {
    return "unknown-signaling-dwell-mega";
  }
  if (isLoadUnknown && isSignaling && isNotMoving && largeDwell) {
    return "unknown-signaling-dwell-large";
  }
  if (isLoadUnknown && isSignaling && isNotMoving && mediumDwell) {
    return "unknown-signaling-dwell-medium";
  }
  if (isLoadUnknown && isSignaling && isNotMoving && lowDwell) {
    return "unknown-signaling-dwell-low";
  }
  if (isLoadUnknown && !isSignaling && isNotMoving && megaDwell) {
    return "unknown-not-signaling-dwell-mega";
  }
  if (isLoadUnknown && !isSignaling && isNotMoving && largeDwell) {
    return "unknown-not-signaling-dwell-large";
  }
  if (isLoadUnknown && !isSignaling && isNotMoving && mediumDwell) {
    return "unknown-not-signaling-dwell-medium";
  }
  if (isLoadUnknown && !isSignaling && isNotMoving && lowDwell) {
    return "unknown-not-signaling-dwell-low";
  }
  return "unknown-not-signaling-moving";
};

export const beaconIconsMapping: Record<string, any> = {
  "loaded-signaling-moving": loadedSignalingMoving,
  "empty-signaling-moving": emptySignalingMoving,
  "unknown-signaling-moving": unknownSignalingMoving,
  "loaded-not-signaling-moving": loadedNotSignalingMoving,
  "empty-not-signaling-moving": emptyNotSignalingMoving,
  "unknown-not-signaling-moving": unknownNotSignalingMoving,
};

export type BreadcrumbIconsMapping = { [key: string]: unknown };
export const breadcrumbIconsMapping: BreadcrumbIconsMapping = {
  location_update_event: locationUpdateEvent,
  health_event: healthEvent,
  door_event: doorEvent,
  cargo_event: cargoEvent,
  location_entry_event: locationEntryEvent,
};

export const googleLibraries: Libraries = [
  "places",
  "drawing",
  "geometry",
  "geocoding",
];

export enum EventHistoryEventNameUi {
  UnknownEvent = "Unknown Event",
  PowerUp = "Power-Up",
  Heartbeat = "Heart Beat",
  PowerConnect = "Power Connect",
  PowerDisconnect = "Power Disconnect",
  PowerLow = "Power Low",
  BatteryLow = "Battery Low",
  BatteryRestored = "Battery Restored",
  IgnitionOn = "Ignition On",
  IgnitionOff = "Ignition Off",
  GPSLock = "GPS Lock",
  GPSUnlock = "GPS Unlock",
  TripStart = "Trip Start",
  TripStop = "Trip Stop",
  Moving = "Moving",
  Stopped = "Stopped",
  HeadingChange = "Heading Change",
  TowAlert = "Tow Alert",
  ReportNow = "Report Now",
  Reset = "Reset",
  Maintenance = "Maintenance",
  MaintenanceQA = "Maintenance QA",
  TFTPFailed = "TFTP Failed",
  TFTPSuccess = "TFTP Success",
  MotionDetected = "Motion Detected",
  MotionStopped = "Motion Stopped",
  PowerSaveEnter = "Power Save Enter",
  PowerNormalEnter = "Power Normal Enter",
  UpdateFailed = "Update Failed",
  UpdateSuccessful = "Update Successful",
  Vibration = "Vibration",
  OrientationLocked = "Orientation Locked",
  OrientationUnlocked = "Orientation Unlocked",
  InstallAssistCompleted = "Install Assist Completed",
  DoorSensorUpdate = "Door Sensor Update",
  LiteSentryUpdate = "Lite Sentry Update",
  ABSReaderUpdate = "ABS Reader Update",
  ATISUpdate = "ATIS Update",
  TPMSUpdate = "TPMS Update",
  BeaconUpdate = "Beacon Update",
  TemperatureSensorUpdate = "Temperature Sensor Update",
  CargoCameraUpdate = "Cargo Camera Update",
  MCSCrashDetected = "MCS Crash Detected",
  LocationEntry = "Location Entry",
}

export enum EventHistoryEventCategory {
  Heartbeat = "Health Event",
  LocationUpdate = "Location Update",
  Cargo = "Cargo Update",
  Door = "Door Event",
  LocationEntry = "Location Entry",
}

export const EventHistoryCategoryToUiNamesMap = new Map<
  EventHistoryEventCategory,
  EventHistoryEventNameUi[]
>([
  [
    EventHistoryEventCategory.LocationUpdate,
    [
      EventHistoryEventNameUi.Vibration,
      EventHistoryEventNameUi.TripStart,
      EventHistoryEventNameUi.TripStop,
      EventHistoryEventNameUi.Moving,
      EventHistoryEventNameUi.HeadingChange,
    ],
  ],
  [
    EventHistoryEventCategory.Heartbeat,
    [EventHistoryEventNameUi.Heartbeat, EventHistoryEventNameUi.Stopped],
  ],
  [EventHistoryEventCategory.Door, [EventHistoryEventNameUi.DoorSensorUpdate]],
  [
    EventHistoryEventCategory.Cargo,
    [EventHistoryEventNameUi.CargoCameraUpdate],
  ],
  [
    EventHistoryEventCategory.LocationEntry,
    [EventHistoryEventNameUi.LocationEntry],
  ],
]);

export enum LastReportedDateOptions {
  Today = "Today",
  Yesterday = "Yesterday",
  TwoToSevenDays = "2-7 Days",
  EightToFourteenDays = "8-14 Days",
  FifteenToThirtyDays = "15-30 Days",
  ThirtyOneToSixtyDays = "31-60 Days",
  OverSixtyOneDays = "61+ Days",
}

export const LAST_REPORTED_DATE_OPTIONS_MAP = new Map<
  LastReportedDateOptions,
  FilterPayloadDateRange
>([
  [
    LastReportedDateOptions.Today,
    {
      startDate: getStartOfToday(),
      endDate: getEndOfToday(),
    },
  ],
  [
    LastReportedDateOptions.Yesterday,
    {
      startDate: getStartOfYesterday(),
      endDate: getEndOfYesterday(),
    },
  ],
  [
    LastReportedDateOptions.TwoToSevenDays,
    {
      startDate: getStartOfDay(getSubDays(new Date(), 7)), // from 7 days back
      endDate: getEndOfDay(getSubDays(new Date(), 2)), // to 2 days back
    },
  ],
  [
    LastReportedDateOptions.EightToFourteenDays,
    {
      startDate: getStartOfDay(getSubDays(new Date(), 14)), // from 14 days back
      endDate: getEndOfDay(getSubDays(new Date(), 8)), // to 8 days back
    },
  ],
  [
    LastReportedDateOptions.FifteenToThirtyDays,
    {
      startDate: getStartOfDay(getSubDays(new Date(), 30)), // from 30 days back
      endDate: getEndOfDay(getSubDays(new Date(), 15)), // to 15 days back
    },
  ],
  [
    LastReportedDateOptions.ThirtyOneToSixtyDays,
    {
      startDate: getStartOfDay(getSubDays(new Date(), 60)), // from 60 days back
      endDate: getEndOfDay(getSubDays(new Date(), 31)), // to 31 days back
    },
  ],
  [
    LastReportedDateOptions.OverSixtyOneDays,
    {
      startDate: getStartOfDay(getSubMonths(new Date(), 6)), // from 6 months back
      endDate: getEndOfDay(getSubDays(new Date(), 61)), // to 61 days back
    },
  ],
]);

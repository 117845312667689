import { useAuth } from "react-oidc-context";
import { Box, ThemeProvider } from "@mui/material";
import { useAuthFormTheme } from "../hooks/useAuthFormTheme";

const LoginSAML = () => {
  const formTheme = useAuthFormTheme();
  const auth = useAuth();

  console.log("AUTH log --- ", auth);
  return (
    <Box>
      <ThemeProvider theme={formTheme}>
        {auth.isLoading && <div>Loading AUTH...</div>}

        {auth.error && <div>Encountering error... {auth.error.message}</div>}

        {auth.isAuthenticated && (
          <div>
            <pre> Hello: {auth.user?.profile.email} </pre>
            <pre> ID Token: {auth.user?.id_token} </pre>
            <pre> Access Token: {auth.user?.access_token} </pre>
            <pre> Refresh Token: {auth.user?.refresh_token} </pre>

            <button onClick={() => auth.removeUser()}>Sign out</button>
          </div>
        )}
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              fontWeight: 500,
            }}
          >
            Login SAML Redirect Page
            <div>
              <Box
                sx={{
                  fontWeight: 500,
                }}
              >
                <button onClick={() => auth.signinRedirect()}>Sign in</button>
              </Box>
              <Box
                sx={{
                  fontWeight: 500,
                }}
              >
                <button onClick={() => {}}>Sign out</button>
              </Box>
            </div>
          </Box>
        </Box>
      </ThemeProvider>
    </Box>
  );
};

export default LoginSAML;

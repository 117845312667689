import { useEffect, useState } from "react";
import { useAppContext } from "../../../context/AppContext";
import {
  AssetOs,
  AssetSortOrder,
  AssetsResult,
  AssetTableData,
  GetTableDataInput,
  Maybe,
  Pagination,
  ProductData,
  useGetAssetsForTableOsQuery,
} from "../../../graphql/operations";
import {
  CargoState,
  DoorSensorState,
} from "../../../views/AssetsView/MapView/Assets/AssetShortTooltip/components/AssetShortTooltipSensors/AssetShortTooltipSensors.interface";
import { InternalCameraSpaceStatus } from "../../../views/AssetsView/MapView/Assets/CargoTabPanel/InternalCameraSpaceGraph/types";
import { useAssetsComplexFilters } from "../../../views/AssetsView/shared/hooks/useAssetsComplexFilters";
import { useAvailableOrgs } from "../useAvailableOrgs";
import { usePreferredTimezone } from "../usePreferredTimezone";
import { useProductsList } from "../useProductsList";

export interface AssetsTableDataHook {
  isLoading: boolean;
  isSuccess: boolean;
  data: AssetTableData[];
  pagination: Pagination;
}

type MapOpenSearchDataParams = {
  data: Maybe<AssetOs>[];
  products: ProductData[];
};

export const mapOpenSearchData = ({
  data,
  products,
}: MapOpenSearchDataParams): AssetTableData[] =>
  (data.filter(Boolean) as AssetOs[]).map((asset): AssetTableData => {
    const { sensors } = asset;
    const [lon, lat] = asset.location?.coordinates ?? [];
    const productName = asset.prd_cde
      ? products.find((product) => product._id === asset.prd_cde)?.product_name
      : "";

    // Cargo State is either loaded or unloaded but we show it as loaded or empty
    let mappedCargoState: CargoState | "" = "";
    if (sensors?.chassis?.data?.cargoState === "loaded") {
      mappedCargoState = CargoState.Loaded;
    } else if (sensors?.chassis?.data?.cargoState === "unloaded") {
      mappedCargoState = CargoState.Empty;
    }

    // Door Sensor State is only either open or closed
    let mappedDoorState: DoorSensorState | "" = "";
    if (sensors?.bleDoorSensor?.data?.doorState === DoorSensorState.Open) {
      mappedDoorState = DoorSensorState.Open;
    } else if (
      sensors?.bleDoorSensor?.data?.doorState === DoorSensorState.Closed
    ) {
      mappedDoorState = DoorSensorState.Closed;
    }

    // Internal Camera Status can be `Mostly LOADED`, `Fully LOADED`, `Partially LOADED`, `Barely LOADED` or `EMPTY`, but we only care about if it's loaded or empty
    let mappedInternalCameraStatus: string = "";
    const isLoaded = sensors?.cargoCamera?.data.floorUsageStatus
      ?.toLowerCase()
      .includes(InternalCameraSpaceStatus.Loaded);
    const isEmpty = sensors?.cargoCamera?.data.floorUsageStatus
      ?.toLowerCase()
      .includes(InternalCameraSpaceStatus.Empty);

    if (isLoaded) {
      mappedInternalCameraStatus = InternalCameraSpaceStatus.Loaded;
    } else if (isEmpty) {
      mappedInternalCameraStatus = InternalCameraSpaceStatus.Empty;
    }

    return {
      id: asset._id,
      imei: asset.imei ?? "",
      assetIdentifier: asset.asset_id ?? "",
      vin: asset.vin ?? "",
      associated: asset.associated ?? "",
      name: asset.name ?? "",
      orgId: asset.customer_orgs_id ?? "",
      orgName: asset.org_name ?? "",
      productName: productName ?? "",
      category: asset.category ?? "",
      tags: asset.tags ?? "",

      state: asset.fullAddress?.state ?? "",
      zip: asset.fullAddress?.postcode ?? "",
      city: asset.fullAddress?.city ?? "",
      lat: lat,
      lon: lon,

      tripStatus: asset.tripStatus?.tripState ?? "",
      doorType: asset.door_type ?? "",
      numOfAxles: asset.num_of_axles,
      numOfTires: asset.wheel_config,
      length: asset.length,
      lastGpsTime: asset.last_gps_t ?? "",
      lastEventTime: asset.lst_evnt_t ?? "",
      dwellingDays:
        asset.dwelling?.dwellingDays && asset.dwelling?.endDwellAt === null
          ? asset.dwelling?.dwellingDays
          : null,
      signal: asset.tripStatus?.signal,
      geofence: asset.geofence,

      // Asset Condition
      statusAsset: asset.statusAsset,
      batteryHealthCondition: asset.sensors?.voltage?.data?.statusBattery ?? "",
      airbagHealthCondition: asset.sensors?.airbag?.statusAirbag ?? "",
      airTankHealthCondition:
        asset.sensors?.psiAirSupply?.data?.statusTankPressure ?? "",
      atisAlphaHealthCondition: asset.sensors?.atisAlpha?.statusAtisAlpha,
      cargoState: mappedCargoState,
      doorState: mappedDoorState,
      dualImbalanceHealthCondition:
        asset.sensors?.tpmsBeta?.statusImbalance ?? "",
      internalCameraStatus: mappedInternalCameraStatus,
      liftgateHealthCondition:
        asset.sensors?.liftgateAdvertisement?.statusLiftgate ?? "",
      liteSentryGammaHealthCondition:
        asset.sensors?.liteSentryGamma?.statusLiteSentryGamma ?? "",
      regulatorHealthCondition:
        asset.sensors?.psiAirSupply?.data?.statusSupplyPressure ?? "",
      temperatureHealthCondition:
        asset.sensors?.temperature?.statusTemperature ?? "",
      tpmsBetaHealthCondition: asset.sensors?.tpmsBeta?.statusTpmsBeta ?? "",
      psiWheelEndHealthCondition:
        asset.sensors?.psiWheelEnd?.statusPsiWheelEnd ?? "",
      shareType: asset.shared?.shareType ?? "",
      shareEndDate: asset.shared?.shareEndDateFormated ?? "",
      shareStartDate: asset.shared?.shareStartDateFormated ?? "",
      shareGroupName: asset.shared?.shareGroupName ?? "",
      shareOrganizationName: asset.shared?.shareOrganizationName ?? "",
    };
  });

export const useAssetsTableData = (
  queryInput: GetTableDataInput
): AssetsTableDataHook => {
  const {
    state: {
      appConfig,
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();

  const availableOrgs = useAvailableOrgs();
  const { products } = useProductsList();
  const timezone = usePreferredTimezone();

  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [data, setData] = useState<AssetTableData[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    total: 0,
    skip: queryInput.pagination?.skip ?? 0,
    limit: queryInput.pagination?.limit ?? appConfig.table.defaultRowsPerPage,
  });

  const { complexFiltersQuery } = useAssetsComplexFilters();

  const { sorting } = queryInput;
  const sortField =
    sorting && sorting?.length > 0 ? sorting[0].field : "lst_evnt_t";
  const sortOrder =
    sorting && sorting?.length > 0 ? sorting[0].order : AssetSortOrder.Desc;

  const {
    data: queryDataOS,
    isSuccess: isSuccessOS,
    isFetching: isFetchingOS,
  } = useGetAssetsForTableOsQuery<AssetsResult>(
    {
      input: {
        skip: queryInput.pagination?.skip,
        limit: queryInput.pagination?.limit,
        search: queryInput.subStringSearchText,
        sort: {
          field: sortField,
          direction: sortOrder as AssetSortOrder,
        },
        viewport: queryInput.viewport,
        cluster: false,
        filterList: queryInput.tableFilters,
        complexFilters: JSON.stringify(complexFiltersQuery),
        timezone: timezone,
        currentOrgId: selectedOrganization.value,
      },
    },
    {
      refetchOnMount: true,
      staleTime: 5000,
      select: ({ getAssetsOS }) => getAssetsOS as AssetsResult,
      enabled: Boolean(complexFiltersQuery),
    }
  );

  useEffect(() => {
    setIsLoading(isFetchingOS);
    setIsSuccess(isSuccessOS);
  }, [isFetchingOS, isSuccessOS]);

  useEffect(() => {
    setData(
      mapOpenSearchData({
        data: queryDataOS?.assets ?? [],
        products,
      })
    );
    setPagination({
      total: queryDataOS?.total ?? 0,
      skip: queryDataOS?.skip ?? 0,
      limit: appConfig.table.defaultRowsPerPage,
    });
  }, [
    appConfig,
    queryDataOS,
    selectedOrganization,
    availableOrgs,
    timezone,
    products,
  ]);

  return {
    isLoading,
    isSuccess,
    pagination,
    data,
  };
};

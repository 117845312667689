export enum StatusCardsType {
  Chassis = "Cargo",
  InternalCamera = "Internal Camera",
  Regulator = "Regulator",
  AirBag = "Air Bag",
  Door = "Door",
  ABSFaultCodes = "ABS Fault Code",
  TPMS = "TPMS",
  AirTank = "Air Tank",
  AirSupply = "Air Supply",
  AtisAlpha = "ATIS",
  LiteSentryGamma = "Light Circuit",
  TemperatureInternal = "Temperature (Internal)",
  WheelEndTemperature = "Wheel End Temperature",
  DualImbalance = "Dual Imbalance",
  Liftgate = "Liftgate",
}

export enum SensorLabels {
  Battery = "Battery",
  Dwell = "Dwell",
  Cargo = "Cargo",
  InternalCamera = "Internal Camera",
  AirBag = "Air Bag",
  Door = "Door",
  TPMS = "TPMS",
  AirSupply = "Air Supply",
  TemperatureInterval = "Temperature (int)",
  ATIS = "ATIS",
  WheelEndTemperature = "Wheel End Temperature",
  LightCircuit = "Light Circuit",
  Regulator = "Regulator",
  AirTank = "Air Tank",
  BrakeWear = "Brake Wear",
  BrakeHealth = "Brake Health",
  RollStability = "Roll Stability",
  TTPair = "T/T Pair",
}

import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { omitBy, isNil } from "lodash";
import { Maybe } from "yup/lib/types";
import { FormFieldDropdownOption } from "../../../../../types";
import { schema } from "../GeofenceFormComponent/geofenceFormUtils";

export type GeofenceInitialDetails = {
  _id: string;
  orgId: string | FormFieldDropdownOption;
  configuration: {
    typeId: string | FormFieldDropdownOption;
    parentId: string | FormFieldDropdownOption;
    typeName: string | FormFieldDropdownOption;
  };
  geofence: {
    name: string;
    fullAddressFormatted: string | null | undefined;
    code: string | null | undefined;
    description: string | null | undefined;
    tags: Maybe<string>[];
  };
  metadata: {
    owner: string | null | undefined;
  };
};

export const useDetailsForm = (
  initialValues: GeofenceInitialDetails = {
    _id: "",
    orgId: "",
    configuration: {
      typeId: "",
      parentId: "",
      typeName: "",
    },
    geofence: {
      name: "",
      fullAddressFormatted: "",
      code: "",
      description: "",
      tags: [],
    },
    metadata: {
      owner: "",
    },
  }
) => {
  const form = useForm({
    resolver: yupResolver(schema),
    values: omitBy(initialValues, isNil),
  });

  const getValues = useCallback(
    () => schema.cast(form.getValues(), { assert: false }),
    [form]
  );

  return { form, getValues, schema };
};

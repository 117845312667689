import { FieldValues } from "react-hook-form";
import { isNil, pick } from "lodash";
import {
  GeofenceSettingsType,
  UPDATE_GEOFENCE_INPUT_KEYS,
} from "../../../../../constants/geofences";
import {
  GeofenceCreateInput,
  GeofenceData,
  SetGeofenceRestrictionsInput,
} from "../../../../../graphql/operations";
import { convertTimeTo24Hour } from "../../../../ReportView/helpers/helpers";

export const ERROR_UPDATING_GEOFENCE = "ERROR_UPDATING_GEOFENCE";

interface GeofenceUpdateSuccessProps {
  updatedGeofence: GeofenceData;
  geofences: GeofenceData[];
  setSelectedGeofence: (geofence: GeofenceData) => void;
  setGeofences: (geofences: GeofenceData[]) => void;
}
export const onGeofenceUpdateSuccess = async ({
  updatedGeofence,
  geofences,
  setSelectedGeofence,
  setGeofences,
}: GeofenceUpdateSuccessProps) => {
  setSelectedGeofence(updatedGeofence);
  const updatedGeofences = geofences.map((geofence) =>
    geofence._id === updatedGeofence._id ? updatedGeofence : geofence
  );
  setGeofences(updatedGeofences);
};

const prepareDaysArray = (geofence: GeofenceData, key: string) => {
  if (geofence?.configuration?.operations) {
    const newCheckedDays = Object.entries(
      geofence.configuration.operations
    ).flatMap(([day, dayOperations]) => {
      if (!isNil(dayOperations)) {
        const dayArray = Object.entries(dayOperations).map(([k, value]) => {
          if (
            k === key &&
            !isNil(value) &&
            value.startAt !== "" &&
            !isNil(value.startAt) &&
            value.endAt !== "" &&
            !isNil(value.endAt)
          ) {
            return day;
          } else {
            return "";
          }
        });
        return dayArray.filter((dayName) => dayName !== "");
      } else {
        return [] as string[];
      }
    });
    return newCheckedDays;
  } else {
    return [];
  }
};

export const daysWithOperatingHours = (geofence: GeofenceData) => {
  return prepareDaysArray(geofence, "operatingHours");
};

export const daysWithLunchBreaks = (geofence: GeofenceData) => {
  return prepareDaysArray(geofence, "lunch");
};

export const prepareUpdateData = (
  geofence: FieldValues,
  tab: GeofenceSettingsType,
  selectedDays: string[]
) => {
  switch (tab) {
    case GeofenceSettingsType.Details:
      const geofenceInput = pick(
        geofence.geofence,
        UPDATE_GEOFENCE_INPUT_KEYS
      ) as GeofenceCreateInput;
      return {
        _id: geofence._id as string,
        orgId: geofence.orgId as string,
        configuration: {
          typeId: geofence.configuration?.typeId,
          subTypeId: geofence.configuration?.subTypeId,
          parentId: geofence.configuration?.parentId,
          typeName: geofence.configuration?.typeName,
        },
        geofence: {
          ...geofenceInput,
        },
        metadata: {
          owner: geofence.metadata?.owner,
        },
      };
    case GeofenceSettingsType.Operations:
      let formattedOperations: {
        [x: string]: { operatingHours: { startAt: string; endAt: string } };
      } = {};
      Object.keys(geofence.configuration.operations).forEach((key) => {
        const dayOperations = geofence.configuration.operations[key];

        const convertedStartAt =
          dayOperations.operatingHours &&
          dayOperations.operatingHours.startAt !== ""
            ? convertTimeTo24Hour(
                `${dayOperations.operatingHours?.startAt} ${dayOperations.operatingHours?.startAtFormat}`
              )
            : null;
        const convertedEndAt =
          dayOperations.operatingHours &&
          dayOperations.operatingHours.endAt !== ""
            ? convertTimeTo24Hour(
                `${dayOperations.operatingHours?.endAt} ${dayOperations.operatingHours?.endAtFormat}`
              )
            : null;
        const convertedLunchStartAt =
          dayOperations.lunch && dayOperations.lunch.startAt !== ""
            ? convertTimeTo24Hour(
                `${dayOperations.lunch?.startAt} ${dayOperations.lunch?.startAtFormat}`
              )
            : null;
        const convertedLunchEndAt =
          dayOperations.lunch && dayOperations.lunch.endAt !== ""
            ? convertTimeTo24Hour(
                `${dayOperations.lunch?.endAt} ${dayOperations.lunch?.endAtFormat}`
              )
            : null;

        const formattedStartAt = convertedStartAt
          ? `${convertedStartAt}:00.000000000`
          : convertedLunchEndAt;
        const formattedEndAt = convertedEndAt
          ? `${convertedEndAt}:00.000000000`
          : convertedLunchEndAt;
        const formattedLunchStartAt = convertedLunchStartAt
          ? `${convertedLunchStartAt}:00.000000000`
          : convertedLunchEndAt;
        const formattedLunchEndAt = convertedLunchEndAt
          ? `${convertedLunchEndAt}:00.000000000`
          : convertedLunchEndAt;
        formattedOperations[key] = {
          ...dayOperations,
          operatingHours: {
            startAt: formattedStartAt,
            endAt: formattedEndAt,
          },
          lunch: {
            startAt: formattedLunchStartAt,
            endAt: formattedLunchEndAt,
          },
        };
      });
      return {
        _id: geofence._id as string,
        configuration: {
          operations: formattedOperations,
        },
      };
    case GeofenceSettingsType.Capacity:
      return {
        _id: geofence._id as string,
        configuration: {
          capacity: geofence.configuration.capacity,
        },
      };
    case GeofenceSettingsType.Restrictions:
      return {
        _id: geofence._id as string,
      };
  }
};

export const prepareUpdateRestriction = (
  restriction: FieldValues
): SetGeofenceRestrictionsInput => {
  return {
    geofenceId: restriction.geofenceId as string,
    restrictions: {
      standard: JSON.stringify({
        noAssetsOverXYearsOld: restriction.noAssetsOverXYearsOld as number,
        allowedAssetTypes: restriction.allowedAssetTypes as string[],
        allowedAssetLength: restriction.allowedAssetLength as string[],
        allowedAssetTags: restriction.allowedAssetTags as string,
        allowedDoorType: restriction.allowedDoorType as [string],
      }),
      custom: JSON.stringify(restriction.custom),
    },
  };
};

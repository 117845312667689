import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/system";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useAppContext } from "../../../../../context/AppContext";
import {
  FileFormat,
  GetEventHistoryTableDataInput,
  Pagination,
  SearchEventHistoryResultData,
  SortOrder,
  TableDomain,
  TableViewType,
  useSearchEventHistoryQuery,
} from "../../../../../graphql/operations";
import Spinner from "../../../../../shared/components/Spinner";
import {
  ServerSideExport,
  ServerSideExportFormat,
  StringTableFilterValue,
} from "../../../../../shared/components/Table";
import { BackEndProcessingTable } from "../../../../../shared/components/Table/BackEndProcessingTable";
import { useGetPressureUnitPreference } from "../../../../../shared/hooks/useGetPressureUnitPreference";
import { useGetTemperatureUnitPreference } from "../../../../../shared/hooks/useGetTemperatureUnitPreference";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import { useTableDataExporter } from "../../../../../shared/hooks/useTableDataExporter/useTableDataExporter";
import {
  getEndOfToday,
  getStartOfYesterday,
  getSubMonths,
} from "../../../../../utils/date";
import { columnVisibilityModel, getColumns } from "./columns";

export type EventHistoryTableProps = {
  imei?: string;
  selectedAssetId?: string;
  customerOrgId?: string;
};

export const EventHistoryTable: FC<EventHistoryTableProps> =
  memo<EventHistoryTableProps>(({ imei }) => {
    const apiRef = useGridApiRef();
    const {
      state: { appConfig },
    } = useAppContext();

    const timezone = usePreferredTimezone();
    const pressureUnit = useGetPressureUnitPreference();
    const tempUnitPreference = useGetTemperatureUnitPreference();

    const columns = useMemo(
      () =>
        getColumns({
          timezone,
          pressureUnit,
          temperatureUnit: tempUnitPreference,
        }),
      [timezone, pressureUnit, tempUnitPreference]
    );
    const [isExporting, setIsExporting] = useState(false);
    const [fileFormat, setFileFormat] = useState<FileFormat>(FileFormat.Excel);
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [queryInput, setQueryInput] = useState<GetEventHistoryTableDataInput>(
      {
        imei,
        startDate: getSubMonths(getStartOfYesterday(), 6),
        endDate: getEndOfToday(),
        sorting: [{ field: "eventDate", order: SortOrder.Desc }],
        pagination: {
          skip: 0,
          limit: appConfig.table.defaultRowsPerPage,
        },
      }
    );

    const {
      isSuccess,
      isLoading,
      isRefetching,
      data: queryData,
    } = useSearchEventHistoryQuery(
      {
        input: queryInput,
      },
      {
        refetchOnMount: true,
        staleTime: 5000,
        enabled: Boolean(imei),
        select: ({ searchEventHistory }) => searchEventHistory,
      }
    );

    const updateQueryInput = useCallback(
      (data: Partial<GetEventHistoryTableDataInput>) =>
        setQueryInput((prev) => ({ ...prev, ...data })),
      [setQueryInput]
    );

    useEffect(() => {
      if (imei) {
        updateQueryInput({ imei });
      }
    }, [imei, updateQueryInput]);

    useTableDataExporter<SearchEventHistoryResultData>({
      queryInput,
      apiRef,
      columns,
      domain: TableDomain.EventHistory,
      fileFormat,
      isExporting,
      setExporting: setIsExporting,
      totalCount: queryData?.pagination.total,
    });

    const handleExport = useCallback((format: ServerSideExportFormat) => {
      if (format === ServerSideExport.EMAIL) {
        setIsSendingEmail(true);
      } else {
        setFileFormat(format);
        setIsExporting(true);
      }
    }, []);

    return (
      <Box className="h-full w-full px-4 pt-8 pb-4 md:px-6 md:pt-0 md:pb-3 lg:px-16">
        <BackEndProcessingTable
          domain={TableDomain.EventHistory}
          queryInput={queryInput}
          apiRef={apiRef}
          tableType={TableViewType.AssetEventHistory}
          tableName={"event-history"}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          data={{
            rows: queryData?.eventHistoryTableData ?? [],
            pagination: queryData?.pagination as Pagination,
          }}
          updateQueryInput={updateQueryInput}
          sorting={queryInput.sorting ?? undefined}
          isDataRefetching={isRefetching}
          onExport={handleExport}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isSendingEmail={isSendingEmail}
          setSendingEmail={setIsSendingEmail}
          rowsPerPage={queryData?.pagination?.limit}
          totalCount={queryData?.pagination?.total}
          tableFiltersToSkip={[
            StringTableFilterValue.DoesNotContain,
            StringTableFilterValue.IsAnyOf,
          ]}
        />
        <Spinner counter={Number(isExporting) || Number(isSendingEmail)} />
      </Box>
    );
  });

import { FC, useCallback } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as ABSSensorDark } from "../../../../../../assets/svgs/absSensorDark.svg";
import { ReactComponent as ABSSensorLight } from "../../../../../../assets/svgs/absSensorsLight.svg";
import { ReactComponent as AirTankSensorDark } from "../../../../../../assets/svgs/airTankSensorDark.svg";
import { ReactComponent as AirbagSensorDark } from "../../../../../../assets/svgs/airbagSensorDark.svg";
import { ReactComponent as AirbagSensorLight } from "../../../../../../assets/svgs/airbagSensorLight.svg";
import { ReactComponent as AirTankSensorLight } from "../../../../../../assets/svgs/airtankSensorLight.svg";
import { ReactComponent as ATISSensorLight } from "../../../../../../assets/svgs/aitsSensorLight.svg";
import { ReactComponent as ATISSensorDark } from "../../../../../../assets/svgs/atisSensorDark.svg";
import { ReactComponent as CameraSensorDark } from "../../../../../../assets/svgs/cameraSensorDark.svg";
import { ReactComponent as CameraSensorLight } from "../../../../../../assets/svgs/cameraSensorLight.svg";
import { ReactComponent as CargoSensorDark } from "../../../../../../assets/svgs/cargoSensorDark.svg";
import { ReactComponent as CargoSensorLight } from "../../../../../../assets/svgs/cargoSensorLight.svg";
import { ReactComponent as DoorSensorDark } from "../../../../../../assets/svgs/doorSensorDark.svg";
import { ReactComponent as DoorSensorLight } from "../../../../../../assets/svgs/doorSensorLight.svg";
import { ReactComponent as DualImbalanceSensorDark } from "../../../../../../assets/svgs/dualImbalanceSensorDark.svg";
import { ReactComponent as DualImbalanceSensorLight } from "../../../../../../assets/svgs/dualImbalanceSensorLight.svg";
import { ReactComponent as LiftgateSensorDark } from "../../../../../../assets/svgs/liftGateSensorDark.svg";
import { ReactComponent as LiftgateSensorLight } from "../../../../../../assets/svgs/liftgateSensorLight.svg";
import { ReactComponent as LightSensorDark } from "../../../../../../assets/svgs/lightOutSensorDark.svg";
import { ReactComponent as LightSensorLight } from "../../../../../../assets/svgs/lightOutSensorLight.svg";
import { ReactComponent as RegulatorSensorDark } from "../../../../../../assets/svgs/regulatorSensorDark.svg";
import { ReactComponent as RegulatorSensorLight } from "../../../../../../assets/svgs/regulatorSensorLight.svg";
import { ReactComponent as TemperatureSensorDark } from "../../../../../../assets/svgs/temperatureSensorDark.svg";
import { ReactComponent as TemperatureSensorLight } from "../../../../../../assets/svgs/temperatureSensorLight.svg";
import { ReactComponent as TPMSSensorDark } from "../../../../../../assets/svgs/tpmsSensorDark.svg";
import { ReactComponent as TPMSSensorLight } from "../../../../../../assets/svgs/tpmsSensorLight.svg";
import { ReactComponent as WheelEndSensorDark } from "../../../../../../assets/svgs/wheelEndTemperatureSensorDark.svg";
import { ReactComponent as WheelEndSensorLight } from "../../../../../../assets/svgs/wheelEndTemperatureSensorLight.svg";
import { useAppContext } from "../../../../../../context/AppContext";
import { StatusCardsType } from "../interfaces";

export interface StatusCardHeaderProps {
  cardType: StatusCardsType;
}

const StatusCardHeader: FC<StatusCardHeaderProps> = ({ cardType }) => {
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";

  const renderIcon = useCallback(() => {
    const svgIconSettings = {
      width: "3.125rem",
      height: "3.125rem",
      display: "block",
    };
    switch (cardType) {
      case StatusCardsType.Chassis:
        return isLightTheme ? (
          <CargoSensorDark style={svgIconSettings} />
        ) : (
          <CargoSensorLight style={svgIconSettings} />
        );

      case StatusCardsType.InternalCamera:
        return isLightTheme ? (
          <CameraSensorDark style={svgIconSettings} />
        ) : (
          <CameraSensorLight style={svgIconSettings} />
        );

      case StatusCardsType.Regulator:
        return isLightTheme ? (
          <RegulatorSensorDark style={svgIconSettings} />
        ) : (
          <RegulatorSensorLight style={svgIconSettings} />
        );

      case StatusCardsType.AirBag:
        return isLightTheme ? (
          <AirbagSensorDark style={svgIconSettings} />
        ) : (
          <AirbagSensorLight style={svgIconSettings} />
        );
      case StatusCardsType.Door:
        return isLightTheme ? (
          <DoorSensorDark style={svgIconSettings} />
        ) : (
          <DoorSensorLight style={svgIconSettings} />
        );
      case StatusCardsType.ABSFaultCodes:
        return isLightTheme ? (
          <ABSSensorDark style={svgIconSettings} />
        ) : (
          <ABSSensorLight style={svgIconSettings} />
        );
      case StatusCardsType.TPMS:
        return isLightTheme ? (
          <TPMSSensorDark style={svgIconSettings} />
        ) : (
          <TPMSSensorLight style={svgIconSettings} />
        );
      case StatusCardsType.AirTank:
        return isLightTheme ? (
          <AirTankSensorDark style={svgIconSettings} />
        ) : (
          <AirTankSensorLight style={svgIconSettings} />
        );
      case StatusCardsType.AirSupply:
        return isLightTheme ? (
          <RegulatorSensorDark style={svgIconSettings} />
        ) : (
          <RegulatorSensorLight style={svgIconSettings} />
        );

      case StatusCardsType.AtisAlpha:
        return isLightTheme ? (
          <ATISSensorDark style={svgIconSettings} />
        ) : (
          <ATISSensorLight style={svgIconSettings} />
        );

      case StatusCardsType.LiteSentryGamma:
        return isLightTheme ? (
          <LightSensorDark style={svgIconSettings} />
        ) : (
          <LightSensorLight style={svgIconSettings} />
        );
      case StatusCardsType.TemperatureInternal:
        return isLightTheme ? (
          <TemperatureSensorDark style={svgIconSettings} />
        ) : (
          <TemperatureSensorLight style={svgIconSettings} />
        );
      case StatusCardsType.WheelEndTemperature:
        return isLightTheme ? (
          <WheelEndSensorDark style={svgIconSettings} />
        ) : (
          <WheelEndSensorLight style={svgIconSettings} />
        );
      case StatusCardsType.DualImbalance:
        return isLightTheme ? (
          <DualImbalanceSensorDark style={svgIconSettings} />
        ) : (
          <DualImbalanceSensorLight style={svgIconSettings} />
        );
      case StatusCardsType.Liftgate:
        return isLightTheme ? (
          <LiftgateSensorDark style={svgIconSettings} />
        ) : (
          <LiftgateSensorLight style={svgIconSettings} />
        );
    }
  }, [cardType, isLightTheme]);

  return (
    <Box className="!mb-8 justify w-full">
      <Grid container direction="row" wrap="nowrap" alignItems="center">
        <Box
          className="summary-tab-card-icon-wrap h-[52px]"
          data-testid={`summary-tab-card-icon-${cardType}`}
        >
          {renderIcon()}
        </Box>
        <Box>
          <Typography
            variant="caption"
            className="!pl-2 !text-lg inline-block !font-semibold !text-primary-blue"
          >
            {cardType}
          </Typography>
        </Box>
      </Grid>

      <Box
        className="mt-4 border-el"
        style={{
          borderTop: "1px solid var(--border-color)",
        }}
      ></Box>
    </Box>
  );
};

export default StatusCardHeader;

export enum TooltipLabelMapping {
  travelDistanceWithPrimaryPower = "Primary Power For",
  travelDistanceWithSecondaryPower = "Secondary Power For",
  travelDistanceWithNoPower = "No Power For",
  odometer = "Odometer",
  travelTime = "Moving Time",
  parkedTime = "Parked Time",
  onTripTime = "On Trip Time",
  incidents = "Number of Alerts:",
  value = "Number of Alerts:",
}

import { FC } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { capitalize } from "lodash";
import { ReactComponent as AirtankSensorDark } from "../../../../../assets/svgs/airTankSensorDark.svg";
import { ReactComponent as AirtankSensorLight } from "../../../../../assets/svgs/airtankSensorLight.svg";
import { TRIP_STATUS, TRIP_STATUS_LABELS } from "../../../../../constants/map";
import { useAppContext } from "../../../../../context/AppContext";
import { SensorStatusUppercase } from "../../../../../graphql/operations";
import SensorHeader from "../../../../../shared/components/SensorHeader/SensorHeader";
import { useDateInPreferredTimezone } from "../../../../../shared/hooks/useDateInPreferredTimezone";
import { useGetPressureUnitPreference } from "../../../../../shared/hooks/useGetPressureUnitPreference";
import { getConvertedPressureValueWithUnit } from "../../../../../utils/convertPressure";
import { AirTankSummaryData } from "../BrakesTab/helpers";

export interface AirTankInfoCardProps extends AirTankSummaryData {}
export const AirTankInfoCard: FC<AirTankInfoCardProps> = ({
  title,
  status,
  pressure,
  tripStatus,
  lastReported,
}) => {
  const {
    state: { theme },
  } = useAppContext();
  const timezoneDate = useDateInPreferredTimezone(lastReported);
  const pressureUnit = useGetPressureUnitPreference();
  const pressureValue = getConvertedPressureValueWithUnit(
    pressure,
    pressureUnit
  );
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "5rem",
    height: "5rem",
    display: "block",
  };
  return (
    <Grid item xs={12} data-testid="AirTankInfoCard">
      <Box className="border border-concrete rounded-lg p-4">
        <SensorHeader title={title} noBorder>
          {isLightTheme ? (
            <AirtankSensorDark style={svgIconSettings} />
          ) : (
            <AirtankSensorLight style={svgIconSettings} />
          )}
        </SensorHeader>
        <div
          className="text-typography !text-md !font-normal"
          data-testid="AirTankInfoCard-status"
        >
          {capitalize(status)}
          {status !== SensorStatusUppercase.Healthy ? (
            <WarningAmberIcon className="text-warning !text-base ml-1" />
          ) : (
            <CheckCircleOutlineIcon
              data-testid="airTankCard-healthy-status-icon"
              className="text-success"
            />
          )}
        </div>
        <div className="flex">
          <div className="text-typography !text-md !font-normal mr-1">
            {`Pressure:`}
          </div>{" "}
          <div
            className="text-typography !text-md mr-1"
            data-testid="AirTankInfoCard-pressure"
          >
            <span className="!font-bold">{pressureValue}</span>
            <span className="!font-normal">,</span>
          </div>
          <Typography
            className="text-typography !text-md !font-normal"
            data-testid="AirTankInfoCard-tripStatus"
          >
            {tripStatus ? TRIP_STATUS_LABELS[tripStatus as TRIP_STATUS] : ""}
          </Typography>
        </div>
        <div>
          <Typography
            className="text-typography !text-md !font-normal"
            data-testid="AirTankInfoCard-reportedDate"
          >
            Last Reported: {timezoneDate}
          </Typography>
        </div>
      </Box>
    </Grid>
  );
};

import { capitalize } from "lodash";
import {
  DWELL_STATUS_DAYS,
  STATUS_FILTER_MAPPING,
  TRIP_STATUS,
  TRIP_STATUS_LABELS,
} from "../../../../../constants/map";
import { getDifferenceInDays } from "../../../../../utils/date";

export const formatSensorEventName = (eventName: string): string => {
  if (!eventName) return "";
  return eventName
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const formatDwellSensorLabel = (dwellDays?: number): string => {
  if (dwellDays === undefined) {
    return "Unknown";
  }

  if (dwellDays === 1) {
    return "1 day";
  }

  return `${dwellDays > 29 ? "30+" : dwellDays} days`;
};

export const getLastEventChipColor = (lastEvent: Date): string => {
  const distanceToNowInDays = getDifferenceInDays(new Date(), lastEvent);

  if (distanceToNowInDays >= 0 && distanceToNowInDays <= 3) {
    return "bg-success";
  } else if (distanceToNowInDays > 3 && distanceToNowInDays <= 7) {
    return "bg-caution";
  } else if (distanceToNowInDays > 7 && distanceToNowInDays <= 29) {
    return "bg-warning";
  } else if (distanceToNowInDays > 29) {
    return "bg-error";
  } else {
    return "bg-gray";
  }
};

export const arrowSide: { [key: string]: { [key: string]: string } } = {
  top: {
    top: "100%",
    left: "50%",
    transform: "rotate(45deg) translate(-36%, -36%)",
  },
  bottom: {
    bottom: "100%",
    left: "50%",
    transform: "rotate(45deg) translate(36%, 36%)",
  },
  left: {
    bottom: "50%",
    left: "100%",
    transform: "rotate(45deg) translate(-36%, 36%)",
  },
  right: {
    bottom: "50%",
    left: "0%",
    transform: "rotate(45deg) translate(-36%, 36%)",
  },
};

export const getTooltipStatus = (
  tripState?: string,
  dwellingDays?: number | null,
  cargoState?: string,
  signal?: boolean
): string => {
  if (signal === false) {
    return "No Signal";
  }
  const tripStatus = tripState
    ? TRIP_STATUS_LABELS[tripState as TRIP_STATUS]
    : "";
  const cargoStatus = cargoState ? capitalize(cargoState) : "";

  let dwellingStatus = "";
  if (dwellingDays !== null && dwellingDays !== undefined) {
    if (dwellingDays > DWELL_STATUS_DAYS.Mega) {
      dwellingStatus = STATUS_FILTER_MAPPING.Mega;
    } else if (dwellingDays > DWELL_STATUS_DAYS.High) {
      dwellingStatus = STATUS_FILTER_MAPPING.High;
    } else if (dwellingDays > DWELL_STATUS_DAYS.Medium) {
      dwellingStatus = STATUS_FILTER_MAPPING.Medium;
    } else if (dwellingDays >= DWELL_STATUS_DAYS.Low) {
      dwellingStatus = STATUS_FILTER_MAPPING.Low;
    }
  }

  const statuses = [
    tripStatus,
    tripState !== TRIP_STATUS.Moving ? dwellingStatus : "",
    cargoStatus,
  ].filter(Boolean);

  return statuses.join(", ");
};

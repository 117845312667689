export interface SensorRangesSettingsValues {
  minSpeed?: number;
  healthyFrom: number;
  healthyTo: number;
  warningFrom: number;
  warningTo: number;
  alertFrom: number;
  alertTo: number;
  criticalFrom: number;
  criticalTo: number;
}

export enum BatteryState {
  Healthy = "healthy",
  Warning = "warning",
  Alert = "alert",
  Critical = "critical",
  Charging = "charging",
  Unknown = "unknown",
}

export enum MaxValuesBySensorType {
  airbag = 14000, // millibars
  battery = 4.2,
  camera = 100,
  percentage = 100,
  cargoCamera = 100,
  supplyPressure = 10000, // millibars
  tankPressure = 10000, // millibars
  temperature = 250, // celsius
  psiWheelEnd = 250, // celsius
  imbalance = 1800, // millibars
  atisAlpha = 250,
  time = 250,
  internal = 50, // temperature.internal celsius
  liteSentryGamma = 20,
  tpmsPressure = 14000, // millibars
  liftgate = 16,
  brakeWear = 100,
  brakeTemperature = 100,
  brakePressureService = 1100,
  brakePressureParking = 8000,
}

export enum MinValuesBySensorType {
  airbag = 0,
  battery = 0,
  camera = 0,
  percentage = 0,
  cargoCamera = 0,
  supplyPressure = 0,
  tankPressure = 0,
  temperature = 0,
  psiWheelEnd = 0,
  imbalance = 0,
  atisAlpha = 0,
  time = 0,
  internal = 0, // temperature.internal
  liteSentryGamma = -100,
  tpmsPressure = 0,
  liftgate = 0,
  brakeWear = 0,
  brakeTemperature = 0,
  brakePressureService = 0,
  brakePressureParking = 0,
}

export const thresholds = {
  healthy: {
    healthyFrom: 4.12,
    healthyTo: 4.2,
  },
  warning: {
    warningFrom: 3.41,
    warningTo: 4.11,
  },
  alert: {
    alertFrom: 3.21,
    alertTo: 3.4,
  },
  critical: {
    criticalFrom: 0,
    criticalTo: 3.2,
  },
};

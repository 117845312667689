import { useMemo } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useIsFetching } from "@tanstack/react-query";
import { useFindAssetById } from "../../../../../shared/hooks/openSearchMongoPolyfillHooks/useFindAssetById";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext/AssetsDataContext";
import AtisSensorSection from "./components/AtisSensorSection";
import PsiWheelEndSensorCardsSection from "./components/PsiWheelEndSensorCardsSection";
import PsiWheelEndSensorChartsSection from "./components/PsiWheelEndSensorChartsSection";
import RegulatorSensorSection from "./components/RegulatorSensorSection";
import TPMSCardsSection from "./components/TPMSCardsSection";
import TPMSChartsSection from "./components/TPMSChartsSection";
import DualImbalanceChartSection from "./components/charts/components/tpms/dual-imbalance-charts/DualImbalanceChartSection";
import { TiresTabProvider } from "./context";

const TiresTabPanel = () => {
  const { selectedAssetId } = useAssetsDataContext();

  const { data: selectedAsset, isLoading: isAssetLoading } = useFindAssetById(
    {
      assetId: selectedAssetId ?? "",
    },
    {
      enabled: Boolean(selectedAssetId),
    }
  );

  const { sensors } = selectedAsset ?? {};

  // this hooks checks if at least 1 sensor is available,
  // if none passes the check we show a general no sensors message
  const areSensorsAvailable = useMemo(() => {
    return Boolean(
      sensors?.tpmsBeta?.data?.sensors?.length ||
        sensors?.psiAirSupply?.data?.supplyPressure ||
        sensors?.psiWheelEnd?.data?.psiWheelEndMeasure ||
        sensors?.atisAlpha?.data?.lightActivatedNumber ||
        sensors?.atisAlpha?.data?.statusLightActivatedSeconds ||
        sensors?.atisAlpha?.data?.lightActivatedSeconds ||
        sensors?.atisAlpha?.data?.lightActivatedSeconds === 0
    );
  }, [sensors]);

  const isFetchingAsset = useIsFetching({
    queryKey: ["FindAssetById", { assetId: selectedAssetId }],
  });

  // Note: Consider waiting for more than one query before showing the real content
  const isLoading = Boolean(isFetchingAsset > 0 || isAssetLoading);
  return (
    <TiresTabProvider>
      <Box className="min-h-full p-4" data-testid="asset-dashboard--tires-tab">
        {isLoading && (
          <Box
            className="flex h-full w-full items-center justify-center"
            data-testid="asset-dashboard--tires-tab-loader"
          >
            <CircularProgress />
          </Box>
        )}

        {areSensorsAvailable ? (
          <Box sx={{ display: isLoading ? "none" : "block" }}>
            <TPMSCardsSection />
            <DualImbalanceChartSection />
            <TPMSChartsSection />

            <RegulatorSensorSection />

            <PsiWheelEndSensorCardsSection />
            <PsiWheelEndSensorChartsSection />

            <AtisSensorSection />
          </Box>
        ) : (
          !isLoading && (
            <Box
              className="text-sm bg-dashboard_subheader__bg p-6 text-asset-info-subheader"
              data-testid="tires-sensors-tab-panel-no-data"
            >
              No Sensors Available.
            </Box>
          )
        )}
      </Box>
    </TiresTabProvider>
  );
};

export default TiresTabPanel;

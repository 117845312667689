import { FC, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { AutocompleteElement } from "react-hook-form-mui";
import { Grid } from "@mui/material";
import {
  SensorProfileType,
  useGetSensorProfilesForOrganizationQuery,
} from "../../../../graphql/operations";
import { useUserData } from "../../../../shared/hooks/useUserData";
import { useAssetParameters } from "../../hooks/useAssetParameters";
import { AlertFormValues } from "../../interfaces";
import { AlertParametersContainer } from "../AlertParametersContainer";
import { AssetsParameters } from "./AssetsParameters";

interface AlertRollStabilityParametersProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}

export const AlertRollStabilityParameters: FC<
  AlertRollStabilityParametersProps
> = ({ form }) => {
  const user = useUserData();
  const {
    assetTypeOptions,
    setAssetNameSearch,
    watchAssetsValue,
    watchAssetTypeValue,
    isFetching,
    hideInputValue,
    setSelectedAsset,
    assetsOptionsList,
    setAssetsOptionsList,
  } = useAssetParameters({ form });

  const { data: defaultSensorProfileData, isFetching: isFetchingProfile } =
    useGetSensorProfilesForOrganizationQuery(
      {
        input: {
          orgId: user?.customer_orgs_id[0] as string,
          type: SensorProfileType.Organization,
          mergeProfilesWithDefaultPctProfile: true,
          includeDefaultPctProfile: true, // We need it for the cases where we don't have default profile for the organization
          default: true,
        },
      },
      {
        enabled: Boolean(user?.customer_orgs_id[0]),
      }
    );

  const statusRollStabilityOptions = useMemo(() => {
    const { sudden, continuous } =
      defaultSensorProfileData?.getSensorProfilesForOrganization?.[0]
        ?.configuration.rollStability ?? {};

    return sudden && continuous
      ? [
          {
            id: sudden,
            label: sudden,
          },
          {
            id: continuous,
            label: continuous,
          },
        ]
      : [];
  }, [defaultSensorProfileData]);

  return (
    <AlertParametersContainer>
      <AssetsParameters
        form={form}
        setAssetNameSearch={setAssetNameSearch}
        setSelectedAsset={setSelectedAsset}
        assetsOptionsList={assetsOptionsList}
        setAssetsOptionsList={setAssetsOptionsList}
        assetTypeOptions={assetTypeOptions}
        watchAssetsValue={watchAssetsValue}
        watchAssetTypeValue={watchAssetTypeValue}
        isFetching={isFetching}
        hideInputValue={hideInputValue}
        assetsSelectTestId="roll-stability-assets-select"
        assetTypeSelectTestId="roll-stability-asset-type-select"
      />

      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        xl={4}
        data-testid="status-roll-stability"
      >
        <AutocompleteElement
          label="Health Status"
          options={statusRollStabilityOptions}
          name="parameters.statusRollStability"
          control={form.control}
          matchId
          autocompleteProps={{ disabled: isFetchingProfile }}
          loading={isFetchingProfile}
          textFieldProps={{
            inputProps: { "data-testid": "status-roll-stability-input" },
          }}
        />
      </Grid>
    </AlertParametersContainer>
  );
};

import { useMemo } from "react";
import {
  Grid,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { capitalize } from "lodash";
import { v4 } from "uuid";
import {
  DateRangeKey,
  TRIP_STATUS,
  TRIP_STATUS_LABELS,
} from "../../../../../../constants/map";
import { ColorsPalette } from "../../../../../../design-system/colors-palette";
import {
  useGetMergedProfileForAssetQuery,
  useGetAtisSensorHistoricalTableDataQuery,
  useGetAtisAggregationsHistoricalDataQuery,
  SortOrder,
} from "../../../../../../graphql/operations";
import { useFindAssetById } from "../../../../../../shared/hooks/openSearchMongoPolyfillHooks/useFindAssetById";
import useBreakpoint from "../../../../../../shared/hooks/useBreakpoint";
import {
  convertToIsoStringIgnoringTimezoneOffset,
  formatDate,
  getEndOfToday,
  getStartOfDay,
  getSubDays,
} from "../../../../../../utils/date";
import { useAssetsDataContext } from "../../../../shared/AssetsDataContext/AssetsDataContext";
import { sensorConfigToReferenceAreaValues } from "../helpers";
import AtisActivationChart from "./charts/sensors/AtisActivationChart";

const AtisSensorSection = () => {
  const { selectedAssetId } = useAssetsDataContext();

  const isDesktop = useBreakpoint("up", "xl");
  const isMobile = useBreakpoint("down", "sm");

  const startDate = convertToIsoStringIgnoringTimezoneOffset(
    getStartOfDay(getSubDays(new Date(), 30))
  );
  const endDate = convertToIsoStringIgnoringTimezoneOffset(getEndOfToday());

  const profileGQL = useGetMergedProfileForAssetQuery(
    {
      input: { assetId: selectedAssetId ?? "" },
    },
    { enabled: Boolean(selectedAssetId) }
  )?.data;

  const { data: selectedAsset } = useFindAssetById(
    {
      assetId: selectedAssetId ?? "",
    },
    {
      enabled: Boolean(selectedAssetId),
    }
  );

  const mergedSensorProfile = useMemo(
    () => profileGQL?.getMergedProfileForAsset.sensorProfile ?? null,
    [profileGQL]
  );

  const movingReferenceAreasValues = sensorConfigToReferenceAreaValues(
    mergedSensorProfile?.configuration?.atisAlpha?.lightActivatedSeconds?.moving
      ?.match.thresholds
  );

  const parkedReferenceAreasValues = sensorConfigToReferenceAreaValues(
    mergedSensorProfile?.configuration?.atisAlpha?.lightActivatedSeconds?.parked
      ?.match.thresholds
  );

  /*
    This query is used to provide data to the charts only
    on their inial load. Beacuse of aech chart is using it on its own,
    the idea is to prevent 3 calls (one for each chart) at the same time.
  */
  const { data: initialHistoryData, isLoading: isLoadingHistoryData } =
    useGetAtisAggregationsHistoricalDataQuery(
      {
        input: {
          startDate,
          endDate,
          imei: selectedAsset?.imei ?? "",
          customerOrgId: selectedAsset?.customer_orgs_id ?? "",
          period: DateRangeKey.Last30Days,
          sort: SortOrder.Asc,
          includeEmptyDates: true,
        },
      },
      {
        enabled: Boolean(selectedAsset?.asset_id),
      }
    );

  const { data: atisHistoricalTableData } =
    useGetAtisSensorHistoricalTableDataQuery({
      input: {
        startDate,
        endDate,
        imei: selectedAsset?.imei ?? "",
        period: DateRangeKey.Last30Days,
        customerOrgId: selectedAsset?.customer_orgs_id ?? "",
        sort: SortOrder.Asc,
        includeEmptyDates: false,
      },
    });

  const hideTable =
    initialHistoryData?.searchHistoricalEventHistory?.data?.every(
      (record) => !record?.atisAggregations
    ) || isLoadingHistoryData;

  return (
    <Grid container data-testid="atis-section" className="w-full my-[24px]">
      {!hideTable && (
        <Grid
          item
          sm={12}
          className="bg-card__bg border rounded-lg"
          sx={{
            borderColor: "var(--asset-card-border)",
            padding: isDesktop ? "16px" : "8px",
            marginBottom: "24px",
          }}
          data-testid="atis-alpha-table-container"
        >
          <Typography
            data-testid="atis-alpha-table-label"
            sx={{
              fontSize: "18px",
              color: "var(--brand)",
              fontWeight: 600,
              margin: isDesktop ? "0 0 16px 0" : "8px 0 0 16px",
            }}
          >
            Latest ATIS Activations
          </Typography>
          <TableContainer
            component={Paper}
            className="border"
            sx={{
              width: "100%",
              boxShadow: "none",
              borderRadius: "8px",
              borderColor: isDesktop
                ? "var(--asset-table-border)"
                : "transparent",
            }}
          >
            <Table stickyHeader aria-label="Tire Sensors Table">
              <TableHead data-testid="atis-alpha-event-history-table-header">
                <TableRow>
                  <TableCell sx={{ fontWeight: "700" }}>
                    Activation Date & Time
                  </TableCell>
                  <TableCell sx={{ fontWeight: "700" }}>
                    Length of Activation (seconds)
                  </TableCell>
                  <TableCell sx={{ fontWeight: "700" }}>
                    Health Status
                  </TableCell>
                  <TableCell sx={{ fontWeight: "700" }}>Trip Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody data-testid="atis-alpha-event-history-table-body">
                {atisHistoricalTableData?.searchHistoricalEventHistory?.data?.map(
                  (row) => (
                    <TableRow
                      key={`row-${v4()}`}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      data-testid="atis-alpha-event-history-table-row"
                    >
                      <TableCell component="th" scope="row">
                        {formatDate(row?.date as string, "MM/dd/yyy hh:mm")}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {capitalize(
                          row?.atisActivations?.light_activated_seconds?.toString()
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {capitalize(row?.atisActivations?.status ?? "")}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row?.atisActivations?.trip_state
                          ? TRIP_STATUS_LABELS[
                              row.atisActivations.trip_state as TRIP_STATUS
                            ] ?? capitalize(row.atisActivations?.trip_state)
                          : ""}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      {!isMobile && (
        <Grid item xs={12}>
          <Grid
            container
            spacing={3}
            sx={{
              marginBottom: "24px",
            }}
          >
            <Grid className="hidden lg:block" item sm={12} lg={6}>
              <AtisActivationChart
                title="ATIS Activation Time Moving"
                label="Seconds"
                subtitle="Trending ATIS data over time"
                initialHistoryData={initialHistoryData}
                referenceAreasValues={movingReferenceAreasValues}
                selectedAsset={selectedAsset}
                xAsisKey="date"
                barKey="atis.activationMoving.sum"
                barColor={ColorsPalette.PrimaryBlue}
              />
            </Grid>

            <Grid className="hidden lg:block" item sm={12} lg={6}>
              <AtisActivationChart
                title="ATIS Activation Time Parked"
                label="Seconds"
                subtitle="Trending ATIS data over time"
                initialHistoryData={initialHistoryData}
                referenceAreasValues={parkedReferenceAreasValues}
                selectedAsset={selectedAsset}
                xAsisKey="date"
                barKey="atis.activationParked.sum"
                barColor={ColorsPalette.Alert}
              />
            </Grid>
          </Grid>
          <Grid className="hidden lg:block" item xs={12}>
            <AtisActivationChart
              title="ATIS Activation Time Counts"
              subtitle="Trending ATIS data over time"
              initialHistoryData={initialHistoryData}
              selectedAsset={selectedAsset}
              xAsisKey="date"
              barKey="atis.activationTotal.count"
              barColor={ColorsPalette.RoyalBlue}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default AtisSensorSection;

import { FC } from "react";
import { Box } from "@mui/material";
import { orderBy, kebabCase } from "lodash";
import {
  DateRangeKey,
  TPMS_CHART_DATE_RANGE_OPTIONS,
} from "../../../../../../../../constants/map";
import {
  useGetAtisAggregationsHistoricalDataQuery,
  GetAtisAggregationsHistoricalDataQuery,
  Asset,
  SortOrder,
  Maybe,
  HistoricalEventHistory,
} from "../../../../../../../../graphql/operations";
import {
  convertToIsoStringIgnoringTimezoneOffset,
  DAY_MONTH_FORMAT,
  formatDate,
} from "../../../../../../../../utils/date";
import { IReferenceAreaValueType } from "../../../interfaces";
import AtisChartBody from "../components/atis/AtisChartBody";
import ChartHeader from "../components/tpms/TiresChartHeader";
import { useDateFilters } from "./hooks/useDateFilters";

export interface AtisActivationProps {
  title: string;
  subtitle: string;
  label?: string;
  selectedAsset?: Maybe<Asset>;
  initialHistoryData?: GetAtisAggregationsHistoricalDataQuery;
  referenceAreasValues?: IReferenceAreaValueType[];
  xAsisKey: string;
  barKey: string;
  barColor: string;
}

const AtisActivationChart: FC<AtisActivationProps> = ({
  title,
  subtitle,
  referenceAreasValues,
  initialHistoryData,
  selectedAsset,
  label,
  xAsisKey,
  barKey,
  barColor,
}) => {
  const {
    startDate,
    setStartDate,

    endDate,
    setEndDate,

    dateRangeOption,
    setDateRangeOption,

    handleDateRangeChange,
  } = useDateFilters();

  const { data: historyData, isLoading: isLoadingHistoryData } =
    useGetAtisAggregationsHistoricalDataQuery(
      {
        input: {
          startDate: convertToIsoStringIgnoringTimezoneOffset(startDate),
          endDate: convertToIsoStringIgnoringTimezoneOffset(endDate),
          imei: selectedAsset?.imei ?? "",
          customerOrgId: selectedAsset?.customer_orgs_id ?? "",
          period:
            TPMS_CHART_DATE_RANGE_OPTIONS.find(
              (option) => option.value === dateRangeOption
            )?.key ?? DateRangeKey.Last30Days,
          sort: SortOrder.Asc,
          includeEmptyDates: true,
        },
      },
      {
        enabled: Boolean(selectedAsset?.asset_id),
      }
    );

  const initialChartData = isLoadingHistoryData
    ? ([] as GetAtisAggregationsHistoricalDataQuery)
    : historyData?.searchHistoricalEventHistory?.data?.length
    ? historyData
    : initialHistoryData;

  const sortedChartData = orderBy(
    initialChartData?.searchHistoricalEventHistory?.data,
    ["date", ["asc"]]
  );

  const formattedChartData = sortedChartData.map(
    (d: HistoricalEventHistory | null) => {
      const date = new Date(d?.date);

      return {
        date: !isNaN(date.getTime())
          ? formatDate(date, DAY_MONTH_FORMAT)
          : date,
        atis: d?.atisAggregations,
      };
    }
  );

  return (
    <Box
      data-testid={kebabCase(title) + "-atis-chart-container"}
      className="rounded-lg bg-dashboard_subheader__bg border border-asset-card-border p-4"
    >
      <ChartHeader
        title={title}
        subtitle={subtitle}
        startDate={startDate}
        setStartDate={setStartDate}
        dateRangeOption={dateRangeOption}
        setDateRangeOption={setDateRangeOption}
        endDate={endDate}
        setEndDate={setEndDate}
        changeDateRangeOption={handleDateRangeChange}
      />

      <AtisChartBody
        data={formattedChartData}
        yAxisLabel={label}
        barColor={barColor}
        referenceAreasValues={referenceAreasValues}
        xAsisKey={xAsisKey}
        barKey={barKey}
      />
    </Box>
  );
};

export default AtisActivationChart;
